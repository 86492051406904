import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { isNull, isUndefined } from "lodash";
import { Fragment, useState } from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";

export const ActionTeamCombobox: React.FC<any> = ({
    teams,
    actionData,
    disabled,
    setCreateActionBody,
    createActionBody,
    update
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    const getDisplayValue = () =>
        (!isNull(actionData?.teamname) && !isUndefined(actionData?.teamname)) ? actionData?.teamname :
            (!isNull(selected?.teamname) && !isUndefined(selected?.teamname)) ? selected?.teamname : "Unassigned";

    const getDefaultValue = () => actionData?.actionteam;

    const filteredTeams =
        query === ""
            ? teams
            : teams.filter((team: any) => {
                return (team.teamname.toLowerCase().includes(query.toLowerCase()))
            });

    const updateTeam = (e: any) => {
        setSelected(e);

        isUndefined(actionData.actionteam) ? setCreateActionBody(Object.assign(
            createActionBody,
            { team: e.teamid })
        ) : update(actionData?.actionid, "team", e.teamid);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            defaultValue={getDefaultValue}
            onChange={(e: any) => { updateTeam(e) }}
        >
            <Box
                border="1px solid #e2e8f0"
                rounded="lg"
                paddingY="1px"
            >
                <Box mx={1} w="100%">
                    {
                        <Avatar
                            mr={1}
                            size="xs"
                            color="white"
                            verticalAlign="middle"
                            name={
                                (!isNull(actionData?.teamname) && !isUndefined(actionData?.teamname)) ? actionData?.teamname :
                                    (!isNull(selected?.teamname) && !isUndefined(selected?.teamname)) ? selected?.teamname : ""
                            }
                        />
                    }

                    <Combobox.Input
                        className="combobox"
                        displayValue={getDisplayValue}
                        defaultValue={getDefaultValue}
                        onChange={(event: any) => {
                            setQuery(event.target.value);
                        }}
                        style={{
                            fontSize: "10pt",
                            display: "inline",
                            color: "#4a5568",
                            fontWeight: 500,
                            padding: "4px",
                            backgroundColor: "transparent",
                        }}
                    />

                    <Combobox.Button
                        hidden={disabled}
                        style={{
                            float: "right",
                            marginRight: "15px",
                            verticalAlign: "middle"
                        }}>
                        <Icon
                            as={BsChevronDown}
                            color="gray.500"
                            verticalAlign="middle"
                        />
                    </Combobox.Button>
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        width: "100%",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white"
                    }}
                >
                    {
                        filteredTeams.length === 0 && query !== "" ?
                            <HStack mt={2}>
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack>

                            : filteredTeams.map((team: any, i: number) => (
                                <Combobox.Option
                                    key={`${team}${i}`}
                                    value={team}
                                >
                                    {({ active }) => (
                                        <HStack
                                            w="100%"
                                            p={2}
                                            bgColor={active ? "gray.50" : ""}
                                            cursor="pointer"
                                        >
                                            <Avatar
                                                size="xs"
                                                color="white"
                                                name={!isNull(team.teamname) ? team.teamname : "Unnamed"}
                                            />
                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                textAlign="left"
                                            >
                                                {!isNull(team.teamname) ? team.teamname : "Unnamed"}
                                            </Text>
                                        </HStack>
                                    )}
                                </Combobox.Option>
                            ))
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};