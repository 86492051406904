import {
    HStack,
    Icon,
    useColorModeValue
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

export const Rating: React.FC<any> = ({ rating, setRating }) => {
    const color = useColorModeValue('gray.200', 'gray.600');
    const activeColor = "brand.500";

    return (
        <HStack spacing="3" w={{ base: "full", md: "-moz-fit-content" }}>
            {
                Array.from({ length: 5 })
                    .map((_, index) => index + 1)
                    .map((index) => (
                        <Icon
                            className='hover-pop'
                            cursor="pointer"
                            key={index}
                            as={FaStar}
                            fontSize="4xl"
                            color={index <= rating ? activeColor : color}
                            onClick={() => { setRating(index); }}
                        />
                    ))
            }
        </HStack>
    )
};