import { BoxProps, Divider, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import { StepCircle } from './StepCircle'

interface StepProps extends BoxProps {
	title: string;
	description: string;
	isCompleted: boolean;
	isActive: boolean;
	isFirstStep: boolean;
	isLastStep: boolean;
};

export const Step = (props: StepProps) => {
	const { isActive, isCompleted, isFirstStep, isLastStep, title, description, ...stackProps } = props
	const isMobile = useBreakpointValue({ base: true, md: false });

	return (
		<Stack
			spacing={4}
			direction={{ base: 'row', md: 'column' }}
			flex="1"
			{...stackProps}
		>
			<Stack spacing="0" align="center" direction={{ base: 'column', md: 'row' }}>
				{
					!isMobile && <Divider
						display={isMobile ? 'none' : 'initial'}
						orientation="horizontal"
						borderWidth="1px"
						borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? 'accent' : 'inherit'}
					/>
				}

				<StepCircle isActive={isActive} isCompleted={isCompleted} />

				{
					!isMobile && <Divider
						orientation="horizontal"
						borderWidth="1px"
						borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
					/>
				}
			</Stack>

			<Stack
				spacing="0.5"
				pb={isMobile && !isLastStep ? '8' : '0'}
				align={{ base: 'start', md: 'center' }}
			>
				<Text color="emphasized" fontWeight={600} fontSize={{ base: "xs", md: "sm" }}>
					{title}
				</Text>

				<Text color="muted" fontSize={{ base: "xs", md: "sm" }}>{description}</Text>
			</Stack>
		</Stack>
	)
}