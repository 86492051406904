import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { usePagination, useSortBy, useTable } from "react-table";
import {
    Avatar,
    Box,
    Center,
    HStack,
    SlideFade,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from "@chakra-ui/react";

import { Pager } from "../common/Pager";
import { localDate } from "../../helpers/DayJsHelper";

export const CompletedActionsTable: React.FC<any> = ({
    currentItems,
    pageCount,
    pageIndex,
    setPageIndex,
    loading
}) => {
    const navigateTo = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true,
            },
            {
                Header: "TITLE",
                accessor: "title",
                disableSortBy: true,
                Cell: (cell: any) => <Box whiteSpace="pre-line">
                    <ReactMarkdown children={cell.value} />
                </Box>
            },
            {
                Header: "LAST UPDATE",
                accessor: "dateClosed",
                Cell: (cell: any) => <VStack alignItems="start">
                    <Text>
                        {localDate(cell.value)}
                    </Text>
                </VStack>
            },
            {
                Header: "CLOSED BY",
                accessor: "closedBy",
                Cell: (cell: any) => <HStack>
                    <Avatar name={cell.value} size="xs" color="white" /> <Text>{cell.value}</Text>
                </HStack>
            },
        ], []
    );

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount,
        } = useTable({
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: true,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
            useSortBy,
            usePagination
        );

        return (
            <Fragment>
                <SlideFade in={!loading} offsetY="20px">
                    <VStack display={{ base: "flex", lg: "none" }}>
                        {
                            currentItems.map((action: any, i: number) => (
                                <Box
                                    w="full"
                                    className="hover-pop"
                                    cursor="pointer"
                                    backgroundColor={i % 2 ? "gray.50" : "gray.100"}
                                    border="1px solid"
                                    borderColor="gray.200"
                                    borderRadius="8px"
                                    boxShadow="xs"
                                    onClick={() => {
                                        navigateTo(`/actions/action/${action.id}`);
                                    }}
                                >
                                    <VStack alignItems="start" m={3}>
                                        <Text
                                            fontSize="sm"
                                            fontWeight={500}
                                            color="gray.600"
                                            textAlign="left"
                                        >
                                            {action.title || "no title"}
                                        </Text>

                                        <HStack>
                                            <Avatar name={action.closedBy} size="xs" color="white" />
                                            <Text
                                                fontSize="xs"
                                                fontWeight={500}
                                                color="gray.500"
                                                textAlign="left"
                                            >
                                                Completed by {action.closedBy} on {localDate(action.dateClosed)}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </Box>
                            ))
                        }
                    </VStack>

                    {
                        currentItems.length < 1 && !loading ?
                            <Fragment>
                                <Center m={5}>
                                    <VStack>
                                        <Text fontSize="xl" fontWeight={600}>
                                            No Completed Actions
                                        </Text>
                                    </VStack>
                                </Center>
                            </Fragment>
                            :
                            <SlideFade in={!loading} offsetY="20px">
                                <Table
                                    {...getTableProps()}
                                    id="ActionTable"
                                    variant="striped"
                                    display={{ base: "none", lg: "inline-table" }}
                                >
                                    <Thead>
                                        {
                                            headerGroups.map(headerGroup => (
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        headerGroup.headers.map(column => (
                                                            <Th {...column.getHeaderProps()}  >
                                                                <Text fontWeight={700} color="gray.600">
                                                                    {column.render("Header")}
                                                                </Text>
                                                            </Th>
                                                        ))
                                                    }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <Fragment>
                                                        <Tr
                                                            {...row.getRowProps()}
                                                            className="hover-pop"
                                                            key={i}
                                                            borderBottom="1px solid"
                                                            borderColor={"gray.100"}
                                                            cursor="pointer"
                                                            rounded="lg"
                                                            onClick={() => {
                                                                navigateTo(`/actions/action/${row.values.id}`,
                                                                    {
                                                                        state: { completed: true }
                                                                    })
                                                            }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    return <Td wordBreak="break-word" {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </Td>
                                                                })
                                                            }
                                                        </Tr>
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </SlideFade>
                    }
                </SlideFade>

                {
                    !loading && <Box
                        mt={5}
                        textAlign="center"
                        display={pageCount > 1 ? "block" : "none"}
                    >
                        <Pager
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                        />
                    </Box>
                }
            </Fragment >
        )
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount
    });
};