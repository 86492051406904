import { Fragment, useState } from "react";
import { isNull, isUndefined } from "lodash";
import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown, BsSearch } from "react-icons/bs";

export const ActionCombobox: React.FC<any> = ({
    users,
    actionData,
    disabled,
    update
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    const getDisplayValue = () =>
        !isUndefined(actionData?.userforename) ? `${actionData?.userforename} ${actionData?.usersurname}` :
            !isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` :
                "Unassigned";

    const getDefaultValue = () =>
        (!isNull(actionData?.userforename) && !isUndefined(actionData?.userforename)) ? `${actionData?.userforename} ${actionData?.usersurname}` :
            "";

    const filteredUsers =
        query === ""
            ? users
            : users.filter((user: any) => {
                return (user.udforename.toLowerCase().includes(query.toLowerCase()) ||
                    user.udsurname.toLowerCase().includes(query.toLowerCase())) ||
                    (`${user.udforename.toLowerCase()} ${user.udsurname.toLowerCase()}`).includes(query.toLowerCase())
            });

    const updateUser = (e: any) => {
        setSelected(e);
        update(actionData?.actionid, "user", e.uduid);
    };

    const renderOptions = () => {
        let options = [];

        options.push(<Combobox.Option
            key="Unassigned"
            value="0"
        >
            {({ active }) => (
                <HStack
                    w="100%"
                    p={2}
                    bgColor={active ? "gray.50" : ""}
                    cursor="pointer"
                >
                    <Avatar size="xs" />
                    <Text
                        fontWeight={500}
                        fontSize="sm"
                        color="gray.600"
                        textAlign="left"
                    >
                        Unassigned
                    </Text>
                </HStack>
            )}
        </Combobox.Option>);

        filteredUsers.map((user: any, i: number) => (
            options.push(
                <Combobox.Option
                    key={`${user}${i}`}
                    value={user}
                >
                    {({ active }) => (
                        <HStack
                            w="100%"
                            p={2}
                            bgColor={active ? "gray.50" : ""}
                            cursor="pointer"
                        >
                            <Avatar
                                size="xs"
                                name={!isNull(user.udforename) ? `${user.udforename} ${user.udsurname}` : "Unnamed"}
                                color="white"
                            />
                            <Text
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                                wordBreak="break-word"
                                textAlign="left"
                            >
                                {!isNull(user.udforename) ? `${user.udforename} ${user.udsurname}` : "Unnamed"}
                            </Text>
                        </HStack>
                    )}
                </Combobox.Option>)
        ))

        return options;
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            defaultValue={getDefaultValue}
            onChange={(e: any) => updateUser(e)}
        >
            <Box
                border="1px solid #e2e8f0"
                rounded="lg"
                paddingY="1px"
            >
                <Box mx={1} w="100%">
                    {
                        <Avatar
                            mr={1}
                            size="xs"
                            color="white"
                            verticalAlign="middle"
                            name={
                                !isNull(actionData.userforename) ? `${actionData.userforename} ${actionData.usersurname}` :
                                    !isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` :
                                        ""
                            }
                        />
                    }

                    <Combobox.Input
                        className="combobox"
                        defaultValue={getDisplayValue}
                        displayValue={getDefaultValue}
                        onChange={(event: any) => {
                            setQuery(event.target.value);
                        }}
                        style={{
                            fontSize: "10pt",
                            display: "inline",
                            color: "#4a5568",
                            fontWeight: 500,
                            padding: "4px",
                            backgroundColor: "transparent",
                        }}
                    />

                    <Combobox.Button
                        hidden={disabled}
                        style={{
                            float: "right",
                            marginRight: "15px"
                        }}>
                        <Icon
                            as={BsChevronDown}
                            color="gray.500"
                            verticalAlign="middle"
                        />
                    </Combobox.Button>
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        width: "100%",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white"
                    }}
                >
                    {
                        filteredUsers.length === 0 && query !== "" ?
                            <HStack mt={2}>
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack> : renderOptions()
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};