import { debounce } from "lodash";
import { useRef, useEffect, useMemo } from "react";

export const useDebounce = (callback: any, time: any = 2000) => {
  const ref: any = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
  }, []);

  return debouncedCallback;
};