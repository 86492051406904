import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Alert,
    AlertIcon,
    AlertDescription,
    Box,
    Button,
    Container,
    Heading,
    Input,
    Stack,
    Text,
    useBreakpointValue,
    CircularProgress,
} from '@chakra-ui/react';

import { has2FA, login } from '../../features/auth/auth.actions';
import logo from '../../assets/logos/obbi_logo_1051x640.png';

export const Login = () => {

    const navigateTo = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoadingStatus] = useState(false);
    const [error, setErrorMessage] = useState("");
    const [twoFAEnabled, setTwoFAEnabled] = useState<boolean | null>(null)
    const [twoFAText, setTwoFAText] = useState('')

    const twoFAUpdated = (value: any) => {
        setTwoFAText(value);
    }

    async function doLogin() {
        if (email.length === 0) {
            setErrorMessage("You have not entered a username");
            return;
        }

        if (password.length === 0) {
            setErrorMessage("You have not entered a password");
            return;
        }

        setLoadingStatus(true);
        setErrorMessage("");

        //check 2fa
        if (twoFAEnabled === null) {
            let twoFAResponse: boolean | null = await has2FA(email).catch((error) => {
                console.log(error);
                return null
            });

            if (twoFAResponse !== null) {
                if (twoFAResponse) {
                    setTwoFAEnabled(twoFAResponse)
                    setLoadingStatus(false)
                    return;
                }
            } else {
                setErrorMessage('Two factor authentication could not be determined');
                setLoadingStatus(false)
                return;
            }
        }

        if (twoFAEnabled && twoFAText === '') {
            setErrorMessage('Please enter 2FA value');
            setLoadingStatus(false)
            return;
        }

        const user = {
            "username": email,
            "password": password,
            "twofacode": twoFAText
        };

        login(user)
            .then(() => {
                setLoadingStatus(false);
                navigateTo("/home");
            })
            .catch((error) => {
                setLoadingStatus(false);
                setErrorMessage(error.response.data.message);
                console.log(error);
            })
    }

    async function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        await doLogin();
    }

    async function checkEnterKey(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            await doLogin();
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "LOCAL") {
            localStorage.clear();
            window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
        }
    }, [])

    return (
        <Box
            bgColor={{ base: "white", md: "gray.100" }}
            py={{ base: '12', md: '24' }}
            height="100vh"
        >
            <Container
                maxW="lg"
                py={{ base: '8', sm: '8' }}
                px={{ base: '10', sm: '10' }}
                bg={useBreakpointValue({ base: 'white' })}
                boxShadow={{ md: 'lg' }}
                borderRadius={{ base: 'xl', sm: 'xl' }}
            >
                <Stack spacing="8">
                    <Stack spacing="6" align="center">
                        <img data-cy="login-image" src={logo} alt="" width="50%" />
                        <Stack spacing="3" textAlign="center">
                            <Heading size="xs" data-cy="login-title">Log in to your account</Heading>
                        </Stack>
                    </Stack>
                    <Stack spacing="6">
                        <Stack spacing="4">
                            <Input
                                data-cy="login-email-input"
                                required
                                value={email}
                                placeholder="Enter your email"
                                onChange={(event: { target: { value: SetStateAction<string>; }; }) => setEmail(event.target.value)}
                            />
                            <Input
                                data-cy="login-password-input"
                                required
                                value={password}
                                type="password"
                                placeholder="Password"
                                onChange={(event: { target: { value: SetStateAction<string>; }; }) => setPassword(event.target.value)}
                                onKeyDown={checkEnterKey}
                            />
                            {twoFAEnabled &&
                                <Input
                                    type="password"
                                    placeholder='2FA'
                                    onChange={(event: { target: { value: SetStateAction<string>; }; }) => twoFAUpdated(event.target.value)}
                                    value={twoFAText}
                                    autoCapitalize="none"
                                    onKeyDown={checkEnterKey}
                                />
                            }
                            <Button
                                data-cy="login-button"
                                colorScheme="green"
                                onClick={handleSubmit}
                            >
                                Log in
                            </Button>
                            {
                                loading ?
                                    <CircularProgress alignSelf='center' isIndeterminate color='green.300' />

                                    : <Fragment></Fragment>
                            }
                            {
                                error ?
                                    <Alert status='error'>
                                        <AlertIcon data-cy="login-error-icon" />
                                        <AlertDescription data-cy="login-error">
                                            {error}
                                        </AlertDescription>
                                    </Alert>

                                    : <Fragment></Fragment>
                            }
                        </Stack>
                    </Stack>
                    <Stack spacing="0.5" align="center">
                        <Text fontSize="sm" color="muted">
                            Having trouble logging in?
                        </Text>
                        <Button
                            variant="link"
                            color="brand.500"
                            _hover={{ bg: "" }}
                            size="sm"
                            onClick={() => window.location.href = 'mailto:support@obbisolutions.com'}>
                            Contact us
                        </Button>
                    </Stack>
                    <Text fontSize="xs" color="subtle" textAlign="center">
                        By continuing, you acknowledge that you have read, understood and agree to our terms and
                        conditions.
                    </Text>
                </Stack>
            </Container >
        </Box >
    );
};