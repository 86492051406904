import { Fragment, useEffect, useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown, BsPeopleFill, BsSearch } from "react-icons/bs";

export const TeamMessageCombobox: React.FC<any> = ({
    teams,
    disabled = false,
    update,
    selectedTeam
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (selectedTeam === 0 || isEmpty(selectedTeam)) {
            setSelected(undefined);
        }
    }, [selectedTeam]);

    const getDisplayValue = () =>
        !isUndefined(selected?.teamname) ? `${selected?.teamname}` : "";

    const filteredTeams =
        query === ""
            ? teams
            : teams.filter((team: any) => {
                return team.teamname?.toLowerCase().includes(query.toLowerCase())
            });

    const updateTeam = (e: any) => {
        setSelected(e);
        update(e);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            onChange={(e: any) => updateTeam(e)}
        >
            <Box
                border="1px solid"
                borderColor={!disabled ? "#e2e8f0" : ""}
                rounded="lg"
                paddingY="5px"
            >
                <Box mx={2}>
                    <Combobox.Button
                        hidden={disabled}
                        style={{ width: "100%", textAlign: "start" }}
                    >
                        <Avatar
                            size="xs"
                            mr={1}
                            mt={-0.5}
                            icon={<Icon as={BsPeopleFill} />}
                            name={!isUndefined(selected?.teamname) ? selected?.teamname : ""}
                            color="white"
                            verticalAlign="middle"
                        />

                        <Combobox.Input
                            className="combobox"
                            autoComplete="off"
                            defaultValue={''}
                            displayValue={getDisplayValue}
                            onChange={(event: any) => {
                                setQuery(event.target.value);
                            }}
                            style={{
                                width: "full",
                                color: "#4a5568",
                                padding: "4px",
                                backgroundColor: "transparent"
                            }}
                        />
                        <Icon
                            as={BsChevronDown}
                            color="gray.500"
                            float="right"
                            mt={2}
                        />
                    </Combobox.Button>
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    w={{ base: "94%", sm: "96%", md: "94.5%", lg: "95%", xl: "96.5%" }}
                    rounded="lg"
                    style={{
                        border: "1px solid",
                        borderColor: "rgba(48, 49, 51, 0.05)",
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white"
                    }}
                >
                    {
                        filteredTeams.length === 0 && query !== "" ?
                            <HStack
                                p={2}
                                bg="white"
                                rounded="md"
                                boxShadow="md"
                            >
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack>

                            : filteredTeams.map((team: any, i: number) => (
                                <Combobox.Option
                                    key={`${team?.teamid}`}
                                    value={team}
                                >
                                    {({ active }) => {
                                        // if (team?.teamid !== "0") return 
                                        return <HStack
                                            w="100%"
                                            p={2}
                                            bgColor={active ? "gray.50" : ""}
                                            cursor="pointer"
                                        >
                                            <Avatar
                                                size="xs"
                                                name={
                                                    !isUndefined(team?.teamname) ? team?.teamname : ""
                                                }
                                                color="white"
                                                verticalAlign="middle"
                                            />

                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                textAlign="left"
                                            >
                                                {team?.teamname}
                                            </Text>
                                        </HStack>
                                    }}
                                </Combobox.Option>
                            ))
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};