import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { isEmpty } from "lodash";
import {
	Avatar,
	Badge,
	Box,
	Flex,
	HStack,
	Icon,
	SlideFade,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from "@chakra-ui/react";
import {
	BsArrowDown,
	BsArrowUp,
	BsCheckCircleFill,
	BsClockHistory,
	BsDash,
	BsExclamationCircleFill,
	BsFillPlayBtnFill,
	BsXCircleFill,
} from "react-icons/bs";

import { Pager } from "../common/Pager";
import { localDate, localDateTime, localDateTimeIgnoreToday, relativeTime } from "../../helpers/DayJsHelper";

export const TeamTaskTable: React.FC<any> = ({
	currentItems,
	sort,
	setSort,
	loading,
	navData,
	setPageIndex
}) => {
	const navigateTo = useNavigate();

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "taskId",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "teamId",
				disableSortBy: true,
			},
			{
				Header: <>
					TASK {
						sort === "taskname" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "tasknamedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "title",
				Cell: (cell: any) => <VStack alignItems="start">
					<HStack>
						{
							cell.value.overdue && !navData.isGuided && !cell.value.mandatory && <Icon as={BsExclamationCircleFill} color="danger.500" />
						}

						<Text color="gray.600" fontSize="sm">
							{cell.value.name || "Untitled"}
						</Text>
					</HStack>

					{
						cell.value.mandatory && !navData.isGuided && <Badge
							fontWeight={500}
							textAlign="left"
							colorScheme="purple"
						>
							Mandatory Task
						</Badge>
					}
				</VStack>
			},
			{
				Header: <>
					DUE {
						sort === "duedate" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "duedatedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "due",
				Cell: (cell: any) => {
					if (cell.value) {
						return (
							<VStack alignItems="start">
								<Text color="gray.600" fontSize="sm">
									{localDateTime(cell.value)}
								</Text>

								<HStack>
									<Icon as={BsClockHistory} color="gray.500" />
									<Text color="gray.600" fontSize="sm">
										{relativeTime(cell.value)}
									</Text>
								</HStack>
							</VStack>
						);
					} else return <Text color="gray.500">Not scheduled</Text>;
				}
			},
			{
				Header: "LAST REPORT",
				accessor: "lastReport",
				disableSortBy: true,
				Cell: (cell: any) => (
					<VStack alignItems="start">
						<HStack>
							{
								cell.value.status === "passed" ? <Icon as={BsCheckCircleFill} color="brand.500" /> :
									cell.value.status === "failed" && <Icon as={BsXCircleFill} color="danger.500" />
							}

							<Text
								color="gray.600"
								fontSize="sm"
							>
								{
									cell.value.date && localDateTimeIgnoreToday(cell.value.date)
								}
							</Text>
						</HStack>

						{
							!isEmpty(cell.value.signedOffBy) ? <Text color="gray.500" fontSize="xs">
								By {cell.value.signedOffBy}
							</Text> : <Icon as={BsDash} color="gray.500" />
						}
					</VStack>
				)
			},
			{
				Header: "OBSERVATIONS REQUIRED",
				accessor: "requiredCount",
				disableSortBy: true,
				Cell: (cell: any) =>
					Number(cell.value) > 0 ? <Text
						color="gray.600"
						fontSize="xs"
						fontWeight={600}
					>
						<Badge colorScheme="red">
							{cell.value}
						</Badge>
					</Text> : <Text>-</Text>
			},
			{
				Header: "TEAM",
				accessor: "assigned",
				disableSortBy: true,
				Cell: (cell: any) => <HStack>
					<Avatar name={cell.value} size="xs" color="white" />
					<Text color="gray.600" fontSize="sm">
						{cell.value}
					</Text>
				</HStack>
			},
			{
				Header: "IN PROGRESS",
				accessor: "count",
				disableSortBy: true,
				Cell: (cell: any) => <VStack alignItems="start">
					<Text
						color="gray.600"
						fontSize="sm"
					>
						{cell.value}
					</Text>
				</VStack>
			}
		], [sort]
	);

	const sortColumn = (term: string) => {
		switch (term) {
			case "title":
				if (sort === "taskname") {
					setSort("tasknamedesc");
				} else if (sort === "tasknamedesc") {
					setSort("taskname");
				} else setSort("tasknamedesc");
				break;
			case "due":
				if (sort === "duedate") {
					setSort("duedatedesc");
				} else if (sort === "duedatedesc") {
					setSort("duedate");
				} else setSort("duedatedesc");
				break;
		}
	};

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: [
						"id",
						"teamId",
						"taskId",
						navData.isGuided && "lastReport",
						navData.isGuided && "due",
						!navData.isGuided && "requiredCount",
						!navData.isGuided && "count"
					]
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading}>
				<VStack
					display={{ base: "flex", lg: "none" }}
					mb={currentItems.length > 6 ? 5 : 0}
				>
					{
						currentItems.map((task: any, i: number) => (
							<Box
								key={i}
								p={3}
								w="full"
								className="hover-pop"
								cursor="pointer"
								boxShadow="xs"
								rounded="lg"
								border="1px solid"
								borderColor={
									task.title.mandatory ? "purple.100" :
										task.status === "overdue" ?
											"red.100" : "gray.100"
								}
								backgroundColor={
									task.title.mandatory ? "purple.50" :
										task.status === "overdue" ?
											"red.50" : "gray.50"
								}
								onClick={() => {
									navigateTo(`/tasks/tasksummary/${task.id}`, {
										state: {
											navData: navData,
											from: "tasks"
										}
									});
								}}
							>
								<VStack alignItems="start">
									<Text
										fontSize="md"
										fontWeight={600}
										color={
											task.title.mandatory ? "purple.600" :
												task.status === "overdue" ? "red.700" :
													"gray.700"
										}
										textAlign="start"
									>
										{task.title.name || "Untitled"}
									</Text>

									{
										task.title.mandatory && <Badge
											ml={3}
											fontWeight={500}
											textAlign="left"
											colorScheme="purple"
											variant="outline"
											size="sm"
										>
											Mandatory Task
										</Badge>
									}

									{
										!navData.isGuided && !task.title.mandatory &&
										(task.status === "overdue" || task.action === "inprogress") && <HStack spacing={2}>
											{
												task.status === "overdue" && <Badge
													colorScheme="danger"
													backgroundColor="transparent"
													size="sm"
													variant="outline"

												>
													<Icon
														as={BsExclamationCircleFill}
														mt="-2px"
														mr="2px"
														verticalAlign="middle"
													/>{" "}
													Overdue
												</Badge>
											}

											{
												task.inProgress && <Badge
													colorScheme="gray"
													backgroundColor="transparent"
													size="sm"
													variant="outline"

												>
													<Icon
														as={BsFillPlayBtnFill}
														mt="-2px"
														mr="2px"
														verticalAlign="middle"
													/>{" "}
													In Progress
												</Badge>
											}
										</HStack>
									}

									{
										navData.isGuided &&
										Number(task.requiredCount) > 0 && <Text
											color="gray.600"
											fontSize="xs"
											fontWeight={600}
										>
											<Badge colorScheme="red">
												{task.requiredCount}
											</Badge> Observations Required
										</Text>
									}

									<HStack>
										<Avatar name={task.assigned} size="xs" />
										<Text
											fontSize="sm"
											fontWeight={400}
											color="gray.600"
											textAlign="start"
										>
											{task.assigned}
											{
												task.due !== null && <>
													, due {relativeTime(task.due)}
												</>
											}
										</Text>
									</HStack>

									{
										!navData.isGuided && task.due && <Text
											fontSize="xs"
											color="gray.600"
											textAlign="start"
										>
											Due {localDate(task.due)}, {task.due.slice(task.due.lastIndexOf(" "), task.due.lastIndexOf(":"))}
										</Text>
									}

									{
										!navData.isGuided && task.lastReport.date && <HStack>
											{
												task.lastReport.status === "passed" && <Icon as={BsCheckCircleFill} fontSize="xs" color="brand.500" />
											}

											{
												task.lastReport.status === "failed" && <Icon as={BsExclamationCircleFill} fontSize="xs" color="danger.500" />
											}

											<Flex>
												{
													task.lastReport.status === "passed" ? <Text
														fontSize="xs"
														color="gray.500"
														textAlign="start"
													>
														Passed,&nbsp;
													</Text> : task.lastReport.status === "failed" && <Text
														fontSize="xs"
														color="gray.500"
														textAlign="start"
													>
														Failed,&nbsp;
													</Text>
												}

												<Text
													fontSize="xs"
													color="gray.500"
													textAlign="start"
												>
													{localDate(task.lastReport.date)} by {task.lastReport.signedOffBy}
												</Text>
											</Flex>
										</HStack>
									}
								</VStack>
							</Box>
						))
					}
				</VStack>

				<Table
					{...getTableProps()}
					id="TaskTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
				>
					<Thead>
						{
							headerGroups.map((headerGroup, i) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column, i) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => onHeaderClick(column)}
												key={`task_header_${i}`}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.map((row, i) => {
								prepareRow(row);

								return (
									<Tr
										{...row.getRowProps()}
										className="hover-pop"
										key={i}
										bg={i % 2 ? "white" : "purple.50"}
										borderBottom="1px solid"
										borderColor="gray.100"
										cursor="pointer"
										rounded="lg"
										onClick={() => {
											navigateTo(`/tasks/tasksummary/${row.values.id}`, {
												state: {
													navData: navData,
													from: "tasks"
												}
											});
										}}
									>
										{
											row.cells.map((cell) => {
												return (
													<Td wordBreak="break-word" {...cell.getCellProps()}>
														{cell.render("Cell")}
													</Td>
												);
											})
										}
									</Tr>
								);
							})
						}
					</Tbody>
				</Table>

				{
					navData.pageCount > 1 && <Box
						mt={3}
						textAlign="center"
					>
						<Pager
							pageCount={navData.pageCount || 0}
							pageIndex={navData.pageIndex || 0}
							setPageIndex={setPageIndex}
						/>
					</Box>
				}
			</SlideFade>
		);
	}

	return DataTable({
		data: currentItems ?? [],
		columns,
		loading,
		pageCount: navData.pageCount,
		onHeaderClick: (c: any) => sortColumn(c.id)
	});
};