import {
	Box,
	Button,
	HStack,
	Icon,
	Text
} from "@chakra-ui/react";
import {
	BsChevronDoubleLeft,
	BsChevronDoubleRight,
	BsChevronLeft,
	BsChevronRight,
} from "react-icons/bs";
import { IconType } from "react-icons/lib";

export const Pager: React.FC<any> = ({
	pageCount,
	pageIndex,
	setPageIndex,
	loading
}): JSX.Element => {
	type PagerButtonProps = {
		index: Number
		hidden: boolean,
		disabled: boolean,
		icon: IconType
	};

	const PagerButton = ({ index, hidden, disabled, icon }: PagerButtonProps): JSX.Element =>
		<Button
			p={0}
			bg="white"
			boxShadow="xs"
			_hover={{bg: "gray.50"}}
			_active={{
				bgColor: "gray.100",
				borderColor: "gray.100",
				boxShadow: "0px 2px 3px 0px #00000014 inset"
			}}
			border="1px solid"
			borderColor="gray.200"
			size="md"
			onClick={async (): Promise<void> => {
				setPageIndex(index);
			}}
			disabled={disabled}
			hidden={hidden}
		>
			<Icon as={icon} />
		</Button>;

	let pagerButtons = [];
	let range: Number[] = [];

	// Set previous buttons
	pagerButtons.push(
		<PagerButton
			key={0}
			index={0}
			hidden={pageIndex < 2}
			disabled={loading}
			icon={BsChevronDoubleLeft}
		/>,
		<PagerButton
			key={1}
			index={pageIndex - 1}
			hidden={false}
			disabled={loading || pageIndex === 0}
			icon={BsChevronLeft}
		/>
	);

	// Set page range and current buttons
	// pageCount + 1 because "0" returns ALL results from server by default
	const total = Array
		.from(Array(pageCount + 1).keys())
		.filter((i) => i !== 0);

	if (pageIndex === pageCount - 1) {
		// If last page
		if (total.length >= 3) {
			range = [total[pageIndex - 2], total[pageIndex - 1], total[pageIndex]];
		} else if (total.length === 2) {
			range = [total[pageIndex - 1], total[pageIndex]];
		} else if (total.length === 1) {
			range = [total[pageIndex]];
		}
	} else if (pageIndex === 0) {
		// If first page
		if (total.length >= 3) {
			range = [total[pageIndex], total[pageIndex + 1], total[pageIndex + 2]];
		} else if (total.length === 2) {
			range = [total[pageIndex], total[pageIndex + 1]];
		} else if (total.length === 1) {
			range = [total[pageIndex]];
		}
	} else
		range = [total[pageIndex - 1], total[pageIndex], total[pageIndex + 1]];

	for (let i = 0; i < range.length; i++) {
		if (range.length > 2)
			pagerButtons.push(
				<Button
					key={i + 2}
					bg={pageIndex + 1 === range[i] ? "brand.500" : "white"}
					_hover={{
						bg: pageIndex + 1 === range[i] ? "brand.500" : "gray.50",
					}}
					_active={{
						bgColor: "gray.100",
						borderColor: "gray.100",
						boxShadow: "0px 2px 3px 0px #00000014 inset"
					}}
					size="md"
					boxShadow="xs"
					border="1px solid"
					borderColor={pageIndex + 1 === range[i] ? "transparent" : "gray.200"}
					disabled={loading}
					onClick={async (): Promise<void> => {
						let newIndex: number = range[i].valueOf() - 1;
						setPageIndex(newIndex);
					}}
				>
					<Text color={pageIndex + 1 === range[i] ? "white" : "gray.500"}>
						{range[i]}
					</Text>
				</Button>
			);
	}

	// Set next buttons
	pagerButtons.push(
		<PagerButton
			key={pageCount + 2}
			index={pageIndex + 1}
			hidden={false}
			disabled={loading || (pageIndex - 1 >= pageCount - 1 - 1)}
			icon={BsChevronRight}
		/>,
		<PagerButton
			key={pageCount + 3}
			index={pageCount - 1}
			hidden={pageIndex - 1 >= pageCount - 1 - 2}
			disabled={loading}
			icon={BsChevronDoubleRight}
		/>
	);

	return (
		<Box textAlign="center" display="inline-grid">
			<HStack gap={0} color="gray.600">
				{pagerButtons}
			</HStack>
		</Box>
	);
};