import { createSlice } from '@reduxjs/toolkit';

const createInitialState = () => {
    return {
        messageFolders: {
            inboxCount: 0,
            draftCount: 0,
            sentCount: 0,
            unreadCount: 0,
            unreadCriticalCount: 0
        },
        drafts: [],
        sent: [],
        error: null,
    }
}

const messageSlice = createSlice({
    name: "messages",
    initialState: createInitialState(),
    reducers: {
        getMessageFoldersSuccess: (state, { payload }) => {
            state.messageFolders.inboxCount = payload.inboxCount;
            state.messageFolders.draftCount = payload.draftCount;
            state.messageFolders.sentCount = payload.sentCount;
            state.messageFolders.unreadCount = payload.unreadCount;
            state.messageFolders.unreadCriticalCount = payload.unreadCriticalCount;
            
            state.error = null;
        },
        getMessageFoldersFailure: (state, { payload }) => {
            state.error = payload.error;
        },
        getDraftsSuccess: (state, { payload }) => {
            state.drafts = payload.messages;
            state.error = null;
        },
        getDraftsFailure: (state, { payload }) => {
            state.error = payload.error;
        },
        getSentSuccess: (state, { payload }) => {
            state.sent = payload.messages;
            state.error = null;
        },
        getSentFailure: (state, { payload }) => {
            state.error = payload.error;
        }
    }
});

export const {
    getMessageFoldersSuccess,
    getMessageFoldersFailure,
    getDraftsSuccess,
    getDraftsFailure,
    getSentSuccess,
    getSentFailure
} = messageSlice.actions;

export const messageReducer = messageSlice.reducer;