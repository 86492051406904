import { useCallback, useEffect, useState } from "react";
import {
	Box,
	Center,
	FormControl,
	Input,
	Image,
	Select,
	Text,
	VStack,
	SlideFade,
	Stack,
	Divider,
	Grid,
	useToast,
	useBreakpointValue
} from "@chakra-ui/react";
import { getGuidedTasks } from "../../../features/tasks/task.actions";
import { SmallFilterCard } from "../../common/SmallFilterCard";
import { TeamTaskTable } from "../TeamTaskTable";
import noTasks from "../../../assets/images/no-tasks.svg";
import { useDebounce } from "../../common/useDebounce";
import { FaClipboardCheck } from "react-icons/fa";
import { TeamCombobox } from "../../common/TeamCombobox";
import { InsightActionCard } from "../../common/InsightActionCard";

import nothing from "../../../components/homepage/svg/nothing.svg";
import observationsInProgress from "../../../components/homepage/svg/observations_in_progress.svg";

export const GuidedTasks: React.FC<any> = ({
	tabIndex,
	pageCount,
	setPageCount,
	pageIndex,
	setPageIndex,
	filter,
	setFilter,
	teamId,
	setTeamId,
	category,
	setCategory,
	search,
	setSearch,
	clearAllFilters,
	setClearAllFilters,
	setFilterSelected
}) => {
	const toast = useToast();
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);

	const [categories, setCategories] = useState([]);
	const [teams, setTeams] = useState([]);
	const [currentItems, setCurrentItems] = useState([]);
	const [sort, setSort] = useState<string>("duedate");
	const [kind] = useState<string>("all");
	const [error, setError] = useState<string>('');

	const [observationsInProgressCount, setObservationsInProgressCount] = useState(0);
	const [observationsRequiredCount, setObservationsRequiredCount] = useState(0);

	const fetchData = useCallback(
		async ({
			category,
			teamId,
			search,
			pageIndex,
			sort,
			filter
		}) => {
			setLoading(true);

			await getGuidedTasks(
				sort,
				filter,
				kind,
				teamId,
				category,
				search,
				(pageIndex + 1).toString()
			)
				.then((res: any) => {
					const formattedCurrentItems = res.tasks?.map((task: any, i: number) => {
						const lastReportDate = task.tth[0]?.tthdatesignedoff;
						const lastReportSignedOffBy = task.tth[0]?.signedoffbyname;

						let lastReportStatus = "";

						if (task.tth.length > 0 && task.tth[0]?.isfailed) {
							lastReportStatus = "failed";
						} else if (task.tth.length > 0 && task.tth[0]?.ispassed) {
							lastReportStatus = "passed";
						}

						return {
							id: task.ttid,
							taskId: task.taskid,
							teamId: task.teamid,
							title: { overdue: task.overdue, name: task.taskname },
							status:
								task.overdue ? "overdue" : task.whendue === "today" ? "today" : "",
							due: task.ttdatedue,
							lastReport: {
								date: lastReportDate,
								status: lastReportStatus,
								signedOffBy: lastReportSignedOffBy
							},
							assigned: task.teamname,
							whenDue: task.whendue,
							action: lastReportStatus,
							requiredCount: task.observationsrequiredcount,
							count: task.observationcount
						};
					});

					setCurrentItems(formattedCurrentItems);
					setPageCount(res.pageCount);
					setCategories(res.categories);
					setTeams(res.teams);
					setObservationsInProgressCount(res.observationsInProgressCount);
					setObservationsRequiredCount(res.observationsRequiredCount);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
		}, []
	);

	useEffect(() => {
		fetchData({
			category,
			teamId,
			search,
			pageIndex,
			sort,
			filter
		});
	}, [fetchData, category, teamId, search, pageIndex, sort, filter]);

	useEffect(() => {
		const filterInEffect =
			search !== "" ||
			(teamId !== 0 && teamId !== undefined) ||
			(category !== "" && category !== undefined);

		setFilterSelected(filterInEffect);
	}, [category, search, teamId]);

	useEffect(() => {
		if (clearAllFilters) {
			setTeamId(0);
			setSearch("");
			updateCategory("");
			setPageIndex(0);
			setSort("duedate");
			setError('');
			setClearAllFilters(false);
		}
	}, [clearAllFilters]);

	const debouncedRequest = useDebounce(() => {
		setPageIndex(0);

		fetchData({
			category,
			teamId,
			search,
			pageIndex: 0,
			sort
		});
	}, 300);

	const updateSearch = (e: any) => {
		setSearch(e.target.value);

		if (/[^a-zA-Z0-9\s]+/.test(e.target.value)) {
			setError('Search text contains characters that are not allowed.');

			toast({
				title: "Search text contains characters that are not allowed.",
				description: "",
				status: "error",
				duration: 3000,
				isClosable: false
			});

			return;
		} else {
			setError('');
		}

		debouncedRequest();
	};

	const updateCategory = (category: any) => {
		setCategory(category);
		setPageIndex(0);
	};

	return (
		<Box>
			<Grid
				templateColumns={{
					base: "repeat(3, 1fr)",
					md: "repeat(4, 1fr)"
				}}
				gap={5}
				mb={5}
				mt={1}
			>
				<InsightActionCard
					title="Observations Required"
					count={observationsRequiredCount ?? 0}
					image={nothing}
					color="red.500"
					active={filter === "required"}
					loading={firstTimeLoading}
					onClickAction={() => {
						if (filter === "required") {
							setFilter("all");
							setPageIndex(0);
						} else {
							setFilter("required");
							setPageIndex(0);
						}
					}}
				/>

				<InsightActionCard
					title="Observations in Progress"
					count={observationsInProgressCount ?? 0}
					image={observationsInProgress}
					color="warning.500"
					active={filter === "inProgress"}
					loading={firstTimeLoading}
					onClickAction={() => {
						if (filter === "inProgress") {
							setFilter("all");
							setPageIndex(0);
						} else {
							setFilter("inProgress");
							setPageIndex(0);
						}
					}}
				/>

			</Grid>

			{
				!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} />
			}

			<SlideFade in={!firstTimeLoading} offsetY="20px">
				{
					categories?.length > 0 && categories?.length <= 7 &&
					<Box
						p={1.5}
						mt={5}
						bg="gray.50"
						textAlign="left"
						rounded="lg"
						border="1px solid"
						borderColor="gray.100"
					>
						<Text
							fontWeight={500}
							color="gray.500"
							fontSize="sm"
							ml={1}
							mb={2}
						>
							Task Categories
						</Text>

						<SlideFade in={!firstTimeLoading}>
							{
								categories?.map((c: any, i: Number) => {
									return (
										<SmallFilterCard
											key={i}
											title={c.taskcategory}
											count={c.taskcount}
											onClickAction={() => {
												c.taskcategory !== category
													? updateCategory(c.taskcategory)
													: updateCategory("");
											}}
											selectedCard={category}
											icon={FaClipboardCheck}
											iconColor="brand.500"
										/>
									);
								})
							}
						</SlideFade>
					</Box>
				}
			</SlideFade>

			<SlideFade in={!firstTimeLoading} offsetY="20px">
				<Stack
					direction={{ base: "column", lg: "row" }}
					mt={
						categories.length > 0 && categories.length <= 7 ? 2 :
							categories.length > 7 ? 5 : 5
					}
				>
					{
						categories?.length > 7 &&
						<>
							<FormControl w={{ base: "full", lg: "50%" }}>
								<Input
									placeholder="Search Tasks"
									fontSize="sm"
									value={search}
									onChange={updateSearch}
								/>
							</FormControl>

							{
								error && <Text
									mt={2}
									ml={2}
									color={'red.500'}
									fontSize="sm"
								>
									{error}
								</Text>
							}
						</>
					}

					{
						categories?.length > 7 ?
							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
								<Select
									_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
									color="gray.600"
									fontSize="sm"
									onChange={(e) => updateCategory(e.target.value)}
									value={category}
									placeholder="Search by Category"
								>
									{
										categories?.map((c: any, i) => {
											return <option key={i} value={c.taskcategory}>{c.taskcategory}</option>;
										})
									}
								</Select>
							</FormControl> :

							<FormControl w={{ base: "full", lg: "50%" }}>
								<Input
									placeholder="Search Tasks"
									fontSize="sm"
									value={search}
									onChange={updateSearch}
								/>
							</FormControl>
					}

					<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
						<TeamCombobox
							teams={teams || []}
							update={(value: any) => {
								setPageIndex(0)
								setTeamId(value)
							}}
							selectedTeam={teamId}
						/>
					</FormControl>
				</Stack>
			</SlideFade>

			<Box
				p={3}
				mt={5}
				border="1px"
				borderColor={firstTimeLoading ? "transparent" : "gray.200"}
				rounded="lg"
			>
				{
					currentItems?.length > 0 && !firstTimeLoading ?
						<TeamTaskTable
							loading={loading}
							currentItems={currentItems}
							sort={sort}
							setSort={setSort}
							setPageIndex={setPageIndex}
							navData={{
								tabIndex,
								pageCount,
								pageIndex,
								filter,
								category,
								teamId,
								search,
								isGuided: true
							}}
						/> :

						<SlideFade in={!loading} offsetY="20px">
							<Center m={5} hidden={firstTimeLoading || loading}>
								<VStack>
									<Image mb={8} src={noTasks} />

									<Text fontSize="xl" fontWeight={600}>
										No Task Observations Found
									</Text>
								</VStack>
							</Center>
						</SlideFade>
				}
			</Box>
		</Box>
	);
};