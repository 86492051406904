import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _, { isEmpty } from "lodash";
import {
	Box,
	Divider,
	Grid,
	HStack,
	Icon,
	Image,
	Text,
	Button,
	Badge,
	SlideFade,
	Center,
	Spinner,
	VStack,
	Tab,
	TabList,
	Tabs,
	TabPanel,
	TabPanels,
	Flex,
	useToast,
	Spacer,
	useBreakpointValue
} from "@chakra-ui/react";
import {
	BsArrowLeftCircleFill,
	BsCheckCircleFill,
	BsExclamationCircleFill,
	BsEye,
	BsPlayCircle,
	BsX,
} from "react-icons/bs";
import { FaClipboardCheck } from "react-icons/fa";
import { VscMortarBoard } from "react-icons/vsc";
import {
	getGuidedTaskAttempts,
	getGuidedTaskReports,
	getTask,
	getTaskReports,
	getTeamTaskAttempts,
	startTaskAttempt
} from "../../features/tasks/task.actions";
import { getTeamMembers } from "../../features/admin/admin.actions";
import { TeamTaskHistoryTable } from "./TeamTaskHistoryTable";
import { TaskSummaryCard } from "./TaskSummaryCard";
import { TaskAttemptsTable } from "./TaskAttemptsTable";
import { Pager } from "../common/Pager";
import validTraining from "../../assets/images/validTraining.svg";
import { GuidedObservationsTable } from "./guidedTasks/GuidedObservationsTable";
import noTasks from "../../assets/images/no-tasks.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { localDateTimeIgnoreToday, relativeTime } from "../../helpers/DayJsHelper";

export const TaskSummary = () => {
	const navigateTo = useNavigate();
	const toast = useToast();
	const isDesktop = useBreakpointValue({ base: false, lg: true });
	const user = useSelector((state: RootState) => state.authReducer.user);

	const { id } = useParams();
	const { state }: any = useLocation();

	const [loading, setLoading] = useState<boolean>(true);
	const [loadingReports, setLoadingReports] = useState<boolean>(true);
	const [taskData, setTaskData] = useState<any>({});
	const [latestAttempt, setLatestAttempt] = useState<any>([]);
	const [taskAttempts, setTaskAttempts] = useState<any>([]);

	// from API
	const [guidedAttempts, setGuidedAttempts] = useState<any>([]);
	const [requiredObservations, setRequiredObservations] = useState<any>([]);
	const [distinctObservations, setDistinctObservations] = useState<any>([]);
	const [displayObservations, setDisplayObservations] = useState<any>([]);

	const [guidedTaskAttempts, setGuidedTaskAttempts] = useState<any>([]);

	const [trainingStatus, setTrainingStatus] = useState<any>({});
	const [trainingIsValid, setTrainingIsValid] = useState<any>({});

	const [teamUsers, setTeamUsers] = useState<any>([]);
	const [selectedUser, setSelectedUser] = useState<any>(state?.navData?.selectedUser || "");

	const [taskHistoryData, setTaskHistoryData] = useState<any>([]);

	const [reportData, setReportData] = useState<any>({
		teamId: 0,
		taskId: 0
	});

	const [filter, setFilter] = useState<string>(
		state?.filter || ""
	);

	const [filterCounts, setFilterCounts] = useState<any>({
		required: 0,
		inProgress: 0
	});

	const [reportsNavData, setReportsNavData] = useState({
		tabIndex: state?.reportsNavData?.tabIndex || 0,
		pageIndex: state?.reportsNavData?.pageIndex || 0,
		pageCount: 0
	});

	useEffect(() => {
		if (reportData.teamId !== 0 && reportData.taskId !== 0) {
			fetchReports(reportData.teamId, reportData.taskId);
		}
	}, [reportsNavData.pageIndex]);

	useEffect(() => {
		setLoading(true);

		getTask(Number(id))
			.then((res) => {
				setTrainingIsValid(res.trainingStatus);
				setTaskData(res.task);
				setLatestAttempt(res.myLatestAttempt);
				setGuidedTaskAttempts(res.guidedTaskAttempts);
				setTrainingStatus(res.trainingStatus);

				setReportData({
					...reportData,
					taskId: Number(res.task.taskid),
					teamId: Number(res.task.teamid)
				});

				fetchReports(res.task.teamid, res.task.taskid);

				if (state?.navData?.isGuided) {
					getGuidedObservationsForTask(Number(id));
				}
				else {
					getAttempts();
				}

				getTeamUsers(Number(res.task.teamid));
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const onTabIndexChange = (index: number) => {
		setReportsNavData({
			...reportsNavData,
			tabIndex: index,
			pageIndex: 0
		});
	};

	const getTeamUsers = async (teamId: Number) => {
		const members = await getTeamMembers(teamId);
		setTeamUsers(members.members);
	};

	const startAttempt = (targetUser?: any) => {
		startTaskAttempt(
			Number(id),
			state?.navData?.isGuided ? 1 : taskData.canstartperiodictask ? 2 : 0,
			taskData.canstartperiodictask ? user.id : targetUser ?? selectedUser
		)
			.then((res) => {
				if (!res.success && res.message) {
					toast({
						title: res.message,
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true
					});

					return;
				}

				if (res?.attempt?.ttaid) {
					navigateTo(`/tasks/task/${res.attempt.ttaid}`, {
						state: {
							id: res.attempt.ttaid,
							cardStatus: trainingIsValid,
							teamTaskId: id,
							teamId: reportData.teamId,
							taskId: reportData.taskId,
							navData: { ...state?.navData, isMandatory: taskData.ttcanstartperiodictask === "1" }
						}
					});
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	};

	const getCardStatus = (training: any): boolean => {
		for (var key in training) {
			if (training.hasOwnProperty(key) && !training[key].valid) return false;
		}

		return true;
	};

	const renderTrainingCard = (training: any) => {
		let rows: any[] = [];
		let trainingIsValid = true;
		let i = 0;

		for (var key in training) {
			if (training.hasOwnProperty(key)) {
				const attemptId = key;

				if (!training[attemptId].valid) trainingIsValid = false;

				rows.push(
					<Box
						className="hover-pop"
						cursor="pointer"
						rounded="lg"
						boxShadow="xs"
						border="1px solid"
						borderColor="gray.200"
						backgroundColor={i % 2 ? "white" : "gray.50"}
						width="100%"
						p={3}
						onClick={() => {
							navigateTo(`/training/module/${attemptId}`, {
								state: {
									from: "task"
								}
							});
						}}
					>
						<HStack>
							<Icon
								as={trainingIsValid ? BsCheckCircleFill : BsExclamationCircleFill}
								color={trainingIsValid ? "brand.500" : "danger.500"}
							/>
							<Text fontWeight={400} fontSize="sm">
								{training[attemptId].cname}
							</Text>
						</HStack>
					</Box>
				);

				i++;
			}
		}

		if (rows.length > 0) return <SlideFade in={!loading} offsetY="20px">
			<VStack>{rows}</VStack>
		</SlideFade>;
		else
			return (
				<SlideFade in={!loading} offsetY="20px">
					<Center m={5} h="150px">
						<VStack>
							<Image mb={12} src={validTraining} />

							<Text fontSize="sm" color="gray.500" fontWeight={400} textAlign="center">
								{
									state?.navData?.isGuided ? "Any training associated with the selected Team Member will appear here" :
										"Any related training will appear here"
								}
							</Text>
						</VStack>
					</Center>
				</SlideFade>
			);
	};

	const fetchReports = (teamId: number, taskId: number) => {
		if (state?.navData?.isGuided) {
			getGuidedTaskReports(teamId, taskId, reportsNavData.pageIndex + 1)
				.then((res) => {
					if (!res.success) {
						console.log("Unable to get team task reports");
					} else {
						const extraColumns = Object.entries(res.variables).slice(0, 4);
						const formattedTaskHistoryData = res.reports.map((report: any) => {
							const row = {
								id: report.tthid || report.gthid,
								targetUser: `${report.targetuserforename} ${report.targetusersurname}`,
								lastReport: report.gthdatesignedoff,
								signedOffBy: `${report.signedoffbyforename} ${report.signedoffbysurname}`,
								status: report.rowclass === "text-danger" ? "failed" : report.rowclass === "text-success" ? "passed" : "",
								taskId: report.gthteamtask
							};

							for (let i = 0; i < extraColumns.length; i++) {
								const currentColumn = extraColumns[i][1];

								if (typeof (currentColumn) === "string") {
									const currentValue = _.find(report.extraData, ['id', Number(extraColumns[i][0])]);
									Object.assign(row, { [currentColumn]: currentValue.value });
								}
							}

							return row;
						});

						setTaskHistoryData(formattedTaskHistoryData);
						setReportsNavData({
							...reportsNavData,
							pageCount: res.pageCount
						});
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoadingReports(false);
				});
		} else
			getTaskReports(teamId, taskId, reportsNavData.pageIndex + 1)
				.then((res) => {
					if (!res.success) {
						console.log("Unable to get team task reports");
					} else {
						const extraColumns = Object.entries(res.variables).slice(0, 4);
						const formattedTaskHistoryData = res.reports.map((report: any) => {
							const row = {
								id: report.tthid,
								status: report.rowclass === "text-danger" ? "failed" : report.rowclass === "text-success" ? "passed" : "",
								lastReport: report.tthdatesignedoff,
								signedOffBy: `${report.udforename} ${report.udsurname}`,
								taskId: report.tthteamtask
							};

							for (let i = 0; i < extraColumns.length; i++) {
								const currentColumn = extraColumns[i][1];

								if (typeof (currentColumn) === "string") {
									const currentValue = _.find(report.extraData, ['id', Number(extraColumns[i][0])]);
									Object.assign(row, { [currentColumn]: currentValue.value });
								}
							}

							return row;
						});

						setTaskHistoryData(formattedTaskHistoryData);
						setReportsNavData({
							...reportsNavData,
							pageCount: res.pageCount
						});
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoadingReports(false);
				});
	};

	const getAttempts = () => {
		getTeamTaskAttempts(Number(id))
			.then((res) => {
				const formattedTaskAttemptData = res.attempts.map(
					(tta: any) => {
						return {
							id: tta.ttaid,
							started: tta.ttadatecreated,
							by: tta.createdbyname
						};
					}
				);
				setTaskAttempts(formattedTaskAttemptData);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getGuidedObservationsForTask = (id: any) => {
		getGuidedTaskAttempts(Number(id))
			.then((res: any) => {
				const formattedAttemptData = res.attempts?.map(
					(attempt: any) => {
						return {
							name: attempt?.targetusername,
							targetUserId: attempt?.ttatargetuser,
							started: attempt?.ttadatecreated,
							action: "Resume",
							attemptId: attempt?.ttaid,
							required: false
						};
					}
				);
				setGuidedAttempts(formattedAttemptData);

				const formattedObservationData = res.observationsRequired?.map(
					(observation: any) => {
						return {
							name: observation?.targetusername,
							targetUserId: observation?.targetuserid,
							started: observation?.ttadatecreated,
							action: observation?.ttaid ? "Resume" : "Start",
							attemptId: observation?.ttaid,
							required: true
						};
					}
				);
				setRequiredObservations(formattedObservationData);

				const formattedDistinctData = res.distinctObservations?.map(
					(distinctObs: any) => {
						return {
							name: distinctObs?.targetusername,
							targetUserId: distinctObs?.targetuserid,
							started: distinctObs?.ttadatecreated,
							action: distinctObs?.ttaid ? "Resume" : "Start",
							attemptId: distinctObs?.ttaid,
							required: true
						};
					}
				);
				setDistinctObservations(formattedDistinctData);

				// initially display the unique observations
				setDisplayObservations(formattedDistinctData);

				setFilterCounts({
					required: res.observationsReqCount,
					inProgress: res.attemptCount,
				});
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			<HStack mb={2}>
				<Button
					display={{ base: "none", md: "unset" }}
					w="fit-content"
					size="sm"
					variant="outline"
					color="gray.600"
					fontWeight={600}
					boxShadow="xs"
					onClick={() => {
						if (state?.from === "tasks") {
							navigateTo("/tasks", {
								state: {
									navData: state?.navData,
									from: "tasks"
								}
							});
						} else
							navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircleFill} />
						<Text>
							{
								state?.from === "home" ? "Back to Home" :
									state?.from === "notification" ? "Back to Notification" :
										state?.from === "tasks" && "Back to Tasks"
							}
						</Text>
					</HStack>
				</Button>
			</HStack>

			<Box
				h={{ base: "full", md: "unset" }}
				pt={2}
				pb={3}
				px={{ base: 0, md: 5 }}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
			>
				<HStack
					mb={{ base: 3, lg: 0 }}
					mx={3}
					gap={3}
				>
					<Button
						display={{ base: "unset", md: "none" }}
						size="sm"
						color="gray.600"
						variant="link"
						fontWeight={600}
						onClick={() => {
							if (state?.from === "tasks") {
								navigateTo("/tasks", {
									state: {
										navData: state?.navData,
										from: "tasks"
									}
								});
							} else
								navigateTo(-1);
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircleFill} />
							<Text>
								{
									state?.from === "home" ? "Back to Home" :
										state?.from === "notification" ? "Back to Notification" :
											state?.from === "tasks" && "Back to Tasks"
								}
							</Text>
						</HStack>
					</Button>
				</HStack>

				<SlideFade in={!loading} offsetY="20px">
					<HStack p={3} pb={5}>
						<Icon
							mr={3}
							as={state?.navData?.isGuided ? BsEye : FaClipboardCheck}
							verticalAlign="middle"
							fontSize="4xl"
							color="green.500"
						/>

						<VStack alignItems="start">
							<Text
								fontWeight={{ base: 600, lg: 700 }}
								fontSize={{ base: "xl", lg: "2xl" }}
								textAlign="left"
								color="gray.700"
							>
								{taskData.taskname}

								{
									((state?.navData?.isMandatory || taskData.canstartperiodictask) ||
										(user.isTeamManager && taskData.ttcanstartperiodictask === "1")) && <Badge
											ml={3}
											fontWeight={500}
											textAlign="left"
											colorScheme="purple"
										>
										Mandatory Task
									</Badge>
								}
							</Text>

							{
								state?.navData?.isGuided && <Text
									fontWeight={500}
									fontSize={{ base: "sm", lg: "md" }}
									textAlign="left"
									color="gray.500"
								>
									Task Observation
								</Text>
							}
						</VStack>
					</HStack>
				</SlideFade>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				<Tabs
					colorScheme="green"
					defaultIndex={reportsNavData.tabIndex}
					onChange={(index) => onTabIndexChange(index)}
				>
					{
						!((state?.navData?.isMandatory || taskData.canstartperiodictask) ||
							(user.isTeamManager && taskData.ttcanstartperiodictask === "1")) && <Flex
								justify={{ base: "center", md: "space-between" }}
								pt={2}
								pb={5}
							>
							<TabList
								mx={{ base: 0, md: 3 }}
								w={{ base: "full", md: "unset" }}
								justifyContent="center"
								hidden={loading}
							>
								<Tab>
									<Text
										color={reportsNavData.tabIndex === 0 ? "green.500" : "gray.500"}
										fontWeight={reportsNavData.tabIndex === 0 ? 700 : 500}
									>
										{state?.navData?.isGuided ? "Details" : "Task Details"}
									</Text>
								</Tab>

								<Tab>
									<Text
										color={reportsNavData.tabIndex === 1 ? "green.500" : "gray.500"}
										fontWeight={reportsNavData.tabIndex === 1 ? 700 : 500}
									>
										Reports
									</Text>
								</Tab>

								<Tab hidden={state?.navData?.isGuided}>
									<Text
										color={reportsNavData.tabIndex === 2 ? "green.500" : "gray.500"}
										fontWeight={reportsNavData.tabIndex === 2 ? 700 : 500}
									>
										In Progress
									</Text>

									<Badge
										bg={reportsNavData.tabIndex === 2 ? "green.500" : "gray.500"}
										color="white"
										ml={2}
									>
										{
											loading ? <Spinner size="xs" thickness="1px" /> :
												state?.navData?.isGuided ?
													requiredObservations?.length ?? 0 : taskAttempts.length
										}
									</Badge>
								</Tab>
							</TabList>
						</Flex>
					}

					<TabPanels>
						<TabPanel p={0}>
							{
								taskData.overdue &&
								!state?.navData?.isGuided &&
								taskData.ttcanstartperiodictask !== "1" && <Box
									borderLeft="3px solid"
									borderLeftColor="red.500"
									bg="red.50"
									m={3}
									px={{ base: "4", md: "3" }}
									py={{ base: "4", md: "2.5" }}
									borderRadius="md"
									boxShadow="xs"
								>
									<HStack>
										<Icon as={BsExclamationCircleFill} color="danger.500" />
										<Text
											textAlign="left"
											fontWeight={500}
											color="gray.600"
											fontSize="sm"
										>
											This task was due {relativeTime(taskData.ttdatedue)}, {localDateTimeIgnoreToday(taskData.ttdatedue)}
										</Text>
									</HStack>
								</Box>
							}

							<Grid
								templateColumns={{ sm: "repeat(1, 2fr)", xl: "repeat(3, 1fr)" }}
								gap={5}
								m={3}
								mt={5}
							>
								<SlideFade in={!loading}>
									<TaskSummaryCard
										teamId={reportData.teamId}
										taskId={reportData.taskId}
										teamTaskId={id}
										schedule={taskData.schedule}
										dueDate={taskData.ttdatedue}
										cardStatus={trainingIsValid}
										startAttempt={startAttempt}
										startAttemptDisabled={state?.navData?.isGuided && isEmpty(selectedUser)}
										latestAttempt={latestAttempt}
										trainingIsValid={trainingIsValid}
										teamUsers={teamUsers}
										selectedUser={selectedUser}
										setSelectedUser={setSelectedUser}
										summary={false}
										taskData={taskData}
										isManager={user.isTeamManager}
									/>
								</SlideFade>

								{
									!loadingReports && state?.navData?.isGuided && <Box
										border="1px solid"
										borderColor="gray.200"
										rounded="lg"
										p={2}
										gridColumnStart={{ xl: 2 }}
										gridColumnEnd={{ xl: 4 }}
									>
										<HStack mx={6} my={3}>
											<Icon
												as={BsPlayCircle}
												color="gray.600"
											/>
											<Text
												fontSize="lg"
												fontWeight={600}
												color="gray.600"
											>
												Observations
											</Text>

											{
												requiredObservations?.length > 0 && <>
													<Spacer />

													<Button
														className={filter === "required" ? "" : "hover-pop"}
														border="1px solid"
														borderColor={filter === "required" ? "brand.500" : "gray.200"}
														boxShadow="xs"
														rounded="lg"
														bg={filter === "required" ? "white" : "gray.50"}
														color="gray.500"
														fontWeight={400}
														size={isDesktop ? "sm" : "xs"}
														px={isDesktop ? 3 : 2}
														_hover={{ bg: "" }}
														_active={{ bg: "" }}
														onClick={() => {
															if (filter === "required") {
																setFilter("all");
																setDisplayObservations(displayObservations);
															} else {
																setFilter("required");
																setDisplayObservations(requiredObservations);
															}
														}}
													>
														<HStack>
															{
																loading ? <Spinner size={isDesktop ? "sm" : "xs"} color="red.500" />
																	: <Badge size={isDesktop ? "sm" : "xs"} color="white" bg="red.500">{filterCounts.required}</Badge>
															}
															<Text fontSize={isDesktop ? "sm" : "xs"}> Required</Text>
															{
																filter === "required" && <Icon as={BsX} />
															}
														</HStack>
													</Button>

													<Button
														className={filter === "inProgress" ? "" : "hover-pop"}
														border="1px solid"
														borderColor={filter === "inProgress" ? "brand.500" : "gray.200"}
														boxShadow="xs"
														rounded="lg"
														bg={filter === "inProgress" ? "white" : "gray.50"}
														color="gray.500"
														fontWeight={400}
														size={isDesktop ? "sm" : "xs"}
														px={isDesktop ? 3 : 2}
														_hover={{ bg: "" }}
														_active={{ bg: "" }}
														onClick={() => {
															if (filter === "inProgress") {
																setFilter("all");
																setDisplayObservations(displayObservations);
															} else {
																setFilter("inProgress");
																setDisplayObservations(guidedAttempts);
															}
														}}
													>
														<HStack>
															{
																loading ? <Spinner size={isDesktop ? "sm" : "xs"} color="orange.400" />
																	: <Badge size={isDesktop ? "sm" : "xs"} color="white" bg="orange.400">{filterCounts.inProgress}</Badge>
															}
															<Text fontSize={isDesktop ? "sm" : "xs"}> In Progress</Text>
															{
																filter === "inProgress" && <Icon as={BsX} />
															}
														</HStack>
													</Button>
												</>
											}
										</HStack>

										{
											distinctObservations?.length > 0 ?
												<GuidedObservationsTable
													navData={state?.navData}
													attemptData={displayObservations}
													teamTaskId={id}
													teamId={reportData.teamId}
													taskId={reportData.taskId}
													cardStatus={trainingIsValid}
													startAttempt={startAttempt}
												/> :

												<SlideFade in={!loading}>
													<Center m={5} hidden={loading}>
														<VStack>
															<Image mb={8} src={noTasks} />
															<Text fontSize="xl" fontWeight={600}>
																No Observations
															</Text>
														</VStack>
													</Center>
												</SlideFade>
										}
									</Box>
								}

								{
									!state?.navData?.isGuided && <SlideFade in={!loading} offsetY="20px">
										<VStack w="100%">
											<Box
												w="100%"
												p={2}
												border="1px"
												borderRadius="12px"
												borderColor="gray.200"
											>
												<Box p={1}>
													<HStack display={{ base: "none", xl: "flex" }} mb={2}>
														<Icon
															fontSize="lg"
															as={VscMortarBoard}
															color="gray.600"
															mr={1}
														/>
														<Text fontSize="xs" fontWeight={700} color="gray.600">
															ASSOCIATED TRAINING
														</Text>
													</HStack>
												</Box>

												{renderTrainingCard(trainingStatus)}
											</Box>
										</VStack>
									</SlideFade>
								}
							</Grid>
						</TabPanel>

						<TabPanel>
							{
								loadingReports ? <Center my={20}>
									<Spinner
										thickness='4px'
										speed='0.65s'
										color="brand.500"
										size="xl"
									/>
								</Center> :

									taskHistoryData.length > 0 ?
										<Box
											p={{ base: 1, lg: 3 }}
											pb={3}
											border="1px solid"
											borderColor="gray.200"
											rounded="lg"
										>
											{
												<TeamTaskHistoryTable
													teamTaskId={id}
													currentItems={taskHistoryData}
													reportsNavData={reportsNavData}
													loading={loadingReports}
													isGuided={state?.navData?.isGuided}
												/>
											}

											{
												reportsNavData.pageCount > 1 && <Box
													mt={3}
													textAlign="center"
												>
													<Pager
														pageCount={reportsNavData.pageCount}
														pageIndex={reportsNavData.pageIndex}
														setPageIndex={(index: number) => {
															setLoadingReports(true);
															setReportsNavData({
																...reportsNavData,
																pageIndex: index
															});
														}}
													/>
												</Box>
											}
										</Box> :

										<Center m={5}>
											<VStack>
												<Text fontSize="xl" fontWeight={600}>
													No Reports
												</Text>
												<Text fontSize="sm" fontWeight={400} color="gray.500">
													{taskData.taskname} has never been completed
												</Text>
											</VStack>
										</Center>
							}
						</TabPanel>

						<TabPanel>
							{
								loading ? <Center my={20}>
									<Spinner
										thickness='4px'
										speed='0.65s'
										color="brand.500"
										size="xl"
									/>
								</Center> :

									!loading &&
										(taskAttempts.length > 0 ||
											(state?.navData?.isGuided && guidedTaskAttempts.length > 0)) ?
										<Box
											p={{ base: 1, lg: 3 }}
											border="1px solid"
											borderColor="gray.200"
											rounded="lg"
										>
											{
												state?.navData?.isGuided ?
													<>
														{
															requiredObservations?.length > 0 ?
																<GuidedObservationsTable
																	navData={state?.navData}
																	attemptData={distinctObservations}
																	teamTaskId={id}
																	teamId={reportData.teamId}
																	taskId={reportData.taskId}
																	cardStatus={trainingIsValid}
																	startAttempt={startAttempt}
																/> :

																<SlideFade in={!loading} offsetY="20px">
																	<Center m={5} hidden={loading}>
																		<VStack>
																			<Image mb={8} src={noTasks} />

																			<Text fontSize="xl" fontWeight={600}>
																				No Observations Required
																			</Text>
																		</VStack>
																	</Center>
																</SlideFade>
														}
													</> :

													<TaskAttemptsTable
														navData={state?.navData}
														attemptData={taskAttempts}
														teamTaskId={id}
														teamId={reportData.teamId}
														taskId={reportData.taskId}
														cardStatus={trainingIsValid}
													/>
											}
										</Box> :

										<Center m={5}>
											<VStack>
												<Text fontSize="xl" fontWeight={600}>
													No Attempts
												</Text>
												<Text fontSize="sm" fontWeight={400} color="gray.500">
													{`${taskData.taskname} has never been started`}
												</Text>
											</VStack>
										</Center>
							}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
};