import * as React from 'react';
import {
    Box,
    Button,
    CloseButton,
    Icon,
    Square,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import { BsExclamationCircleFill } from 'react-icons/bs';

export const CsrfBanner: React.FC<any> = ({ reauth }) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box
            borderLeft="3px solid"
            borderColor="danger.500"
            bg="red.50"
            mt={{ base: 3, md: 5 }}
            mx={{ base: 3, md: 5 }}
            px={{ base: '4', md: '3' }}
            py={{ base: '4', md: '2.5' }}
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="md"
        >
            <CloseButton
                display={{ md: 'none' }}
                position="absolute"
                right="2"
                top="2"
            />

            <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                spacing={{ base: '3', md: '2' }}
            >
                <Stack
                    spacing="4"
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'start', md: 'center' }}
                >
                    {!isMobile && (
                        <Square
                            size="12"
                            bg="red.50"
                            borderRadius="md"
                        >
                            <Icon
                                as={BsExclamationCircleFill}
                                boxSize="6"
                                color="danger.500"
                            />
                        </Square>
                    )}
                    <Stack spacing="0.5" pe={{ base: '4', md: '0' }}>
                        <Text fontWeight={500}>
                            Error
                        </Text>
                        <Text
                            fontSize="sm"
                            fontWeight={500}
                            color="danger.500"
                        >
                            There has been an authentication issue. Please click the button to fix.
                        </Text>
                    </Stack>
                </Stack>

                <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    spacing={3}
                    align={{ base: 'stretch', md: 'center' }}
                >
                    <Button
                        className='hover-pop'
                        color="danger.500"
                        borderColor="danger.500"
                        bg="transparent"
                        size="sm"
                        variant="outline"
                        width="full"
                        fontWeight={500}
                        _hover={{ bg: "" }}
                        onClick={() => {
                            reauth();
                        }}
                    >
                        Fix
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}