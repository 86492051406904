import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fileTypeFromBlob } from 'file-type';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    HStack,
    Icon,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import {
    BsArrowLeftCircleFill,
    BsArrowRightCircleFill,
    BsCheckCircleFill,
    BsPen,
    BsPencil,
    BsTrash
} from 'react-icons/bs';

import {
    deleteDraft,
    getContacts,
    getDraft,
    postMessageAttachment,
    saveDraft,
    saveNewDraft,
    sendMessage,
    deleteMessageAttachment,
    getTeams
} from '../../features/messages/message.actions';

import MessageDraft from '../../types/MessageDraft';

import { useStep } from '../common/progressSteps/useStep';
import { CreateStepOne } from './CreateStepOne';
import { CreateStepFive } from './CreateStepFive';
import { ObbiAlertDialog } from '../common/AlertDialog';
import _ from 'lodash';

export const steps = [
    { title: 'Task Overview', description: 'Description' },
    { title: 'Step 2', description: 'Description' },
];

export const CreateMessage: React.FC<any> = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { id: existingDraftId } = useParams<string>();
    const [newDraftId, setNewDraftId] = useState("");

    const toast = useToast();
    const navigateTo = useNavigate();
    const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 0 });

    const [loading, setLoading] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    const [contacts, setContacts] = useState<any[]>([]);
    const [selectedContacts, setSelectedContacts] = useState<any[]>([]);

    const [teams, setTeams] = useState<any[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);

    const [priority, setPriority] = useState(false);
    const [acknowledgement, setAcknowledgement] = useState(false);

    const onChangeAcknowledgement = (arg: boolean): void => setAcknowledgement(arg);

    const onChangePriority = (arg: boolean): void => {
        setAcknowledgement(arg);
        setPriority(arg);
    };

    const selectContact = (arg: any): void => {
        if (arg.uduid === "0") return;

        let currentSelection = selectedContacts;
        currentSelection.push(arg);

        let updatedContacts = contacts.filter(contact => arg.uduid !== contact.uduid);

        setContacts(updatedContacts);
        setSelectedContacts(currentSelection);
    };

    const deselectContact = (id: string): void => {
        let currentSelection = selectedContacts;
        const deselectedContact =
            currentSelection.filter(contact => id === contact.uduid);

        currentSelection =
            currentSelection.filter(contact => !deselectedContact.includes(contact));

        let updatedContacts = contacts;
        updatedContacts = updatedContacts.concat(deselectedContact);
        updatedContacts = _.orderBy(updatedContacts, [contact => contact?.udforename?.toLowerCase()], ['asc']);

        setContacts(updatedContacts);
        setSelectedContacts(currentSelection);
    };

    const selectTeam = (arg: any): void => {
        if (arg.teamid === "0") return;

        let currentSelection = selectedTeams;
        currentSelection.push(arg);

        let updatedContacts = teams.filter(team => arg.teamid !== team.teamid);

        setTeams(updatedContacts);
        setSelectedTeams(currentSelection);
    };

    const deselectTeam = (id: string): void => {
        let currentSelection = selectedTeams;
        const deselectedTeam =
            currentSelection.filter(team => id === team.teamid);

        currentSelection =
            currentSelection.filter(team => !deselectedTeam.includes(team));

        let updatedTeams = teams;
        updatedTeams = updatedTeams.concat(deselectedTeam);
        updatedTeams = _.orderBy(updatedTeams, [team => team?.teamname?.toLowerCase()], ['asc']);

        setTeams(updatedTeams);
        setSelectedTeams(currentSelection);
    };

    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const onChangeSubject =
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value.length === 200) {
                setValidSubjectLength(false);
            } else {
                setValidSubjectLength(true);
            }
            setSubject(event.target.value)
        };

    const onChangeMessage =
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value.length === 5000) {
                setValidMessageLength(false);
            } else {
                setValidMessageLength(true);
            }
            setMessage(event.target.value);
        };

    const [attachments, setAttachments] = useState<any[]>([]);

    const deleteAttachment = (attachmentGuid: string) => {
        const id = existingDraftId || newDraftId;

        if (id !== null) {
            deleteMessageAttachment(id, attachmentGuid)
                .then(() => {
                    let updatedAttachments = attachments;
                    updatedAttachments = updatedAttachments.filter(attachment => attachment.path !== attachmentGuid);
                    setAttachments(updatedAttachments);

                    toast({
                        title: "Attachment deleted",
                        description: "",
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    });
                })
                .catch((error) => {
                    toast({
                        title: error.message,
                        description: "",
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                })
        }
    };

    const onDropAction = async (file: any) => {
        const fileExtension = await fileTypeFromBlob(file);

        if (fileExtension?.ext === "docx") {
            toast({
                title: "Unsupported attachment type",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        if (attachments.length > 4) {
            toast({
                title: "You can only upload up to 5 attachments",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        const id = existingDraftId || newDraftId;
        setFileUploadLoading(true);

        if (id !== null) {
            let formData = new FormData();
            formData.append("id", id);
            formData.append("file", file);

            await postMessageAttachment(formData)
                .then((res: any) => {
                    const attachment = {
                        thumbnail: `${process.env.REACT_APP_OBBI_API_URL}/messages/draftAttachment?id=${id}&attachment=${res.path}&thumbnail=true&datauri=false`,
                        fileName: res.fileName,
                        path: res.path,
                        isPdf: res.isPdf
                    };

                    setAttachments(attachments => [...attachments, attachment]);
                })
                .catch((error) => {
                    toast({
                        title: error.response.data.message,
                        description: "",
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                })
                .finally(() => setFileUploadLoading(false));
        }
    };

    const [validTeamRecipients, setValidTeamRecipients] = useState<boolean>(true);
    const [validRecipients, setValidRecipients] = useState<boolean>(true);
    const [validSubject, setValidSubject] = useState<boolean>(true);
    const [validSubjectLength, setValidSubjectLength] = useState<boolean>(true);
    const [validMessage, setValidMessage] = useState<boolean>(true);
    const [validMessageLength, setValidMessageLength] = useState<boolean>(true);

    const canProceed = (): boolean => {
        let canProceed = true;

        if (selectedContacts.length < 1 && selectedTeams.length < 1) {
            setValidTeamRecipients(false);
            setValidRecipients(false);
            canProceed = false;
        } else {
            setValidTeamRecipients(true);
            setValidRecipients(true);
        }

        if (subject.length < 1) {
            setValidSubject(false);
            canProceed = false;
        } else {
            setValidSubject(true);
        }

        if (message.length < 1) {
            setValidMessage(false);
            canProceed = false;
        } else {
            setValidMessage(true);
        }

        if (!canProceed) window.scrollTo({ top: 0, behavior: 'smooth' });

        return canProceed;
    };

    const discardDraft = (): void => {
        const id = existingDraftId || newDraftId;

        if (id !== null) {
            deleteDraft(id.toString())
                .then(() => {
                    toast({
                        title: "Message draft discarded",
                        description: "",
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    });
                })
                .catch(() => {
                    toast({
                        title: "Could not discard message draft",
                        description: "",
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                })
                .finally(() => { navigateTo("/messages"); });
        } else {
            toast({
                title: "Could not discard message draft",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });
        }
    };

    const send = (): void => {
        const id = Number(existingDraftId) || Number(newDraftId);
        const users: any[] = selectedContacts.map(({ uduid }) => uduid);
        const teams: any[] = selectedTeams.map(({ teamid }) => teamid);

        const draft: MessageDraft = {
            id: id,
            subject: subject,
            body: message,
            kind: priority === true ? 1 : 0,
            acknowledgementRequired: acknowledgement,
            users: users,
            locations: [],
            assets: [],
            teams: teams,
            groups: [],
            attachments: []
        };

        if (id) {
            saveDraft(draft)
                .then(() => {
                    sendMessage(id)
                        .then(() => {
                            toast({
                                title: `Message sent!`,
                                description: "",
                                status: 'success',
                                duration: 3000,
                                isClosable: true
                            });

                            navigateTo("/messages");
                        })
                        .catch(() => {
                            toast({
                                title: "Could not send message",
                                description: "",
                                status: "error",
                                duration: 6000,
                                isClosable: true
                            });
                        })
                })
                .catch(() => {
                    toast({
                        title: "Could not send message",
                        description: "",
                        status: "error",
                        duration: 6000,
                        isClosable: true
                    });
                })
        } else {
            toast({
                title: "Could not send message",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });
        }
    };

    const save = (): void => {
        const id = Number(existingDraftId) || Number(newDraftId);
        const users: any[] = selectedContacts.map((u) => u.uduid);;
        const teams: any[] = selectedTeams.map((t) => t.teamid);

        const draft: MessageDraft = {
            id: id,
            subject: subject,
            body: message,
            kind: priority === true ? 1 : 0,
            acknowledgementRequired: acknowledgement,
            users: users,
            locations: [],
            assets: [],
            teams: teams,
            groups: [],
            attachments: []
        };

        saveDraft(draft)
            .then(() => {
                toast({
                    title: `Draft saved!`,
                    description: "",
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                });
            })
            .catch(() => {
                toast({
                    title: "Could not save draft",
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true
                });
            })
            .finally(() => navigateTo("/messages"))
    };

    useEffect(() => {
        const getCurrentDraft = async () => {
            if (existingDraftId)
                return await getDraft(parseInt(existingDraftId));
        };

        async function initialise() {
            try {
                setLoading(true);
                setSubject("");
                setMessage("");

                let contacts: any = await getContacts();
                let teams: any = await getTeams();

                const draft: any = await getCurrentDraft();

                // If a current message draft exists

                if (draft) {
                    const acknowledgement = draft.msgacknowledgementrequired === "1" ? true : false;
                    const priority = draft.msgkind === "1" ? true : false;

                    setAcknowledgement(acknowledgement);
                    setPriority(priority);

                    for (const user of draft.users) {
                        contacts = contacts.filter((c: any) => c.uduid !== user.uduid);
                    }

                    for (const team of draft.teams) {
                        teams = teams.filter((t: any) => t.teamid !== team.teamid);
                    }

                    setSelectedContacts(draft.users);
                    setSelectedTeams(draft.teams);

                    setSubject(draft.msgsubject || "");
                    setMessage(draft.msgbody || "");

                    const attachmentThumbnails: any[] = [];

                    for (let attachment of draft.attachments) {
                        attachmentThumbnails.push({
                            thumbnail: `${process.env.REACT_APP_OBBI_API_URL}/messages/draftAttachment?id=${draft.msgid}&attachment=${attachment.matpath}&thumbnail=true&datauri=false`,
                            fileName: attachment.matfilename,
                            path: attachment.matpath,
                            isPdf: attachment.ispdf || attachment.isdoc
                        });
                    }

                    setAttachments(attachmentThumbnails);
                } else {
                    // Creating a new draft entity to save DropZone data against

                    saveNewDraft({
                        subject: "",
                        body: "",
                        kind: priority ? 1 : 0,
                        acknowledgementRequired: acknowledgement,
                        users: [],
                        locations: [],
                        assets: [],
                        teams: [],
                        groups: [],
                        attachments: []
                    })
                        .then((res: any) => {
                            setNewDraftId(res.msgid);
                        })
                        .catch(() => {
                            toast({
                                title: "Could not create message",
                                description: "",
                                status: "error",
                                duration: 6000,
                                isClosable: true
                            });
                        })
                }

                setContacts(contacts);
                setTeams(teams);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        initialise();
    }, [existingDraftId]);

    return (
        <Box
            m={{ base: 0, md: 5 }}
            p={5}
            bg="white"
            h="100%"
            minH={{ base: "100vh", md: "unset" }}
            boxShadow={'lg'}
            rounded={'lg'}
            textAlign={'center'}
        >
            <ObbiAlertDialog
                isOpen={isOpen}
                onConfirm={discardDraft}
                onClose={onClose}
                title="Discard Draft?"
                message="Are you sure you want to discard this message draft?"
            />

            <HStack
                gap={4}
                mb={4}
                mx={{ base: 0, md: 5 }}
            >
                <Icon
                    as={BsPencil}
                    verticalAlign="middle"
                    fontSize="4xl"
                    color="brand.500"
                />

                <Text
                    fontWeight={{ base: 600, lg: 700 }}
                    fontSize={{ base: "lg", lg: "2xl" }}
                    color="gray.700"
                >
                    {currentStep === 1 ? "Review and send" : "Create Message"}
                </Text>
            </HStack>

            <Divider w="unset" mx={-5} />

            <Box>
                {
                    {
                        0: <CreateStepOne
                            id={newDraftId || existingDraftId}
                            onChangePriority={onChangePriority}
                            onChangeAcknowledgement={onChangeAcknowledgement}
                            contacts={contacts}
                            selectedContacts={selectedContacts}
                            selectContact={selectContact}
                            deselectContact={deselectContact}
                            validRecipients={validRecipients}
                            teams={teams}
                            selectedTeams={selectedTeams}
                            selectTeam={selectTeam}
                            deselectTeam={deselectTeam}
                            validTeamRecipients={validTeamRecipients}
                            priority={priority}
                            acknowledgement={acknowledgement}
                            onChangeSubject={onChangeSubject}
                            onChangeMessage={onChangeMessage}
                            subject={subject}
                            validSubject={validSubject}
                            validSubjectLength={validSubjectLength}
                            message={message}
                            validMessage={validMessage}
                            validMessageLength={validMessageLength}
                            onDropAction={onDropAction}
                            deleteAttachment={deleteAttachment}
                            attachments={attachments}
                            loading={loading}
                            fileUploadLoading={fileUploadLoading}
                        />,
                        1: <CreateStepFive
                            id={newDraftId || existingDraftId}
                            selectedContacts={selectedContacts}
                            selectedTeams={selectedTeams}
                            priority={priority}
                            subject={subject}
                            message={message}
                            attachments={attachments}
                        />
                    }[currentStep]
                }
            </Box>

            {
                !loading && <>
                    <Divider w="unset" mx={-5} />

                    <Box py={5} pb={10}>
                        <Stack float={{ md: "left" }}>
                            <Button
                                size="md"
                                color="white"
                                bg="green.500"
                                variant="solid"
                                _hover={{
                                    bg: '#248451',
                                }}
                                rightIcon={
                                    currentStep === 1 ? <BsCheckCircleFill /> :
                                        <BsArrowRightCircleFill />
                                }
                                onClick={() => {
                                    if (currentStep === 1) {
                                        send();
                                    } else {
                                        if (canProceed())
                                            setStep(currentStep + 1);
                                    }
                                }}
                            >
                                {
                                    {
                                        0: "Review and send",
                                        1: "Send"
                                    }[currentStep]
                                }
                            </Button>
                        </Stack>

                        <HStack
                            mt={{ base: 3, md: 2 }}
                            spacing={2}
                            float={{ base: "left", md: "right" }}
                        >
                            <ButtonGroup gap={1}>
                                {
                                    currentStep > 0 && <Button
                                        leftIcon={<BsArrowLeftCircleFill />}
                                        size="sm"
                                        variant="outline"
                                        color="gray.500"
                                        borderColor="gray.500"
                                        fontWeight={600}
                                        onClick={() => {
                                            setStep(currentStep - 1);
                                        }}
                                    >
                                        <Text>Back</Text>
                                    </Button>
                                }

                                <Button
                                    leftIcon={<BsPen />}
                                    size="sm"
                                    variant="outline"
                                    color="gray.500"
                                    borderColor="gray.500"
                                    fontWeight={600}
                                    onClick={() => save()}
                                >
                                    <Text display={{ base: "none", md: "flex" }}>Save Draft</Text>
                                    <Text display={{ base: "flex", md: "none" }}>Save</Text>
                                </Button>

                                <Button
                                    leftIcon={<BsTrash />}
                                    size="sm"
                                    variant="outline"
                                    color="red.500"
                                    borderColor="red.500"
                                    fontWeight={600}
                                    onClick={() => onOpen()}
                                >
                                    <Text display={{ base: "none", md: "flex" }}>Discard Draft</Text>
                                    <Text display={{ base: "flex", md: "none" }}>Discard</Text>
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </>
            }
        </Box>
    );
}