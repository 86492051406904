import {
    Fragment,
    useCallback,
    useEffect,
    useState
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { Grid, GridItem, Skeleton } from "@chakra-ui/react";
import 'react-image-lightbox/style.css';

import { getMessageTracking } from "../../features/messages/message.actions";

import { InsightActionCard } from "../common/InsightActionCard";
import { MessageTrackingTable } from "./MessageTrackingTable";

import requiresAttention from "../../assets/images/requiresAttention.svg";
import organise from "../../assets/images/viewing-files.svg";

export const MessageTracking: React.FC<any> = ({ message }) => {
    const { id } = useParams();
    const { state }: any = useLocation();

    const [currentItems, setCurrentItems] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);

    const [filter, setFilter] = useState<string>("all");

    const [firstTimeLoading, setFirstTimeLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [unreadCount, setUnreadCount] = useState(0);
    const [unacknowlegedCount, setUnacknowlegedCount] = useState(0);

    const [unacknowledgedActive, setUnacknowledgedActive] = useState(state !== null ? state.unreadActiveState : false);
    const [unreadActive, setUnreadActive] = useState(false);

    const getTracking = useCallback((index, filter) => {
        setLoading(true);
        const pageIndex = (index + 1).toString();

        getMessageTracking(
            Number(id),
            filter,
            pageIndex
        )
            .then((res: any) => {
                const formattedCurrentItems = res.userList.map((user: any) => {
                    let ack = "";
                    if (message.msgacknowledgementrequired === "1") {
                        if (user.muacknowledged === "1") {
                            ack = "acknowledged";
                        } else {
                            ack = "not";
                        }
                    }

                    return {
                        name: `${user.udforename} ${user.udsurname}`,
                        acknowledgementRequired: message.msgacknowledgementrequired,
                        acknowledged: { status: ack, date: user.mudateacknowledged },
                        unread: { status: user.muunread, date: user.mudateread }
                    }
                });

                const acknowledgementCount = message.msgacknowledgementrequired === "1" ? res.notAcknowledged : 0;

                setUnreadCount(res.unread);
                setUnacknowlegedCount(acknowledgementCount);

                setCurrentItems(formattedCurrentItems);
                setPageCount(res.pageCount);
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            });
    }, [id, message.msgacknowledgementrequired]);

    useEffect(() => {
        getTracking(pageIndex, filter);
    }, [getTracking, filter, pageIndex]);

    return (
        <Fragment>
            <Grid
                templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)" }}
                gap={5}
                mb={5}
            >
                <GridItem>
                    <Skeleton isLoaded={!firstTimeLoading} rounded="lg">
                        <InsightActionCard
                            title="Unread"
                            count={unreadCount}
                            image={organise}
                            color="red.500"
                            active={unreadActive}
                            onClickAction={() => {
                                if (unacknowledgedActive) {
                                    setUnacknowledgedActive(!unacknowledgedActive);
                                    setUnreadActive(true);
                                    setFilter("new");
                                    setPageIndex(0);
                                } else if (unreadActive) {
                                    setUnreadActive(!unreadActive);
                                    setFilter("all");
                                    setPageIndex(0);
                                } else {
                                    setUnreadActive(true);
                                    setFilter("new");
                                    setPageIndex(0);
                                }
                            }}
                        />
                    </Skeleton>
                </GridItem>

                <GridItem>
                    <Skeleton isLoaded={!firstTimeLoading} rounded="lg">
                        <InsightActionCard
                            title="Unacknowledged"
                            count={unacknowlegedCount}
                            image={requiresAttention}
                            color="warning.500"
                            active={unacknowledgedActive}
                            onClickAction={() => {
                                if (unreadActive) {
                                    setUnreadActive(!unreadActive);
                                    setUnacknowledgedActive(true);
                                    setFilter("nack");
                                    setPageIndex(0);
                                } else if (unacknowledgedActive) {
                                    setUnacknowledgedActive(!unacknowledgedActive);
                                    setFilter("all");
                                    setPageIndex(0);
                                } else {
                                    setUnacknowledgedActive(true);
                                    setFilter("nack");
                                    setPageIndex(0);
                                }
                            }}
                        />
                    </Skeleton>
                </GridItem>
            </Grid>

            <MessageTrackingTable
                loading={loading}
                message={message}
                currentItems={currentItems}
                pageCount={pageCount}
                index={pageIndex}
                setPageIndex={setPageIndex}
            />
        </Fragment>
    )
}