import {
	Box,
	VStack,
	SimpleGrid,
	Text,
	Icon,
	Image,
	Checkbox,
	Input,
	Textarea,
	CircularProgress,
	Center,
	HStack,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useToast,
	FormControl,
	FormLabel,
	Button,
	Spacer,
	Spinner,
	Badge,
	Tag,
	TagLabel,
	TagCloseButton
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
	BsClockHistory,
	BsExclamationCircleFill,
	BsHandThumbsUp,
	BsImages
} from "react-icons/bs";

import { Document, Page, pdfjs } from "react-pdf";
import { getDraftAttachment } from "../../features/messages/message.actions";

import { DropZone } from "../common/DropZone";
import { RadioCardGroup, RadioCard } from "../common/radioCardGroup/RadioCardGroup";

import { TeamMessageCombobox } from "./TeamMessageCombobox";
import { UserMessageCombobox } from "./UserMessageCombobox";

export const CreateStepOne: React.FC<any> = ({
	id,
	onChangePriority,
	onChangeAcknowledgement,
	contacts,
	selectedContacts,
	selectContact,
	deselectContact,
	validRecipients,
	teams,
	selectedTeams,
	selectTeam,
	deselectTeam,
	validTeamRecipients,
	priority,
	acknowledgement,
	onChangeSubject,
	onChangeMessage,
	subject,
	validSubject,
	validSubjectLength,
	message,
	validMessage,
	validMessageLength,
	onDropAction,
	deleteAttachment,
	attachments,
	loading,
	fileUploadLoading
}) => {
	const toast = useToast();
	const [isOpen, setIsOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState("");

	// PDF pages
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const [currentPdf, setCurrentPdf] = useState({
		id: 0,
		uri: "",
		fileName: ""
	});
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const options = [
		{
			"title": "Normal",
			"description": "This is just for general information and is not urgent.",
			"icon": BsHandThumbsUp,
			"iconColor": "brand.500"
		},
		{
			"title": "Critical",
			"description": "This is critical information that might impact on safety.",
			"icon": BsExclamationCircleFill,
			"iconColor": "red.500"
		}
	];

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentPdf.fileName}</Text>

						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>

						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>

						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentPdf.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	};

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	const renderImage = () => {
		return <Modal
			size="2xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader />

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>
				<ModalBody>
					<Image
						src={currentImage}
						m="5px auto 15px auto"
						p={0}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			{
				loading ?
					<Center py="30vh">
						<CircularProgress isIndeterminate color='brand.500' />
					</Center> :

					<Box my={5}>
						<VStack
							display="flex"
							alignItems="baseline"
							spacing={5}
						>
							<Box w="full">
								<Text
									fontWeight={500}
									fontSize="sm"
									color="gray.500"
									textAlign="start"
									mb={2}
								>
									Send to Teams
								</Text>

								<TeamMessageCombobox
									teams={teams}
									update={selectTeam}
									selectedTeam={{}}
								/>

								<Text
									fontWeight={500}
									fontSize="sm"
									color="gray.500"
									textAlign="start"
									mt={5}
									mb={2}
								>
									Send to Individuals
								</Text>

								<UserMessageCombobox
									users={contacts}
									update={selectContact}
									selectedUser={{}}
								/>
							</Box>

							<Box w="full">
								<HStack>
									<Text
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
										textAlign="start"
									>
										Recipients
									</Text>

									<Badge
										alignSelf="baseline"
										size="sm"
									>
										{(selectedTeams.length + selectedContacts.length).toString()}
									</Badge>
								</HStack>

								{
									(selectedTeams.length > 0 || selectedContacts.length > 0) && <Box
										textAlign="left"
										border="1px solid"
										borderColor="gray.200"
										rounded="lg"
										mt={2}
										p={3}
									>
										{
											selectedTeams.map((team: any, i: number) => (
												<Tag
													key={`${team.teamid}_${i}`}
													size="md"
													mr={2}
													mb={2}
													borderRadius='lg'
													variant='solid'
													colorScheme='green'
												>
													<TagLabel>{team.teamname}</TagLabel>
													<TagCloseButton
														onClick={() => {
															deselectTeam(team.teamid)
														}}
													/>
												</Tag>
											))
										}

										{
											selectedContacts.map((user: any, i: number) => (
												<Tag
													key={`${user.uduid}_${i}`}
													size="md"
													mr={2}
													mb={2}
													borderRadius='lg'
													variant='solid'
													colorScheme='green'
												>
													<TagLabel>{`${user.udforename} ${user.udsurname}`}</TagLabel>
													<TagCloseButton
														onClick={() => {
															deselectContact(user.uduid)
														}}
													/>
												</Tag>
											))
										}
									</Box>
								}

								{
									!validRecipients && !validTeamRecipients && <Text
										mt={1}
										fontWeight={500}
										fontSize="sm"
										color="danger.500"
										textAlign="start"
									>
										You must select at least one team or individual recipient.
									</Text>
								}
							</Box>

							<SimpleGrid
								w="full"
								spacing={5}
								columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
							>
								<Box w="full">
									<FormControl colorScheme="green" isRequired>
										<FormLabel
											fontWeight={500}
											fontSize="sm"
											color="gray.500"
										>
											Subject
										</FormLabel>

										<Input
											_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
											onChange={onChangeSubject}
											value={subject}
											maxLength={200}
											rounded="lg"
										/>
									</FormControl>

									{
										!validSubject && <Text
											fontWeight={500}
											fontSize="sm"
											color="danger.500"
											textAlign="start"
											mt={1}
										>
											You must provide a message subject.
										</Text>
									}

									{
										!validSubjectLength && <Text
											fontWeight={500}
											fontSize="sm"
											color="danger.500"
											textAlign="start"
											mt={1}
										>
											Subject length cannot exceed 200 characters.
										</Text>
									}

									<FormControl colorScheme="green" isRequired mt={5}>
										<FormLabel
											fontWeight={500}
											fontSize="sm"
											color="gray.500"
										>
											Message
										</FormLabel>

										<Textarea
											_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
											size="sm"
											rounded="lg"
											onChange={onChangeMessage}
											rows={5}
											value={message}
											maxLength={5000}
										/>
									</FormControl>

									{
										!validMessage && <Text
											fontWeight={500}
											fontSize="sm"
											color="danger.500"
											textAlign="start"
											mt={1}
										>
											You must provide a message.
										</Text>
									}

									{
										!validMessageLength && <Text
											fontWeight={500}
											fontSize="sm"
											color="danger.500"
											textAlign="start"
											mt={1}
										>
											Message length cannot exceed 5000 characters.
										</Text>
									}

									<Box w="full" textAlign="start">
										<Text
											fontWeight={500}
											fontSize="sm"
											color="gray.500"
											mt={5}
										>
											Acknowledgement Required
										</Text>
										<Checkbox
											borderColor="gray.500"
											onChange={() => onChangeAcknowledgement(!acknowledgement)}
											isChecked={acknowledgement}
										>
											<Text
												fontWeight={500}
												fontSize="xs"
												color="gray.400"
												mt={2}
												ml={2}
											>
												This will allow you to keep track of who has seen and acknowledged this message for any future audit purposes.
											</Text>
										</Checkbox>
									</Box>
								</Box>

								<Box w="full">
									<Text
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
										textAlign="start"
									>
										Priority
									</Text>

									<RadioCardGroup
										w="full"
										flexDirection={{ base: "column", md: "row" }}
										alignItems="center"
										gap={{ base: 2, md: 5 }}
										onChange={() => onChangePriority(!priority)}
										value={priority === true ? "Critical" : "Normal"}
									>
										{
											options.map((option) => (
												<RadioCard
													key={option.title}
													value={option.title}
													showCheck={true}
													className="hover-pop"
												>
													<VStack alignItems='start'>
														<HStack>
															<Icon
																as={option.icon}
																color={option.iconColor}
															/>
															<Text
																color="gray.600"
																fontWeight="500"
																fontSize="sm"
																display='flex'
																alignItems='baseline'
																textAlign="left"
															>
																{option.title}
															</Text>
														</HStack>

														<Text color="gray.500" fontSize="xs" textAlign="left">
															{option.description}
														</Text>
													</VStack>
												</RadioCard>
											))
										}
									</RadioCardGroup>

									<Box w="full" textAlign="start">
										<HStack mt={5} mb={2}>
											<Icon
												as={BsImages}
												fontSize="xs"
												color="gray.500"
											/>
											<Text
												fontWeight={500}
												fontSize="sm"
												color="gray.500"
											>
												Upload Attachments
											</Text>
										</HStack>

										<DropZone
											onDropAction={onDropAction}
											fileUploadLoading={fileUploadLoading}
											py={6}
											w="full"
										/>

										<Grid
											templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(5, 5fr)' }}
											alignItems="start"
											gap={{ base: 2, lg: 5 }}
											mt={3}
										>
											{
												attachments.map((a: any) => {
													return (
														<Box minW="170px">
															<Image
																className="hover-pop"
																cursor="pointer"
																src={a.thumbnail}
																fallbackSrc='https://via.placeholder.com/160'
																alt={a.fileName}
																boxSize='160px'
																boxShadow="xs"
																objectFit="cover"
																border="1px solid"
																borderColor="gray.200"
																rounded="lg"
																onClick={() => {
																	getDraftAttachment(id, a.path, 0, 1)
																		.then((res: any) => {
																			if (a.isPdf) {
																				setCurrentPdf({
																					id: Number(id),
																					fileName: a.fileName,
																					uri: res?.datauri
																				});
																				setPdfIsOpen(true);
																			} else {
																				setCurrentImage(res.datauri);
																				setIsOpen(true);
																			}
																		})
																		.catch((error: any) => {
																			console.log(error);

																			toast({
																				title: "Error",
																				description: "Cannot download this attachment",
																				status: 'error',
																				duration: 6000,
																				isClosable: true,
																			});
																		});
																}}
															/>

															<Button
																className="hover-pop"
																variant="link"
																size="xs"
																color="gray.500"
																_hover={{ color: "" }}
																onClick={() => {
																	deleteAttachment(a.path);
																}}
															>
																<Text textDecoration="underline">Delete</Text>
															</Button>
														</Box>
													)
												})
											}
										</Grid>
									</Box>
								</Box>
							</SimpleGrid>
						</VStack>
					</Box >
			}

			{renderImage()}
			{renderPdf()}
		</Box >
	)
};