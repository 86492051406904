import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	AspectRatio,
	Badge,
	Box,
	Button,
	Center,
	Divider,
	FormControl,
	Grid,
	HStack,
	Icon,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Skeleton,
	SlideFade,
	Spacer,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	useToast,
	VStack,
} from "@chakra-ui/react";
import {
	BsArrowLeftCircleFill,
	BsCheckCircleFill,
	BsDashCircleFill,
	BsExclamationCircleFill,
	BsExclamationDiamondFill,
	BsFileText,
	BsFileTextFill,
	BsFolder,
	BsHandThumbsUp,
	BsXCircleFill
} from "react-icons/bs";
import { SmallFilterCard } from "../common/SmallFilterCard";

import { InsightActionCard } from "../common/InsightActionCard";

import incident from "../../assets/images/incident.svg";
import { downloadDocument, getDocuments, acknowledgeDocument } from "../../features/documents/documents.actions";
import { Pager } from "../common/Pager";

import { isEmpty, isNull, isUndefined, uniq, uniqBy } from "lodash";

import certificateExpiring from "../../assets/images/certificateExpiring.svg";
import organise from "../../assets/images/viewing-files.svg";

import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { TeamCombobox } from "../common/TeamCombobox";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import { localDate } from "../../helpers/DayJsHelper";

export const Documents = () => {
	const { state }: any = useLocation();
	const user = useSelector((state: RootState) => state.authReducer.user);
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const navigateTo = useNavigate();
	const toast = useToast();

	const [isOpen, setIsOpen] = useState(false);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [acknowledging, setAcknowledging] = useState(false);

	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	const [currentDocument, setCurrentDocument] = useState({
		id: 0,
		isPdf: false,
		isAck: false,
		iHaveAck: false,
		iNeedToAck: false,
		myAckDate: "",
		uri: "",
		fileName: ""
	});

	const [categories, setCategories] = useState([]);
	const [teams, setTeams] = useState<any>([]);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [team, setTeam] = useState(0);
	const [category, setCategory] = useState("");
	const [search, setSearch] = useState("");

	const [docsToAckCount, setDocsToAckCount] = useState(0);
	const [expiredCertCount, setExpiredCertCount] = useState(0);
	const [expiringCertCount, setExpiringCertCount] = useState(0);

	// PDF pages
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [filterSelected, setFilterSelected] = useState(false);

	useEffect(() => {
		const filterInEffect =
			(filter !== "" && filter !== "all") ||
			(team !== 0 && team !== undefined) ||
			search !== "" ||
			(category !== "" && category !== undefined);

		setFilterSelected(filterInEffect);
	}, [filter, team, category, search]);

	function setClearAllFiltersPressed() {
		setFilter("all");
		setPageIndex(0);
		setSearch("");
		setCategory("");
		setTeam(0);
	}

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent minH="100vh" my={0}>
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} pr={10}>
						<Text wordBreak="break-word" fontSize={{ base: "sm", sm: "md" }}>
							{currentDocument.fileName}
						</Text>

						<Spacer />

						<HStack hidden={numPages < 2}>
							<Button
								bg="white"
								color="brand.500"
								float="left"
								size="sm"
								disabled={pageNumber === 1}
								onClick={() => setPageNumber(pageNumber - 1)}
							>
								Back
							</Button>
							<Text fontSize="md">
								{pageNumber} of {numPages}
							</Text>
							<Button
								bg="white"
								color="brand.500"
								float="right"
								size="sm"
								disabled={pageNumber === numPages}
								onClick={() => setPageNumber(pageNumber + 1)}
							>
								Next
							</Button>
						</HStack>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<Box
					w="full"
					py={3}
					px={5}
					bg="gray.50"
					boxShadow="sm"
					hidden={!currentDocument.iHaveAck && !currentDocument.isAck}
				>
					<Stack direction={{ base: "column", md: "row" }}>
						{
							currentDocument.iHaveAck ?
								<HStack>
									<Icon as={BsCheckCircleFill} color="brand.500" />
									<Text color="gray.600" fontWeight={500}>You acknowledged this document on {localDate(currentDocument.myAckDate)}</Text>
								</HStack>
								: <Text color="gray.600" fontWeight={500} alignSelf="center">Please read fully and acknowledge that you have understood this document.</Text>
						}

						<Spacer />

						<Button
							w={{ base: "full", sm: "unset" }}
							size="sm"
							bg="brand.500"
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
							onClick={() => acknowledge(currentDocument.id)}
							disabled={acknowledging}
							hidden={currentDocument.iHaveAck}
						>
							<HStack>
								{
									acknowledging ? <Spinner size="sm" color="white" /> :
										<Icon as={BsHandThumbsUp} />
								}
								<Text>Acknowledge</Text>
							</HStack>
						</Button>
					</Stack>
				</Box>

				<ModalBody>
					<Document
						file={currentDocument.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
							className="testing"
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderImage = () => {
		return <Modal
			id="ImageModal"
			size="4xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false) }}
		>
			<ModalOverlay />

			<ModalContent my={0} bg="white">
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={3} paddingRight={10}>
						<Text wordBreak="break-word" fontSize={{ base: "sm", sm: "md" }}>
							{currentDocument.fileName}
						</Text>

						<Spacer />

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<Box
					w="full"
					py={3}
					px={5}
					bg="gray.50"
					boxShadow="sm"
					hidden={!currentDocument.iHaveAck && !currentDocument.isAck}
				>
					<Stack direction={{ base: "column", md: "row" }}>
						{
							currentDocument.iHaveAck ?
								<HStack>
									<Icon as={BsCheckCircleFill} color="brand.500" />
									<Text color="gray.600" fontWeight={500}>You acknowledged this document on {localDate(currentDocument.myAckDate)}</Text>
								</HStack>
								: <Text color="gray.600" fontWeight={500} alignSelf="center">Please read fully and acknowledge that you have understood this document.</Text>
						}

						<Spacer />

						<Button
							w={{ base: "full", sm: "unset" }}
							size="sm"
							bg="brand.500"
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
							onClick={() => acknowledge(currentDocument.id)}
							disabled={acknowledging}
							hidden={currentDocument.iHaveAck}
						>
							<HStack>
								{acknowledging ? <Spinner size="sm" color="white" /> :
									<Icon as={BsHandThumbsUp} />
								}
								<Text>Acknowledge</Text>
							</HStack>
						</Button>
					</Stack>
				</Box>

				<ModalBody m={5}>
					<Image src={currentDocument.uri} m="0 auto" p={0} />
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const fetchDocument = async (id: number, isPdf: boolean) => {
		await downloadDocument(id, isPdf)
			.then((res: any) => {
				setCurrentDocument({
					id: id,
					isPdf: res.doc.ispdf || res.doc.isdoc,
					isAck: res.doc.ineedtoack,
					iHaveAck: res.doc.ihaveack,
					myAckDate: res.doc.myackdate,
					iNeedToAck: res.doc.ineedtoack,
					uri: res.datauri,
					fileName: res.doc.docfilename
				});
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const acknowledge = async (id: number) => {
		setAcknowledging(true);

		await acknowledgeDocument(id)
			.then((res: any) => {
				setCurrentDocument(Object.assign(
					currentDocument,
					{
						isAck: res.doc.ineedtoack,
						iHaveAck: res.doc.ihaveack,
						iNeedToAck: res.doc.ineedtoack,
						myAckDate: res.doc.myackdate
					}
				));

				toast({
					title: "Document Acknowledged",
					description: "",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			})
			.catch((error: any) => {
				console.log(error);
				toast({
					title: "Document could not be Acknowledged",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true,
				});
			})
			.finally(() => {
				setAcknowledging(false);

				fetchData({
					filter,
					team,
					category,
					search,
					pageIndex
				});
			});
	};

	const fetchData = useCallback(async ({ filter, team, category, search, pageIndex }) => {
		setLoading(true);
		pageIndex = (pageIndex + 1).toString();

		await getDocuments("", filter, team, category, search, pageIndex)
			.then(async (res: any) => {
				let formattedCurrentItems: any = [];

				for (let i = 0; i < res.documents.length; i++) {
					let thumbnail = null;

					if (res.documents[i].hasthumbnail) {
						thumbnail =
							`${process.env.REACT_APP_OBBI_API_URL}/documents/thumbnail?id=${res.documents[i].docid}&datauri=false&thumbnail=true`;
					}

					formattedCurrentItems.push({
						docid: res.documents[i].docid,
						docfilename: res.documents[i].docfilename,
						docexpirydate: res.documents[i].docexpirydate,
						expiring: res.documents[i].expiring,
						expired: res.documents[i].expired,
						ineedtoack: res.documents[i].ineedtoack,
						ihaveack: res.documents[i].ihaveack,
						myackdate: res.documents[i].myackdate,
						ackdatehuman: res.documents[i].ackdatehuman,
						isimage: res.documents[i].isimage,
						ispdf: res.documents[i].ispdf,
						isdoc: res.documents[i].isdoc,
						thumbnail
					});
				}

				setDocsToAckCount(res.needAcknowledgedCount);
				setExpiredCertCount(res.expiredCount);
				setExpiringCertCount(res.expiringSoonCount);

				setCategories(res.availableCategories);

				const concattedTeams = uniqBy(res.teamMemberships.concat(res.teamsManaged), "teamid");
				setTeams(concattedTeams);

				setCurrentItems(formattedCurrentItems);
				setPageCount(res.pageCount);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
				setFirstTimeLoading(false);
			});
	}, []);

	useEffect(() => {
		if (state?.from === "notification") {
			fetchDocument(state?.id, state?.isPdf)
				.then(() => {
					if (state?.isPdf || currentDocument.isPdf) {
						setPdfIsOpen(true);
					} else {
						setIsOpen(true);
					}
				});
		};

		fetchData({
			filter,
			team,
			category,
			search,
			pageIndex
		})
	}, [category, fetchData, filter, pageIndex, search, team]);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			{
				!isEmpty(currentDocument.uri) && pdfIsOpen && renderPdf()
			}

			{
				!isEmpty(currentDocument.uri) && isOpen && renderImage()
			}

			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				mb={2}
				hidden={isUndefined(state?.from)}
				size="sm"
				variant="outline"
				color="gray.600"
				fontWeight={600}
				boxShadow="xs"
				onClick={() => {
					navigateTo(-1);
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircleFill} />
					<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
				</HStack>
			</Button>

			<Box
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
				minH={{ base: "100vh", md: "auto" }}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={"lg"}
				rounded={"lg"}
			>
				<Button
					display={{ base: "unset", md: "none" }}
					hidden={isUndefined(state?.from)}
					size="sm"
					m={5}
					textAlign="start"
					color="gray.600"
					variant="link"
					fontWeight={600}
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircleFill} />
						<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
					</HStack>
				</Button>

				<HStack mx={5} mb={5} gap={4}>
					<Icon
						as={BsFileText}
						verticalAlign="middle"
						fontSize="4xl"
						color="green.500"
					/>
					<Text
						fontWeight={{ base: 600, lg: 700 }}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						Documents
					</Text>

					<Spacer />

					{
						filterSelected && <Button
							variant="ghost"
							size="xs"
							onClick={setClearAllFiltersPressed}
						>
							<HStack>
								<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
								<Text>Clear All Filters</Text>
							</HStack>
						</Button>
					}

					{
						user?.permissions?.Documents?.read && <>
							<Button
								display={{ base: "none", md: "flex" }}
								bg="green.500"
								_hover={{
									bg: "#248451"
								}}
								mb={-2}
								size="sm"
								color="white"
								float="right"
								onClick={() => {
									handleClickThrough("ManageDocuments");
								}}
							>
								<Icon as={BsFolder} mr={2} /> Manage Documents
							</Button>
						</>
					}
				</HStack>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				{
					user?.permissions?.Documents?.read && <HStack
						mx={5}
						my={8}
						display={{ base: "flex", md: "none" }}
					>
						<Button
							bg="green.500"
							_hover={{
								bg: "#248451"
							}}
							w="full"
							size="md"
							color="white"
							float="right"
							onClick={() => {
								handleClickThrough("ManageDocuments");
							}}
						>
							<Icon as={BsFolder} mr={2} /> Manage Documents
						</Button>
					</HStack>
				}

				<Box m={4}>
					<Grid
						templateColumns={{
							base: "repeat(3, 1fr)",
							xl: "repeat(4, 1fr)",
						}}
						gap={5}
						my={5}
					>
						<InsightActionCard
							title="Need Acknowledged"
							count={docsToAckCount || 0}
							image={organise}
							color="red.500"
							active={filter === "needack"}
							onClickAction={() => {
								if (filter === "needack") {
									setFilter("all");
									setPageIndex(0);
								} else {
									setFilter("needack");
									setPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>

						<InsightActionCard
							title="Expired Documents"
							count={expiredCertCount || 0}
							image={incident}
							color="danger.500"
							active={filter === "expired"}
							onClickAction={() => {
								if (filter === "expired") {
									setFilter("all");
									setPageIndex(0);
								} else {
									setFilter("expired");
									setPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>

						<InsightActionCard
							title="Expiring Documents"
							count={expiringCertCount || 0}
							image={certificateExpiring}
							color="warning.500"
							active={filter === "expiringsoon"}
							onClickAction={() => {
								if (filter === "expiringsoon") {
									setFilter("all");
									setPageIndex(0);
								} else {
									setFilter("expiringsoon");
									setPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>
					</Grid>

					{
						!isMobile && <Divider w="unset" mx={{ base: -5, md: -10 }} />
					}

					<SlideFade in={!firstTimeLoading} offsetY="20px">
						{
							categories.length > 0 && categories.length < 7 &&
							<Box
								p={1.5}
								mt={5}
								bg="gray.50"
								textAlign="left"
								rounded="lg"
								border="1px solid"
								borderColor="gray.100"
							>
								<Text
									fontWeight={500}
									color="gray.500"
									fontSize="sm"
									ml={1}
									mb={2}
								>
									Document Categories
								</Text>
								<SlideFade in={!firstTimeLoading}>
									{
										categories.map((c: any, i: Number) => {
											return (
												<SmallFilterCard
													key={i}
													title={c.doccategory}
													onClickAction={() => {
														c.doccategory !== category
															? setCategory(c.doccategory)
															: setCategory("");
													}}
													selectedCard={category}
													icon={BsFolder}
													iconColor="brand.500"
												/>
											);
										})
									}
								</SlideFade>
							</Box>
						}
					</SlideFade>

					<SlideFade in={!firstTimeLoading} offsetY="20px">
						<Stack
							direction={{ base: "column", lg: "row" }}
							mt={
								categories.length > 0 && categories.length <= 7 ? 2 :
									categories.length > 7 ? 5 : 5
							}
						>
							{
								categories.length > 7 ? (
									<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
										<Select
											_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
											color="gray.600"
											fontSize="sm"
											defaultValue={
												state !== null && state.teamId ? Number(state.teamId) : ""
											}
											onChange={(e) => setCategory(e.target.value)}
											placeholder="Search by Category"
										>
											{categories.map((c: any, i) => {
												return <option key={i} value={c.doccategory}>{c.doccategory}</option>;
											})}
										</Select>
									</FormControl>
								) : (
									<FormControl w={{ base: "full", lg: "50%" }}>
										<Input placeholder="Search Documents" fontSize="sm" value={search} onChange={(e: any) => { setSearch(e.target.value); }} />
									</FormControl>
								)
							}

							{
								categories.length > 7 ?
									<FormControl w={{ base: "full", lg: "50%" }}>
										<Input placeholder="Search Documents" fontSize="sm" value={search} onChange={(e: any) => { setSearch(e.target.value); }} />
									</FormControl> : <Fragment />
							}

							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
								<TeamCombobox
									teams={teams || []}
									update={(value: any) => {
										setPageIndex(0)
										setTeam(value)
									}}
									selectedTeam={team}
								/>
							</FormControl>
						</Stack>
					</SlideFade>

					<Box
						mt={5}
						px={5}
						border="1px"
						borderRadius="12px"
						borderColor="gray.200"
						hidden={firstTimeLoading}
					>
						{
							currentItems.length < 1 && !firstTimeLoading ?
								<Fragment>
									<Center m={5}>
										<VStack>
											<Text fontSize="xl" fontWeight={600}>
												No Documents Found
											</Text>
											<Text fontWeight={400} color="gray.500">
												You have no documents available
											</Text>
										</VStack>
									</Center>
								</Fragment>
								:
								<Fragment>
									<Grid
										templateColumns={{
											base: "repeat(1, minmax(0, 1fr))",
											sm: "repeat(2, minmax(0, 1fr))",
											lg: "repeat(3, minmax(0, 1fr))",
											xl: "repeat(5, minmax(0, 1fr))",
										}}
										gridAutoRows="1fr"
										gap={5}
										my={5}
									>
										{
											currentItems.length > 0 &&
											currentItems.map((doc: any, i) => {
												return (
													<Skeleton
														isLoaded={!loading && !firstTimeLoading}
														display="flex"
														rounded="lg"
														key={i}
													>
														<Box
															display="flex"
															flexDirection="column"
															position="relative"
															w="100%"
															p={3}
															background="white"
															rounded="md"
															boxShadow="xs"
															border="1px solid"
															borderColor="gray.200"
															className="hover-pop"
															cursor="pointer"
															onClick={() => {
																fetchDocument(Number(doc.docid), doc.ispdf || doc.isdoc)
																	.then(() => {
																		if (doc.isimage) {
																			setIsOpen(true);
																		} else {
																			setPdfIsOpen(true);
																		}
																	});
															}}
														>
															<Box flex="1" mb={3} w="100%">
																{
																	!isNull(doc.thumbnail) ?
																		<AspectRatio minW="100%" ratio={4 / 3} mb={3}>
																			<Image
																				src={doc.thumbnail}
																				rounded="lg"
																				objectFit='contain'
																				border="1px solid"
																				borderColor="gray.100"
																			/>
																		</AspectRatio>
																		:
																		<AspectRatio maxW="100%" ratio={4 / 3} mb={3}>
																			<Icon
																				as={BsFileTextFill}
																				fontSize="5xl"
																				color="gray.600"
																			/>
																		</AspectRatio>
																}

																<Box alignItems="start" width="calc(100%)">
																	<Text
																		fontWeight={600}
																		fontSize="xs"
																		color="gray.600"
																		textAlign="left"
																		wordBreak="break-all"
																		textOverflow="ellipsis"
																		overflow="hidden"
																		whiteSpace="nowrap"
																		height="3ch"
																	>
																		{doc.docfilename}
																	</Text>
																	{doc?.docexpirydate &&
																		<Text
																			fontWeight={500}
																			fontSize="xs"
																			color="gray.500"
																			textAlign="left"
																			wordBreak="break-all"
																		>
																			Expires {localDate(doc.docexpirydate)}
																		</Text>
																	}
																</Box>
															</Box>

															<Box display="flex">
																<VStack alignItems="start">
																	{
																		(doc.expired || doc.expiring) &&
																		<Badge
																			colorScheme={
																				doc.expired ? "danger"
																					: doc.expiring ? "orange"
																						: "gray"
																			}
																			size="sm"
																			variant="outline"

																		>
																			<HStack>
																				<Icon
																					as={
																						doc.expired ? BsXCircleFill
																							: doc.expiring ? BsExclamationDiamondFill
																								: BsDashCircleFill
																					}
																				/>
																				<Text>
																					{
																						doc.expired ? "Expired" : doc.expiring ? "Expiring" : ""
																					}
																				</Text>
																			</HStack>
																		</Badge>
																	}

																	{
																		(doc.ineedtoack || doc.ihaveack) &&
																		<Badge
																			colorScheme={
																				doc.ineedtoack ? "danger" :
																					doc.ihaveack ? "brand" : ""
																			}
																			size="sm"
																			variant="outline"


																		>
																			<HStack>
																				<Icon
																					as={
																						doc.ineedtoack ? BsExclamationCircleFill
																							: doc.ihaveack ? BsCheckCircleFill
																								: BsDashCircleFill
																					}
																				/>
																				<Text>
																					{
																						doc.ineedtoack ? "Acknowledge" : doc.ihaveack ?
																							"Acknowledged" : ""
																					}
																				</Text>
																			</HStack>
																		</Badge>
																	}
																</VStack>
															</Box>
														</Box>
													</Skeleton>
												);
											})
										}
									</Grid>

									<Box
										my={3}
										textAlign="center"
										hidden={pageCount <= 1}
									>
										<Pager
											pageCount={pageCount}
											pageIndex={pageIndex}
											setPageIndex={setPageIndex}
										/>
									</Box>
								</Fragment>
						}
					</Box>
				</Box>

				{/* </TabPanel>

            <TabPanel>
              <Box
                p={5}
                border="1px"
                borderRadius="12px"
                borderColor="gray.200"
              >
                <UnderConstruction />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs> */}

			</Box>
		</Box>
	);
};