import { getWithParams, patch, post } from "../../api/axiosClient";

export async function getCourses(
    filter: string,
    category: string,
    search: string,
    page: string,
    pagesize: string = "10"
) {
    let courseResult: any = {
        "pageCount": 0,
        "courses": [],
        "categories": [],
        "numberOfNotStartedCourses": 0,
        "numberOfInProgressCourses": 0,
        "numberOfInvalidCourses": 0,
        "numberOfExpiringCourses": 0
    };

    await getWithParams("training/all", { filter, category, search, page, pagesize })
        .then((res: any) => {
            courseResult.courses = res.data.modules;
            courseResult.categories = res.data.availableCategories;
            courseResult.pageCount = res.data.pageCount;
            courseResult.numberOfNotStartedCourses = res.data.notStartedCount;
            courseResult.numberOfInProgressCourses = res.data.inProgressCount;
            courseResult.numberOfInvalidCourses = res.data.notValidCount;
            courseResult.numberOfExpiringCourses = res.data.expiringSoonCount;
        })
        .catch((error: any) => {
            throw error;
        });

    return courseResult;
}

export async function getCourseImage(
    id: number,
    datauri: boolean,
    thumbnail: boolean
) {
    let courseImageResult = null;

    await getWithParams("training/modulePhoto", { id, datauri, thumbnail })
        .then((res: any) => {
            courseImageResult = res.data.datauri;
        })
        .catch((error: any) => {
            throw error;
        });

    return courseImageResult;
}

export async function getCourse(id: number) {
    let courseResult = null;

    await getWithParams("training/moduleStatus", { id })
        .then((res: any) => {
            courseResult = res.data.module;
        })
        .catch((error: any) => {
            throw error;
        });

    return courseResult;
}

export async function getModule(id: number) {
    let result = {};

    await getWithParams("training/coursePlayer", { id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function patchModule(data: any) {
    let result = {};

    await patch(`training/coursePlayer`, data)
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getModuleAssetDataUri(
    id: number,
    asset: string,
    datauri: boolean,
    thumbnail: boolean
) {
    let result = {};

    await getWithParams("training/courseAsset", { id, asset, datauri, thumbnail })
        .then((res: any) => {
            result = res.data.datauri;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getModuleAssetVideo(
    id: number,
    asset: string
) {
    let result = {};

    await getWithParams("training/courseAsset", {
        id,
        asset,
        datauri: false,
        thumbnail: false,
    })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getModuleAudio(
    id: number,
    asset: string,
    datauri: boolean,
    thumbnail: boolean
) {
    let result = {};

    await getWithParams("training/courseAsset", { id, asset, datauri, thumbnail })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function completeModule(data: any) {
    let result = {};

    await post(`training/wrapupCourseAttempt`, data)
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}