import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Square,
    Stack,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/rootReducer';
import { BsEnvelope, BsFillExclamationCircleFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { changeEmail } from '../../features/auth/auth.actions';

export const HomepageBanner: React.FC<any> = ({ welcome, unreadCriticalCount }) => {
    const navigateTo = useNavigate();
    const toast = useToast();

    const user = useSelector((state: RootState) => state.authReducer.user);
    
    const [isOpen, setIsOpen] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
    const [confirmError, setConfirmError] = useState("");
    const [error, setError] = useState("");

    const canVerify = () => {
        if (emailAddress.length < 1) {
            setError("You must provide your email address");

            return false;
        } else
            setError("");

        if (confirmEmailAddress.length < 1) {
            setConfirmError("You must confirm your email address");

            return false;
        } else
            setConfirmError("");

        if (emailAddress !== confirmEmailAddress) {
            setConfirmError("This does not match the email address you supplied");

            return false;
        } else
            setConfirmError("");

        return true;
    };

    const sendEmail = () => {
        if (canVerify()) {
            changeEmail({ emailAddress, confirmEmailAddress })
                .then((res: any) => {
                    if (res.success) {
                        setIsOpen(false);

                        toast({
                            title: "A verification email has been successfully sent",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    } else {
                        setConfirmError(res.message);
                    }
                })
                .catch(() => {
                    toast({
                        title: "We couldn't send a verification email",
                        description: "Please try again. If this issue persists please contact your system administrator.",
                        status: "error",
                        duration: 5000,
                        isClosable: true
                    });
                })
        }
    };

    const renderVerifyEmailModal = () => {
        return <Modal
            size="lg"
            isOpen={isOpen}
            onClose={() => { setIsOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader color="gray.600">
                    <HStack>
                        <Icon as={BsEnvelope} />

                        <Text>
                            Verify Your Email
                        </Text>
                    </HStack>
                </ModalHeader>

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Stack spacing="5">
                        <FormControl isRequired>
                            <FormLabel htmlFor="email">Please enter your email address</FormLabel>
                            <Input
                                id="email"
                                type="email"
                                defaultValue={user.email || ""}
                                onChange={(e: any) => {
                                    setEmailAddress(e.target.value);
                                }}
                            />

                            {
                                error !== "" && <Text color="danger.500" fontSize="sm" fontWeight={500} mt={2}>{error}</Text>
                            }
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel htmlFor="emailConfirm">Confirm your email address</FormLabel>
                            <Input
                                id="emailConfirm"
                                type="email"
                                onChange={(e: any) => {
                                    setConfirmEmailAddress(e.target.value);
                                }}
                            />

                            {
                                confirmError !== "" && <Text color="danger.500" fontSize="sm" fontWeight={500} mt={2}>{confirmError}</Text>
                            }
                        </FormControl>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <HStack>
                        <Button onClick={() => { setIsOpen(false); }} cursor="pointer">
                            Cancel
                        </Button>

                        <Button
                            className="hover-pop"
                            colorScheme="brand"
                            _active={{ bg: "" }}
                            _hover={{ bg: "" }}
                            onClick={() => { sendEmail(); }}
                        >
                            Get Verification Email
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    };

    useEffect(() => {
        if (user?.email !== "") setEmailAddress(user.email);
    }, [user.email]);

    return (
        <Box
            borderLeft="3px solid"
            borderColor={
                (!user.emailVerified || user.passwordAboutToExpire) ? "orange.500" :
                    unreadCriticalCount > 0 ? "danger.500" : "telegram.500"
            }
            bg={
                (!user.emailVerified || user.passwordAboutToExpire) ? "orange.50" :
                    unreadCriticalCount > 0 ? "red.50" : "#f9fcff"
            }
            mx={{ base: 3, md: 0 }}
            my={{ base: 0, md: 5 }}
            px={{ base: '4', md: '3' }}
            py={{ base: '4', md: '2.5' }}
            boxShadow="sm"
            borderRadius="md"
        >
            <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "3", md: "2" }}
                justify="space-between"
            >
                <Stack
                    spacing="4"
                    direction={{ base: "column", md: "row" }}
                    align={{ base: "start", md: "center" }}
                >
                    <Stack  direction={{ base: "column", md: "row" }} pe={{ base: "4", md: "0" }}>
                        {
                            (!user.emailVerified || user.passwordAboutToExpire) && <Square
                                size="12"
                                bg="orange.50"
                                borderRadius="md"
                            >
                                <Icon as={BsFillExclamationCircleFill} boxSize="6" color="orange.500" />
                            </Square>
                        }

                        {
                            (user.emailVerified && !user.passwordAboutToExpire) && <Text
                                fontWeight={600}
                                fontSize="md"
                                color="gray.600"
                            >
                                {welcome}
                            </Text>
                        }

                        {
                            !user.emailVerified && <VStack alignItems="start">
                                <Text
                                    fontWeight={600}
                                    fontSize="md"
                                    color="gray.600"
                                >
                                    You do not have a verified email address
                                </Text>

                                <Text
                                    fontWeight={400}
                                    fontSize="sm"
                                    color="gray.600"
                                >
                                    A verified email address is needed to reset your password
                                </Text>
                            </VStack>
                        }

                        {
                            (user.emailVerified && user.passwordAboutToExpire) && <VStack alignItems="start">
                                <Text
                                    fontWeight={600}
                                    fontSize="md"
                                    color="gray.600"
                                >
                                    Your password is about to expire
                                </Text>

                                <Text
                                    fontWeight={400}
                                    fontSize="sm"
                                    color="gray.600"
                                >
                                    Click Update Password to change it from your profile.
                                </Text>
                            </VStack>
                        }

                        {
                            unreadCriticalCount > 0 && (user.emailVerified && !user.passwordAboutToExpire) && <Text
                                fontWeight={400}
                                fontSize="sm"
                                color="gray.600"
                            >
                                -&nbsp; You have{" "}
                                <strong>
                                    {unreadCriticalCount} critical
                                    message
                                    {
                                        unreadCriticalCount === 1
                                            ? ""
                                            : "s"
                                    }
                                </strong>.
                            </Text>
                        }
                    </Stack>
                </Stack>

                {
                    !user.emailVerified && <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={{ base: "stretch", md: "center" }}
                        spacing={3}
                    >
                        <Button
                            className="hover-pop"
                            colorScheme="orange"
                            color="white"
                            width="full"
                            size="sm"
                            fontWeight={600}
                            _hover={{ bg: "" }}
                            onClick={() => { setIsOpen(true); }}
                        >
                            Verify Email
                        </Button>
                    </Stack>
                }

                {
                    (user.emailVerified && user.passwordAboutToExpire) && <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={{ base: "stretch", md: "center" }}
                        spacing={3}
                    >
                        <Button
                            className="hover-pop"
                            colorScheme="orange"
                            color="white"
                            width="full"
                            size="sm"
                            fontWeight={600}
                            _hover={{ bg: "" }}
                            onClick={() => {
                                navigateTo("/profile", {
                                    state: {
                                        from: "home"
                                    }
                                });
                            }}
                        >
                            Update Password
                        </Button>
                    </Stack>
                }

                {
                    (unreadCriticalCount > 0 && !user.passwordAboutToExpire && user.emailVerified) && <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={{ base: "stretch", md: "center" }}
                        spacing={3}
                    >
                        <Button
                            className="hover-pop"
                            variant="outline"
                            borderColor="red.500"
                            color="red.500"
                            bg="transparent"
                            width="full"
                            size="sm"
                            fontWeight={600}
                            onClick={() => {
                                navigateTo("/messages", {
                                    state: {
                                        filter: "critical"
                                    },
                                });
                            }}
                        >
                            View Message{unreadCriticalCount === 1 ? "" : "s"}
                        </Button>
                    </Stack>
                }
            </Stack>

            {renderVerifyEmailModal()}
        </Box>
    )
}