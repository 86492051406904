import { createSlice } from '@reduxjs/toolkit';

const createInitialState = () => {
    return {
        notificationFolders: {
            criticalNewCount: 0,
            criticalClearedCount: 0,
            personalNewCount: 0,
            personalClearedCount: 0,
            teamNewCount: 0,
            teamClearedCount: 0,
            totalCount: 0
        },
        error: null,
    }
}

const notificationSlice = createSlice({
    name: "notifications",
    initialState: createInitialState(),
    reducers: {
        getNotificationFoldersSuccess: (state, { payload }) => {
            state.notificationFolders.criticalNewCount = payload.criticalNewCount;
            state.notificationFolders.criticalClearedCount = payload.criticalClearedCount;
            state.notificationFolders.personalNewCount = payload.personalNewCount;
            state.notificationFolders.personalClearedCount = payload.personalClearedCount;
            state.notificationFolders.teamNewCount = payload.teamNewCount;
            state.notificationFolders.teamClearedCount = payload.teamClearedCount;
            state.notificationFolders.totalCount = payload.totalCount;
            
            state.error = null;
        },
        getNotificationFoldersFailure: (state, { payload }) => {
            state.error = payload.error;
        }
    }
});

export const {
    getNotificationFoldersSuccess,
    getNotificationFoldersFailure
} = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;