import { useCallback, useEffect, useState } from "react";
import { isEmpty, isNull } from "lodash";
import {
	Box,
	Text,
	Textarea,
	VStack,
	HStack,
	Icon,
	Button,
	Badge,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverHeader,
	PopoverBody,
	PopoverCloseButton,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Square,
	SimpleGrid,
	Grid,
	Image,
	SlideFade,
	Input,
	Center,
	FormErrorMessage,
	GridItem,
	useBreakpointValue,
	Stack,
	Spacer,
	Spinner
} from "@chakra-ui/react";
import { BsInfoCircle, BsPlusCircle, BsThreeDotsVertical, BsTrash, BsPencil, BsExclamationTriangle, BsPeople, BsExclamationCircle, BsLifePreserver, BsWrench } from "react-icons/bs";
import { IoSkullOutline } from "react-icons/io5";

import { createAction, createControl, deleteHazard, getHazard, updateHazard } from "../../features/riskAssessments/riskAssessments.actions";

import { Action } from "./Action";
import { Control } from "./Control";
import { TeamMessageCombobox } from "../messages/TeamMessageCombobox";

import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import obbiHelper from "../../assets/logos/obbi_helper.svg";

dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const Hazard: React.FC<any> = ({
	riskAssessmentId,
	riskAssessmentVersionId,
	teams,
	hazards,
	setHazards,
	hazard,
	getBgColor,
	getHoverColor
}) => {
	//#region State Variables
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [loading, setLoading] = useState(true);
	const [deleteHazardModalIsOpen, setDeleteHazardModalIsOpen] = useState(false);

	const [hazardData, setHazardData] = useState<any>({
		rahid: "",
		rahdescription: "",
		rahriskcategory: "",
		rahrisklevel: "",
		rahpeopleaffected: "None",
		rahconsequences: "None"
	});

	const [editingHazard, setEditingHazard] = useState<any>({
		description: "",
		consequences: "",
		peopleaffected: "",
		risk: { riskText: "", score: 0 },
		riskcontrols: "",
		newcontrolsrequired: "",
		newrisklevel: ""
	});

	const [controls, setControls] = useState<any>([]);
	const [actions, setActions] = useState<any>([]);

	const [resetControlInput, setResetControlInput] = useState<boolean>(false);

	const [isControlModalOpen, setIsControlModalOpen] = useState(false);
	const [newControl, setNewControl] = useState("");

	const [isActionModalOpen, setIsActionModalOpen] = useState(false);
	const [newAction, setNewAction] = useState<any>({
		description: "",
		team: null,
		priority: "",
		status: "",
		category: "",
		duedate: ""
	});

	const [controlError, setControlError] = useState(false);
	const [followUpActionError, setFollowUpActionError] = useState(false);
	const [assignToTeamError, setAssignToTeamError] = useState(false);
	const [dateError, setDateError] = useState(false);

	const [isEditHazardModalIsOpen, setIsEditHazardModalIsOpen] = useState(false);

	const [editingHazardDescriptionError, setEditingHazardDescriptionError] = useState(false);
	const [editingHazardAffectedError, setEditingHazardAffectedError] = useState(false);
	const [editingHazardConsequencesError, setEditingHazardConsequencesError] = useState(false);
	const [editingHazardRiskError, setEditingHazardRiskError] = useState(false);
	//#endregion


	//#region Hazard
	const renderDeleteHazardModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteHazardModalIsOpen}
			onClose={() => { setDeleteHazardModalIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Delete Hazard
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Text
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						Are you sure you want to delete <b>{hazard?.rahdescription}</b>?
					</Text>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							onClick={() => { setDeleteHazardModalIsOpen(false); }}
							cursor="pointer"
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="red"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { deleteHazardFunc(hazard?.rahid); }}
						>
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderEditHazardModal = () => {
		return <Modal
			size="lg"
			isOpen={isEditHazardModalIsOpen}
			onClose={() => {
				setEditingHazard({
					description: "",
					consequences: "",
					peopleaffected: "",
					risk: { riskText: "", score: 0 },
					riskcontrols: "",
					newcontrolsrequired: "",
					newrisklevel: ""
				});

				setEditingHazardDescriptionError(false);
				setEditingHazardAffectedError(false);
				setEditingHazardConsequencesError(false);
				setEditingHazardRiskError(false);

				setIsEditHazardModalIsOpen(false);
			}}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					Edit Hazard
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						alignItems="start"
						gap={2}
						w="full"
					>
						<FormControl isRequired isInvalid={editingHazardDescriptionError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Hazard Description
							</FormLabel>

							<Input
								defaultValue={hazardData?.rahdescription || ""}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										description: e.target.value
									});
								}}
							/>
							{editingHazardDescriptionError && <FormErrorMessage>Please enter a hazard description</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={editingHazardAffectedError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								People Affected
							</FormLabel>

							<Textarea
								defaultValue={hazardData.rahpeopleaffected}
								rows={6}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										affects: e.target.value
									});
								}}
							/>
							{editingHazardAffectedError && <FormErrorMessage>Please provide parties or individuals at risk</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={editingHazardConsequencesError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Consequences of Hazard
							</FormLabel>

							<Textarea
								defaultValue={hazardData.rahconsequences}
								rows={6}
								onChange={(e) => {
									setEditingHazard({
										...editingHazard,
										consequences: e.target.value
									});
								}}
							/>
							{editingHazardConsequencesError && <FormErrorMessage>Please provide hazard consequences</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={editingHazardRiskError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Select Risk Level
								<Popover>
									<PopoverTrigger>
										<Button variant="link" size="xs"><Icon as={BsInfoCircle} /></Button>
									</PopoverTrigger>
									<PopoverContent>
										<PopoverArrow />
										<PopoverCloseButton />
										<PopoverHeader
											fontWeight={600}
											fontSize="sm"
											color="gray.600"
										>
											RISK SCALE
										</PopoverHeader>
										<PopoverBody>
											<VStack alignItems="start" gap={2} my={2}>
												<Box>
													<Badge colorScheme="gray" mb={1}>
														1 Trivial
													</Badge>
													<Text whiteSpace="pre-wrap">Won’t cause serious injuries or illnesses.</Text>
												</Box>

												<Box>
													<Badge colorScheme="gray" mb={1}>
														2 Minor
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause injuries or illnesses, only to a mild extent.</Text>
												</Box>

												<Box>
													<Badge colorScheme="yellow" mb={1}>
														3 Moderate
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause injuries or illnesses that may require medical attention but limited treatment.</Text>
												</Box>

												<Box>
													<Badge colorScheme="orange" mb={1}>
														4 Serious
													</Badge>
													<Text whiteSpace="pre-wrap">Can cause irreversible injuries or illnesses that require constant medical attention.</Text>
												</Box>

												<Box>
													<Badge colorScheme="red" mb={1}>
														5 Fatal
													</Badge>
													<Text whiteSpace="pre-wrap">Can result in fatality.</Text>
												</Box>
											</VStack>
										</PopoverBody>
									</PopoverContent>
								</Popover>
							</FormLabel>

							<Popover>
								<PopoverTrigger>
									<Button
										variant="link"
										size="xs"
										border="1px solid"
										borderColor={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
										onClick={() => { }}
									>
										<Square
											float="right"
											cursor="pointer"
											rounded="md"
											roundedRight="none"
											boxShadow="sm"
											size="40px"
											bg={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
										>
											{
												(editingHazard.risk.riskText.indexOf("Fatal") > -1 ||
													hazardData?.rahriskcategory.indexOf("Fatal") > -1) &&
												<Icon
													as={IoSkullOutline}
													color="white"
													mr="2px"
												/>
											}
											<Text
												color="white"
												fontWeight={700}
												fontSize={
													(editingHazard.risk.riskText.indexOf("Fatal") > -1 ||
														hazardData?.rahriskcategory.indexOf("Fatal") > -1) ?
														"10pt" : "lg"
												}
											>
												{editingHazard.risk.score || hazardData?.rahrisklevel}
											</Text>
										</Square>

										<Text
											color={getColor(editingHazard.risk.score || hazardData?.rahrisklevel)}
											fontSize="10pt"
											fontWeight={500}
											mx={3}
										>
											{editingHazard.risk.riskText || hazardData?.rahriskcategory}
										</Text>
									</Button>
								</PopoverTrigger>

								<PopoverContent minW={{ base: "100%", lg: "max-content" }}>
									<PopoverBody py={5} pl={2} pr={5}>
										{renderRiskMatrix()}
									</PopoverBody>
								</PopoverContent>
							</Popover>

							{editingHazardRiskError && <FormErrorMessage>Please select a risk</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							cursor="pointer"
							onClick={() => {
								setEditingHazard({
									description: "",
									consequences: "",
									peopleaffected: "",
									risk: { riskText: "", score: 0 },
									riskcontrols: "",
									newcontrolsrequired: "",
									newrisklevel: ""
								});

								setEditingHazardDescriptionError(false);
								setEditingHazardAffectedError(false);
								setEditingHazardConsequencesError(false);
								setEditingHazardRiskError(false);

								setIsEditHazardModalIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							colorScheme="brand"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => {
								validateEditHazardForm();
							}}
						>
							Update
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const getHazardFunc = useCallback(async () => {
		getHazard(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazard?.rahid
		)
			.then((res: any) => {
				setHazardData(res.hazard);
				setControls(res.hazard.controls || []);
				setActions(res.hazard.actions || []);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const updateHazardFunc = () => {
		updateHazard({
			id: riskAssessmentId,
			version: riskAssessmentVersionId,
			hazard: hazardData?.rahid,

			description: editingHazard?.description || hazardData?.rahdescription,
			consequences: editingHazard?.consequences || hazardData?.rahconsequences,
			peopleaffected: editingHazard?.affects || hazardData?.rahpeopleaffected,

			risklevel: editingHazard?.risk?.score !== 0 ? editingHazard?.risk?.score?.toString() : hazardData?.rahrisklevel,
			riskcategory: editingHazard?.risk?.riskText || hazardData?.rahriskcategory,
			riskcontrols: hazardData?.rahriskcontrols,

			newcontrolsrequired: "",
			newrisklevel: editingHazard?.risk?.score?.toString() || hazardData?.rahrisklevel
		})
			.then((res: any) => {
				setHazardData(res.hazard);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setEditingHazard({
					description: "",
					consequences: "",
					peopleaffected: "",
					risk: { riskText: "", score: 0 },
					riskcontrols: "",
					newcontrolsrequired: "",
					newrisklevel: ""
				});
				setIsEditHazardModalIsOpen(false);
			});
	};

	const deleteHazardFunc = (hazardId: any) => {
		deleteHazard(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId
		)
			.then((res: any) => {
				const deletedHazard = [...hazards.filter(
					(hazard: any) => hazardId === hazard.rahid
				)];

				const updatedHazards = [...hazards.filter(
					(hazard: any) => !deletedHazard.includes(hazard)
				)];

				setHazards(updatedHazards);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setDeleteHazardModalIsOpen(false);
			});
	};
	//#endregion


	//#region Controls
	const renderAddControlModal = (hazard: any) => {
		return <Modal
			size="lg"
			isOpen={isControlModalOpen}
			onClose={() => { setIsControlModalOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Add Safety Control
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						fontSize="sm"
						alignItems="start"
						gap={2}
						w="full"
					>
						<Box
							w="full"
							p={0}
							pr={3}
							border="1px solid"
							borderColor="green.600"
							rounded="lg"
							bg="brand.500"
						>
							<HStack>
								<Image src={obbiHelper} h="100px" bg="white" roundedLeft="6.5px" />

								<Spacer />

								<Box alignItems="start" alignSelf="center">
									<Text
										fontWeight={700}
										color="white"
										mb={1}
									>
										Obbi Tips - Safety Controls
									</Text>

									<Text
										fontWeight={500}
										fontSize={{ base: "8pt", md: "xs" }}
										color="green.200"
									>
										Ensure the control effectively reduces the risk and is practical for all team members to follow.
									</Text>
								</Box>
							</HStack>
						</Box>

						<Text color="gray.600" fontWeight={500}>
							Control for <b>{hazardData?.rahdescription}</b>
						</Text>

						<FormControl isRequired isInvalid={controlError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Safety Control
							</FormLabel>

							{
								resetControlInput ? <Spinner size="sm" color="green" /> : <Textarea
									autoFocus
									rows={10}
									id="SafetyControlTextarea"
									onChange={(e: any) => {
										setNewControl(e.target.value);
									}}
								/>
							}

							{controlError && <FormErrorMessage>Please enter a control</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button onClick={() => { setIsControlModalOpen(false); }} cursor="pointer" size="sm">
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => { validateControlForm(true) }}
						>
							Add Another
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => { validateControlForm() }}
						>
							Add
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const addControl = (hazard: string | number, addAnother: boolean) => {
		if (addAnother) setResetControlInput(true);

		createControl({
			id: Number(riskAssessmentId),
			version: Number(riskAssessmentVersionId),
			hazard: Number(hazard),
			description: newControl
		})
			.then((res: any) => {
				setControls([
					...controls,
					res.control
				]);
			})
			.catch((error: any) => {
				console.log(error);
			}).finally(() => {
				setNewControl("");
				setResetControlInput(false);

				if (!addAnother) setIsControlModalOpen(false);
			});
	};
	//#endregion


	//#region Actions
	const renderAddActionModal = () => {
		return <Modal
			size="lg"
			isOpen={isActionModalOpen}
			onClose={() => { setIsActionModalOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Add Action
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						fontSize="sm"
						alignItems="start"
						gap={2}
						w="full"
					>
						<Box
							w="full"
							p={0}
							pr={3}
							border="1px solid"
							borderColor="green.600"
							rounded="lg"
							bg="brand.500"
						>
							<HStack>
								<Image src={obbiHelper} h="100px" bg="white" roundedLeft="6.5px" />

								<Spacer />

								<Box alignItems="start" alignSelf="center">
									<Text
										fontWeight={700}
										color="white"
										mb={1}
									>
										Obbi Tips - Follow up Actions
									</Text>

									<Text
										fontWeight={500}
										fontSize={{ base: "8pt", md: "xs" }}
										color="green.200"
									>
										Ensure your follow up action is clear, time-bound, and assigns specific responsibility.
									</Text>
								</Box>
							</HStack>
						</Box>

						<Text color="gray.600" fontWeight={500}>
							Action for <b>{hazardData.rahdescription}</b>
						</Text>

						<FormControl isRequired isInvalid={followUpActionError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Follow up Action
							</FormLabel>

							<Textarea
								autoFocus
								onChange={(e: any) => {
									setNewAction({
										...newAction,
										description: e.target.value
									});
									setFollowUpActionError(false);
								}} />
							{followUpActionError && <FormErrorMessage>Please enter a follow up action</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={dateError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Due Date
							</FormLabel>

							<HStack>
								<Button
									size="sm"
									onClick={() => {
										const oneWeekFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 7 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: oneWeekFromNow
										});
										setDateError(false);
									}}
								>
									1 week
								</Button>

								<Button
									size="sm"
									onClick={() => {
										const twoWeeksFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 14 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: twoWeeksFromNow
										});
										setDateError(false);
									}}
								>
									2 weeks
								</Button>

								<Button
									size="sm"
									onClick={() => {
										const threeWeeksFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'days': 21 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: threeWeeksFromNow
										});
										setDateError(false);
									}}
								>
									3 weeks
								</Button>

								<Button
									size="sm"

									onClick={() => {
										const oneMonthFromNow = dayjs(
											dayjs().add(dayjs.duration({ 'months': 1 }))
										)
											.format("YYYY-MM-DD");

										setNewAction({
											...newAction,
											duedate: oneMonthFromNow
										});
										setDateError(false);
									}}
								>
									1 month
								</Button>
							</HStack>

							<Input
								mt={2}
								type="date"
								defaultValue={newAction?.duedate}
								onChange={(e: any) => {
									setNewAction({
										...newAction,
										duedate: e.target.value
									});
									setDateError(false);
								}} />
							{dateError && <FormErrorMessage>Please enter a due date</FormErrorMessage>}
						</FormControl>

						<FormControl isRequired isInvalid={assignToTeamError}>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Assign to Team
							</FormLabel>

							<TeamMessageCombobox
								teams={teams}
								update={selectActionTeam}
								selectedTeam={newAction?.team?.teamname}
							/>
							{assignToTeamError && <FormErrorMessage>Please select a team</FormErrorMessage>}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button onClick={() => { setIsActionModalOpen(false); }} cursor="pointer" size="sm">
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={validateActionForm}
						>
							Add
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const resetActionModalFields = () => {
		setNewAction({
			description: "",
			team: null,
			priority: "",
			status: "",
			category: "",
			duedate: "",
		});

		setDateError(false);
		setFollowUpActionError(false);
		setAssignToTeamError(false);
	}

	const validateEditHazardForm = () => {
		let isValid = true;

		if (isEmpty(editingHazard?.description) && isEmpty(hazardData?.rahdescription)) {
			setEditingHazardDescriptionError(true);
			isValid = false;
		}

		if (isEmpty(editingHazard?.peopleaffected) && isEmpty(hazardData?.rahpeopleaffected)) {
			setEditingHazardAffectedError(true);
			isValid = false;
		}

		if (isEmpty(editingHazard?.consequences) && isEmpty(hazardData?.rahconsequences)) {
			setEditingHazardConsequencesError(true);
			isValid = false;
		}

		if (isValid) {
			updateHazardFunc();
		}
	}

	const validateActionForm = () => {
		if (isEmpty(newAction?.description)) {
			setFollowUpActionError(true);
		}

		if (isEmpty(newAction?.duedate)) {
			setDateError(true);
		}

		if (isEmpty(newAction?.team)) {
			setAssignToTeamError(true);
		}

		if (newAction?.description && newAction?.duedate && newAction?.team) {
			addAction(hazardData.rahid);
		}
	}

	const validateControlForm = (addAnother: boolean = false) => {
		if (isEmpty(newControl)) {
			setControlError(true);
		} else {
			addControl(hazardData?.rahid, addAnother);
		}
	}

	const selectActionTeam = (arg: any): void => {
		if (arg?.teamid === "0") return;

		setNewAction({
			...newAction,
			team: arg
		})

		setAssignToTeamError(false);
	}

	const addAction = (hazard: string | number) => {
		createAction({
			id: Number(riskAssessmentId),
			version: Number(riskAssessmentVersionId),
			hazard: Number(hazard),
			description: newAction?.description,
			team: newAction?.team?.teamid,
			priority: 0, // 0 = Normal, 1 = Critical
			status: 0, // 0 = Not started, 1 = Ongoing, 2 = Blocked, 99 = Completed
			category: "Risk Assessment",
			duedate: newAction?.duedate,
		})
			.then((res: any) => {
				setActions([
					...actions,
					res.action
				]);
			})
			.catch((error: any) => {
				console.log(error);
			});

		setIsActionModalOpen(false);
		setNewAction({
			description: "",
			team: "",
			priority: "",
			status: "",
			category: "",
			duedate: "",
		});
	};
	//#endregion


	//#region Helper Functions
	const renderRiskMatrix = () => {
		const matrixData = [
			// ROW 1
			{ type: "sidebar", text: "Trivial", number: 1 },
			{ number: 1, riskText: "Trivial / Rare" },
			{ number: 2, riskText: "Trivial / Unlikely" },
			{ number: 3, riskText: "Trivial / Possible" },
			{ number: 4, riskText: "Trivial / Likely" },
			{ number: 5, riskText: "Trivial / Frequent" },

			// ROW 2
			{ type: "sidebar", text: "Minor", number: 2 },
			{ number: 2, riskText: "Minor / Rare" },
			{ number: 4, riskText: "Minor / Unlikely" },
			{ number: 6, riskText: "Minor / Possible" },
			{ number: 8, riskText: "Minor / Likely" },
			{ number: 10, riskText: "Minor / Frequent" },

			// ROW 3
			{ type: "sidebar", text: "Moderate", number: 3 },
			{ number: 3, riskText: "Moderate / Rare" },
			{ number: 6, riskText: "Moderate / Unlikely" },
			{ number: 9, riskText: "Moderate / Possible" },
			{ number: 12, riskText: "Moderate / Likely" },
			{ number: 15, riskText: "Moderate / Frequent" },

			// ROW 4
			{ type: "sidebar", text: "Serious", number: 4 },
			{ number: 4, riskText: "Serious / Rare" },
			{ number: 8, riskText: "Serious / Unlikely" },
			{ number: 12, riskText: "Serious / Possible" },
			{ number: 16, riskText: "Serious / Likely" },
			{ number: 20, riskText: "Serious / Frequent" },

			// ROW 5
			{ type: "sidebar", text: "Fatal", number: 5, icon: <Icon as={IoSkullOutline} color="gray.600" /> },
			{ number: 5, riskText: "Fatal / Rare" },
			{ number: 10, riskText: "Fatal / Unlikely" },
			{ number: 15, riskText: "Fatal / Possible" },
			{ number: 20, riskText: "Fatal / Likely" },
			{ number: 25, riskText: "Fatal / Frequent" },

			// FOOTER ROW 
			{ type: "sidebar" },
			{ type: "footer", text: "Rare", number: 1 },
			{ type: "footer", text: "Unlikely", number: 2 },
			{ type: "footer", text: "Possible", number: 3 },
			{ type: "footer", text: "Likely", number: 4 },
			{ type: "footer", text: "Frequent", number: 5 },
		];

		return <SimpleGrid columns={6}>
			{
				matrixData.map((data: any, index: any) => {
					if (data.type === "footer") {
						return <Box
							key={index}
							pt={2}
							textAlign="center"
							alignSelf="center"
						>
							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
								alignSelf="center"
								textAlign="center"
							>
								{data.text}
							</Text>

							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.number && data.number}
							</Text>
						</Box>
					} else if (data.type === "sidebar") {
						return <Box
							key={index}
							textAlign="center"
							alignSelf="center"
						>
							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.text} {data.icon}
							</Text>

							<Text
								color="gray.600"
								fontSize="xs"
								fontWeight={500}
							>
								{data.number}
							</Text>
						</Box>
					} else
						return <Center
							key={index}
							cursor="pointer"
							rounded="md"
							boxShadow="sm"
							boxSize="65px"
							m="1.5px"
							px={7}
							bg={data.bgColor || getBgColor(Number(data.number))}
							_hover={{
								bg: getHoverColor(Number(data.number))
							}}
							onClick={() => {
								setEditingHazard({
									...editingHazard,
									risk: { riskText: data.riskText, score: data.number }
								});
							}}
						>
							<Text
								color="white"
								fontWeight={700}
							>
								{data.number}
							</Text>
						</Center>
				})
			}
		</SimpleGrid>
	};

	const getColor = (risk: any) => (
		risk > 0 && risk < 5 ? "#37a169" :
			risk > 4 && risk < 11 ? "#D69E2E" :
				risk > 10 && risk < 16 ? "#ED8936" :
					risk > 15 && risk < 21 ? "#E53E3E" :
						"#1A202C"
	);
	//#endregion

	useEffect(() => {
		getHazardFunc();
	}, []);

	return (
		<SlideFade in={!loading} style={{ width: "100%" }}>
			<Box w="100%">
				<Box
					w="100%"
					pt={isMobile ? 1 : 3}
					pb={isMobile ? 2 : 3}
					px={isMobile ? 3 : 5}
					bg={getColor(hazardData?.rahrisklevel)}
					color="white"
					roundedTop="lg"
					boxShadow="sm"
				>
					<Stack
						direction={{ base: "column", md: "row" }}
						mx={0}
						w="100%"
					>
						<VStack
							w="100%"
							alignItems="start"
							mt={{ base: 0, md: "7px" }}
						>
							<HStack w="100%" fontWeight={600}>
								{
									!isMobile && <Icon as={BsExclamationTriangle} fontSize="md" color="white" />
								}
								<Text color="white" fontSize={isMobile ? "sm" : "lg"}>
									{hazardData?.rahdescription}
								</Text>

								<Spacer />

								{
									isMobile && <Box>
										<Menu>
											<MenuButton>
												<Icon
													as={BsThreeDotsVertical}
													mt={2}
													fontSize="sm"
													color="white"
													cursor="pointer"
												/>
											</MenuButton>

											<MenuList
												fontSize="sm"
												color="gray.600"
											>
												<MenuItem
													fontWeight={500}
													onClick={() => {
														setIsEditHazardModalIsOpen(hazardData);
													}}
												>
													<Icon as={BsPencil} mr={2} />
													<Text>Edit</Text>
												</MenuItem>
												<MenuItem
													fontWeight={500}
													onClick={() => {
														setDeleteHazardModalIsOpen(true);
													}}
												>
													<Icon as={BsTrash} mr={2} />
													<Text>Delete</Text>
												</MenuItem>
											</MenuList>
										</Menu>
									</Box>
								}
							</HStack>
						</VStack>

						<Spacer />

						<HStack>
							<Box>
								<HStack gap={2}>
									<Text
										pl={{ base: 0, md: "5px" }}
										color="white"
										fontWeight={600}
										fontSize="sm"
										textAlign="center"
									>
										Risk
									</Text>

									<Button
										variant="link"
										size="xs"
										border="1px solid"
										borderColor="white"
										bg="white"
										cursor='default'
									>
										<Square
											rounded="7px"
											size="40px"
											bg={getColor(hazardData?.rahrisklevel)}
										>
											{
												(hazardData?.rahriskcategory.indexOf("Fatal") > -1) && <Icon
													as={IoSkullOutline}
													color="white"
													mr="2px"
												/>
											}

											<Text
												color="white"
												fontWeight={700}
												fontSize={(hazardData?.rahriskcategory.indexOf("Fatal") > -1) ? "10pt" : "lg"}
											>
												{hazardData?.rahrisklevel}
											</Text>
										</Square>

										<Text
											color={getColor(hazardData?.rahrisklevel)}
											fontSize="10pt"
											fontWeight={600}
											mx={3}
										>
											{hazardData?.rahriskcategory}
										</Text>
									</Button>
								</HStack>
							</Box>

							{
								!isMobile &&
								<>
									<Spacer />

									<Box>
										<Menu>
											<MenuButton>
												<Icon
													as={BsThreeDotsVertical}
													fontSize="sm"
													color="white"
													cursor="pointer"
												/>
											</MenuButton>

											<MenuList
												fontSize="sm"
												color="gray.600"
											>
												<MenuItem
													fontWeight={500}
													onClick={() => {
														setIsEditHazardModalIsOpen(hazardData);
													}}
												>
													<Icon as={BsPencil} mr={2} />
													<Text>Edit</Text>
												</MenuItem>
												<MenuItem
													fontWeight={500}
													onClick={() => {
														setDeleteHazardModalIsOpen(true);
													}}
												>
													<Icon as={BsTrash} mr={2} />
													<Text>Delete</Text>
												</MenuItem>
											</MenuList>
										</Menu>
									</Box>
								</>
							}
						</HStack>
					</Stack>
				</Box>

				{/* Controls and Actions */}
				<Box
					w="full"
					p={isMobile ? 3 : 5}
					pt={3}
					bg="white"
					roundedBottom="lg"
					boxShadow="xs"
					border="1px solid"
					borderColor="gray.100"
				>
					<Grid
						templateColumns={{ md: 'repeat(1, 1fr)', xl: 'repeat(2, 2fr)' }}
						gap={6}
					>
						<GridItem>
							<HStack mb={2}>
								<Icon as={BsPeople} color="gray.500" />
								<Text color="gray.600" fontWeight={600} fontSize={{ base: "xs", md: "sm" }}>People Affected</Text>
							</HStack>
							<Text color="gray.500" fontSize="11pt" fontWeight={500} mt={2}>
								{hazardData?.rahpeopleaffected}
							</Text>
						</GridItem>

						<GridItem>
							<HStack mb={2}>
								<Icon as={BsExclamationCircle} color="gray.500" />
								<Text color="gray.600" fontWeight={600} fontSize={{ base: "xs", md: "sm" }}>Consequences</Text>
							</HStack>
							<Text color="gray.500" fontSize="11pt" fontWeight={500} mt={2}>
								{hazardData?.rahconsequences}
							</Text>
						</GridItem>

						<Box w="full">
							<HStack mb={2}>
								<Icon as={BsLifePreserver} color="gray.500" />
								<Text
									color="gray.600"
									fontWeight={600}
									fontSize={{ base: "xs", md: "sm" }}
									mb={2}
								>
									Existing Safety Controls
								</Text>
							</HStack>

							<Box
								textAlign="center"
								border={isEmpty(controls) ? "1px solid" : "none"}
								borderColor="gray.200"
								rounded="lg"
								p={isEmpty(controls) ? 2 : 0}
							>
								{
									isEmpty(controls) ?
										<VStack gap={2}>
											<Box w="full">
												<Text
													color="gray.600"
													fontSize="lg"
													fontWeight={700}
												>
													No Controls
												</Text>

												<Text
													color="gray.500"
													fontSize="sm"
													fontWeight={400}
													mt={1}
												>
													No Controls have been added to this Hazard
												</Text>
											</Box>

											<Button
												variant="outline"
												color="gray.600"
												size="sm"
												leftIcon={<Icon as={BsPlusCircle} />}
												onClick={() => {
													setIsControlModalOpen(true);
												}}
											>
												Add Safety Control
											</Button>
										</VStack> :

										<SimpleGrid columns={1} gap={2}>
											<Button
												h="full"
												minH="50px"
												fontSize="sm"
												variant="outline"
												onClick={() => {
													setIsControlModalOpen(true);
												}}
											>
												<HStack w="full">
													<Icon as={BsPlusCircle} />
													<Text>Add Safety Control</Text>
												</HStack>
											</Button>

											{
												controls?.map((c: any) => {
													return <Control
														key={c.racid}
														control={c}
														controls={controls}
														setControls={setControls}
														riskAssessmentId={riskAssessmentId}
														riskAssessmentVersionId={riskAssessmentVersionId}
														hazardId={hazardData.rahid}
														hazardData={hazardData}
														getColor={getColor}
													/>
												})
											}
										</SimpleGrid>
								}
							</Box>
						</Box>

						<Box>
							<HStack mb={2}>
								<Icon as={BsWrench} color="gray.500" />
								<Text
									color="gray.600"
									fontWeight={600}
									fontSize={{ base: "xs", md: "sm" }}
									mb={2}
								>
									Follow up Actions
								</Text>
							</HStack>

							<Box
								textAlign="center"
								border={isEmpty(controls) ? "1px solid" : "none"}
								borderColor="gray.200"
								rounded="lg"
								p={isEmpty(controls) ? 2 : 0}
							>
								{
									isEmpty(actions) ? <VStack gap={2}>
										<Box w="full">
											<Text
												color="gray.600"
												fontSize={{ base: "sm", md: "lg" }}
												fontWeight={700}
											>
												No Actions
											</Text>

											<Text
												color="gray.500"
												fontSize={{ base: "xs", md: "sm" }}
												fontWeight={400}
												mt={1}
											>
												No Actions have been added to this Hazard
											</Text>
										</Box>

										<Button
											variant="outline"
											color="gray.600"
											size={isMobile ? "xs" : "sm"}
											leftIcon={<Icon as={BsPlusCircle} />}
											onClick={() => {
												setIsActionModalOpen(true);
											}}
										>
											Add Action
										</Button>
									</VStack> :

										<SimpleGrid columns={1} gap={2}>
											<Button
												h="full"
												minH="50px"
												fontSize="sm"
												variant="outline"
												onClick={() => {
													resetActionModalFields();
													setIsActionModalOpen(true);
												}}
											>
												<HStack w="full">
													<Icon as={BsPlusCircle} />
													<Text>Add Action</Text>
												</HStack>
											</Button>

											{
												actions?.map((a: any) => {
													return <Action
														key={a.raaid}
														action={a}
														actions={actions}
														setActions={setActions}
														riskAssessmentId={riskAssessmentId}
														riskAssessmentVersionId={riskAssessmentVersionId}
														hazardId={hazardData.rahid}
													/>
												})
											}
										</SimpleGrid>
								}
							</Box>
						</Box>
					</Grid>
				</Box>

				{renderEditHazardModal()}
				{renderDeleteHazardModal()}
				{renderAddControlModal(hazard)}
				{renderAddActionModal()}
			</Box>
		</SlideFade>
	);
};