import { useCallback, useEffect, useState } from "react";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	HStack,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	SlideFade,
	Spacer,
	Square,
	Text,
	Textarea,
	VStack
} from "@chakra-ui/react";
import { BsThreeDotsVertical, BsPencil, BsTrash, BsInfoCircle } from "react-icons/bs";

import { deleteControl, getControl, updateControl } from "../../features/riskAssessments/riskAssessments.actions";
import { IoSkullOutline } from "react-icons/io5";
import { isEmpty } from "lodash";

export const Control: React.FC<any> = ({
	control,
	controls,
	setControls,
	riskAssessmentId,
	riskAssessmentVersionId,
	hazardId,
	hazardData,
	getColor
}) => {
	const [loading, setLoading] = useState(true);

	const [controlData, setControlData] = useState<any>({
		racid: "",
		racdescription: "N/A"
	});
	const [editControlData, setEditControlData] = useState<any>("");
	const [showControlValidationMessage, setShowControlValidationMessage] = useState(false);

	const [deleteControlModalIsOpen, setDeleteControlModalIsOpen] = useState(false);
	const [editControlModalIsOpen, setEditControlModalIsOpen] = useState(false);

	const validateEditedControl = () => {
		let isValid = true;

		if (isEmpty(editControlData)) {
			isValid = false;
			setShowControlValidationMessage(true);
		}

		return isValid;
	};

	const editControl = () => {
		updateControl({
			id: Number(riskAssessmentId),
			version: Number(riskAssessmentVersionId),
			hazard: Number(hazardData?.rahid),
			control: Number(controlData?.racid),
			description: editControlData,
			state: 0
		}).
			then((res: any) => {
				setControlData(res.control);
				setEditControlModalIsOpen(false);
				setShowControlValidationMessage(false);
				setEditControlData("");
			})
			.catch((error: any) => {
				console.log(error);
			})
	};

	const renderDeleteControlModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteControlModalIsOpen}
			onClose={() => { setDeleteControlModalIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Delete Control
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Text
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						Are you sure you want to delete <b>{controlData.racdescription}</b>?
					</Text>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							cursor="pointer"
							onClick={() => { setDeleteControlModalIsOpen(false); }}
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="red"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { deleteControlFunc(controlData.racid); }}
						>
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderEditHazardModal = () => {
		return <Modal
			size="lg"
			isOpen={editControlModalIsOpen}
			onClose={() => {
				setEditControlModalIsOpen(false);
				setShowControlValidationMessage(false);
				setEditControlData("");
			}}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					Edit Safety Control
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<VStack
						fontSize="sm"
						alignItems="start"
						gap={2}
						w="full"
					>
						<Text color="gray.600" fontWeight={500}>
							Safety Control for <b>{hazardData?.rahdescription}</b>
						</Text>

						<FormControl isRequired>
							<FormLabel
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								Safety Control
							</FormLabel>

							<Textarea
								autoFocus
								defaultValue={controlData?.racdescription}
								rows={10}
								onChange={(e) => {
									setEditControlData(e.target.value);
								}}
							/>

							{
								showControlValidationMessage && <Text
									mt={1}
									color="red.500"
									fontWeight={500}
								>
									You must provide a Safety Control to update.
								</Text>
							}
						</FormControl>
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							cursor="pointer"
							onClick={() => {
								setEditControlModalIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							colorScheme="brand"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => {
								if (validateEditedControl()) {
									editControl();
								}
							}}
						>
							Update
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const deleteControlFunc = (controlId: any) => {
		deleteControl(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId,
			controlId
		)
			.then(() => {
				const deletedControl = controls.filter(
					(control: any) => controlId === control.racid
				);

				const updatedControls = controls.filter(
					(control: any) => !deletedControl.includes(control)
				);

				setControls(updatedControls);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setDeleteControlModalIsOpen(false);
			});
	};

	const getControlFunc = useCallback(async () => {
		setLoading(true);

		getControl(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId,
			control?.racid
		)
			.then((res: any) => {
				setControlData(res.control);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getControlFunc();
	}, []);

	return (
		<SlideFade in={!loading} style={{ width: "100%" }}>
			<Button
				w="full"
				minH="50px"
				h="fit-content"
				boxShadow="xs"
				bg="white"
				borderRadius="md"
				border="1px solid"
				borderColor="gray.100"
				cursor="default"
				_hover={{ bg: "" }}
				_active={{ bg: "" }}
				p={2}
				pl={3}
			>
				<HStack w="full">
					<Text
						lineHeight="5"
						color="gray.600"
						textAlign="left"
						fontSize={{ base: "xs", md: "sm" }}
						fontWeight={500}
						wordBreak="break-word"
						whiteSpace="break-spaces"
					>
						{controlData.racdescription}
					</Text>

					<Spacer />

					<Menu>
						<MenuButton alignSelf="baseline" pt="2px">
							<Icon
								as={BsThreeDotsVertical}
								fontSize="sm"
								color="gray.600"
								cursor="pointer"
							/>
						</MenuButton>

						<MenuList fontSize="sm">
							<MenuItem
								onClick={() => {
									setEditControlModalIsOpen(true);
								}}
							>
								<Icon as={BsPencil} mr={2} />
								<Text>Edit</Text>
							</MenuItem>
							<MenuItem
								onClick={() => {
									setDeleteControlModalIsOpen(true);
								}}
							>
								<Icon as={BsTrash} mr={2} />
								<Text>Delete</Text>
							</MenuItem>
						</MenuList>
					</Menu>
				</HStack>

				{renderEditHazardModal()}
				{renderDeleteControlModal()}
			</Button>
		</SlideFade>
	);
};