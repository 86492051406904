import React from "react";
import { Box, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { BsX } from "react-icons/bs";

export const SmallFilterCard: React.FC<any> = ({
	title,
	count,
	icon,
	iconColor,
	onClickAction,
	selectedCard,
}) => {
	return (
		<Box
			w="fit-content"
			p={1.5}
			m={1}
			display="inline-block"
			textOverflow="ellipsis"
			className={selectedCard === title ? "" : "hover-pop"}
			cursor="pointer"
			rounded="lg"
			bgColor="white"
			border="1px solid"
			borderColor={selectedCard === title ? "brand.500" : "gray.200"}
			onClick={onClickAction}
		>
			<VStack color="gray.500" textOverflow="ellipsis">
				<HStack textOverflow="ellipsis" alignSelf="normal">
					{
						icon && <Icon as={selectedCard === title ? BsX : icon} color={selectedCard === title ? "gray.600" : iconColor} />
					}

					<Text fontSize={{ base: "xs", md: "sm" }} fontWeight={500} color="gray.600">
						{title}
					</Text>
				</HStack>
			</VStack>
		</Box>
	);
};
