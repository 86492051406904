import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Box,
    VStack,
    SimpleGrid,
    Text,
    Image,
    Button,
    Icon,
    HStack,
    Stack,
    useToast,
    useDisclosure,
    SlideFade,
    useBreakpointValue
} from "@chakra-ui/react";
import {
    BsArrowLeftCircleFill,
    BsArrowRightCircle,
    BsArrowRightCircleFill,
    BsExclamationCircle,
    BsExclamationCircleFill,
    BsTrashFill
} from "react-icons/bs";

import {
    dismissAlert,
    getNotification,
    getNotificationAttachment,
    rateTrainingGroup
} from "../../features/notifications/notification.actions";

import { ObbiAlertDialog } from "../common/AlertDialog";
import { Rating } from "../common/Rating";
import waiting from "../../assets/images/waiting.svg";
import Lightbox from "react-image-lightbox";
import { isNull, isUndefined } from "lodash";
import { localDateTime } from "../../helpers/DayJsHelper";

export const Notification: React.FC<any> = () => {
    const { id } = useParams();
    const { state }: any = useLocation();
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [notification, setNotification] = useState<any>({ "links": [], "cleared": true });
    const [rating, setRating] = useState<number>(0);

    const [attachments, setAttachments] = useState<any>([]);
    const [activeImage, setActiveImage] = useState("");
    const [isImageOpen, setIsImageOpen] = useState(false);

    useEffect(() => {
        async function initialise() {
            setLoading(true);

            getNotification(Number(id))
                .then(async (notification: any) => {
                    const attachmentThumbnails = [];
                    const notificationAttachments = notification.alert.attachments;

                    for (let attachment in notificationAttachments) {
                        const thumbnail = await getNotificationAttachment(notification.alertId, attachment);

                        attachmentThumbnails.push({
                            thumbnail
                        });
                    }

                    setAttachments(attachmentThumbnails);
                    setNotification(notification.alert);
                    setTitle(notification.breakdown.description);
                })
                .catch((error) => { console.log(error); })
                .finally(() => { setLoading(false); });
        }

        initialise();
    }, [id]);

    const submitGroupRating = () => {
        rateTrainingGroup(
            Number(id),
            notification.aetraininggroup,
            rating
        )
            .then((res) => {
                toast({
                    title: `${notification.tgname} rated.`,
                    description: "",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                navigateTo(-1);
            })
            .catch(() => {
                toast({
                    title: `Could not rate ${notification.tgname}. Please try again.`,
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                });
            })
    };

    const dismiss = (id: number) => {
        dismissAlert(id)
            .then(() => {
                toast({
                    title: "Notification cleared.",
                    description: "",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                navigateTo(-1);
            })
            .catch(() => {
                toast({
                    title: "Could not clear this notification. Please try again.",
                    description: "",
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                });
            });
    };

    const getTaskObservationLinks = (
        verb: string,
        id: string | null,
        description: string,
        selectedUser?: string,
        taskId?: string
    ) => {
        let links: any[] = [];
        let path = "/";
        let state: any = { from: "notification", id: id };

        switch (verb) {
            case "TaskObservationSummary":
                path += `tasks/tasksummary/${id}`;
                state = {
                    ...state,
                    navData: {
                        isGuided: true,
                        tabIndex: 1,
                        selectedUser
                    },
                    isGuided: true
                };
                break;
            case "TaskObservation":
                if (isNull(id)) {
                    path += "tasks/task/observation";
                } else {
                    path += `tasks/task/${id}`;
                }

                state = {
                    ...state,
                    navData: {
                        isGuided: true,
                        tabIndex: 1,
                        selectedUser,
                        taskId
                    },
                    isGuided: true
                };
                break;
        }

        links.push(
            <SlideFade in={!loading} offsetY="20px">
                <Button
                    w={{ base: "full", md: "unset" }}
                    size="md"
                    colorScheme="brand"
                    _hover={{ bg: "" }}
                    onClick={() => {
                        navigateTo(path, { state });
                    }}
                >
                    {description}
                    <Icon
                        ml={3}
                        as={BsArrowRightCircle}
                        verticalAlign="middle"
                    />
                </Button>
            </SlideFade>
        );

        return links;
    };

    const getNotificationLinks = (
        verb: string,
        id: string,
        description: string,
        isPdf?: boolean
    ) => {
        let links: any[] = [];
        let path = "/";
        let state: any = { from: "notification", id: id };

        switch (verb) {
            case "Action": path += `actions/action/${id}`;
                break;
            case "Certificate":
                path += "certificates";
                state = { ...state, isPdf: isPdf };
                break;
            case "Document":
                path += "documents";
                state = { ...state, isPdf: isPdf };
                break;
            case "StartCourse": path += `training/module/${id}`;
                break;
            case "TaskAnalysis": path += "taskanalysis";
                break;
            case "TaskObservation":
                path += `tasks/task/${id}`;
                state = {
                    ...state,
                    navData: { tabIndex: 1 },
                    isGuided: true
                };
                break;
            case "TeamTaskLibrary": path += "tasks";
                break;
            case "TeamTaskHistory":
                path += `tasks/tasksummary/${id}`;
                state = { ...state, tabIndex: 1 };
                break;
            case "TeamTaskReport": path += `tasks/taskreport/${id}`;
                break;
            case "GuidedTaskReport": path += "guidedtaskreport";
                break;
            case "PeriodicTaskReport": path += "periodictaskreport";
                break;
        };

        links.push(
            <SlideFade in={!loading} offsetY="20px">
                <Button
                    w={{ base: "full", md: "unset" }}
                    colorScheme="brand"
                    size={isMobile ? "md" : "sm"}
                    _hover={{ bg: "" }}
                    onClick={() => {
                        navigateTo(path, { state });
                    }}
                >
                    {description}
                    <Icon
                        ml={3}
                        as={BsArrowRightCircle}
                        verticalAlign="middle"
                    />
                </Button>
            </SlideFade>
        );

        return links;
    };

    const getActionLinks = () => {
        let links: any[] = [];

        const notificationVerb = notification.aeactionverb;
        const notificationId = notification.aeactionargint;
        const notificationDescription = notification.aeactiondescription;

        if (!isNull(notification.aeactionverb) && !isUndefined(notification.aeactionverb)) {
            links.push(getNotificationLinks(
                notificationVerb,
                notificationId,
                notificationDescription
            ));
        }

        if (notification.links.length > 0) {
            for (let i = 0; i < notification.links.length; i++) {
                const verb = notification.links[i]?.aeactionverb;
                const id = notification.links[i]?.aeactionargint;
                const description = notification.links[i]?.aeactiondescription;

                let isPdf = false;

                if (verb === "Document") {
                    isPdf = notification.document?.ispdf || notification.document?.isdoc;
                    links.push(getNotificationLinks(
                        verb,
                        id,
                        description,
                        isPdf
                    ));
                } else if (verb === "Certificate") {
                    isPdf = notification.certificate?.ispdf || notification.certificate?.isdoc;
                    links.push(getNotificationLinks(
                        verb,
                        id,
                        description,
                        isPdf
                    ));
                } else if (verb === "TaskObservation") {

                    if (!isNull(notification.observationteamtask)) {

                        switch (notification.observationattemptsinprogress) {
                            case 0:
                                links.push(getTaskObservationLinks(
                                    verb,
                                    null,
                                    description,
                                    notification.aecreatedby,
                                    notification.observationteamtask?.ttid
                                ));
                                break;
                            case 1:
                                links.push(getTaskObservationLinks(
                                    verb,
                                    notification.observationactiveattempt[0]?.ttaid,
                                    description,
                                    notification.aecreatedby,
                                    notification.observationactiveattempt[0]?.ttid
                                ));
                                break;
                            default:
                                links.push(getTaskObservationLinks(
                                    "TaskObservationSummary",
                                    notification.observationteamtask?.ttid,
                                    description,
                                    notification.aecreatedby
                                ));
                        }
                    } else {
                        console.log(`You cannot start this task - task ID: ${notification.observationtaskid}`);

                        links.push(
                            <SlideFade in={!loading} offsetY="20px">
                                <Button
                                    w={{ base: "full", md: "unset" }}
                                    disabled
                                >
                                    <Icon
                                        mr={2}
                                        as={BsExclamationCircle}
                                        verticalAlign="middle"
                                    />
                                    You cannot start this task
                                </Button>
                            </SlideFade>
                        );
                    }
                } else {
                    links.push(getNotificationLinks(verb, id, description));
                }
            }
        }

        if (notification.canDismiss && !notification.cleared) {
            links.push(
                <Button
                    color="red.500"
                    borderColor="red.500"
                    variant="outline"
                    w={{ base: "full", md: "unset" }}
                    size="sm"
                    onClick={() => onOpen()}
                >
                    Clear Notification <Icon ml={3} as={BsTrashFill} />
                </Button>
            );
        }

        return links;
    };

    return (
        <Box m={{ base: 0, md: 5 }} height={{ base: "100%", md: "unset" }}>
            <ObbiAlertDialog
                isOpen={isOpen}
                onConfirm={() => { dismiss(notification.aeid) }}
                onClose={onClose}
                title="Clear notification?"
                message="Are you sure you want to clear this notification?"
            />

            <Button
                display={{ base: "none", md: "unset" }}
                w="fit-content"
                border="none"
                boxShadow="sm"
                mb={2}
                size="sm"
                color="gray.600"
                variant="outline"
                fontWeight={600}
                onClick={() => {
                    navigateTo("/notifications",
                        {
                            state: {
                                tabIndex: state?.tabIndex,
                                index: state?.index,
                                grouping: state?.grouping
                            }
                        });
                }}
            >
                <HStack alignItems="center">
                    <Icon as={BsArrowLeftCircleFill} />
                    <Text>Back to Notifications</Text>
                </HStack>
            </Button>

            <Box
                h={{ base: "full", md: "unset" }}
                p={{ base: 0, md: 5 }}
                pt={{ base: 5, md: 2 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                boxShadow={{ base: "none", md: "lg" }}
            >
                <Button
                    display={{ base: "unset", md: "none" }}
                    mx={3}
                    mt={2}
                    mb={4}
                    size="sm"
                    color="gray.600"
                    variant="link"
                    fontWeight={600}
                    leftIcon={<Icon as={BsArrowLeftCircleFill} />}
                    onClick={() => {
                        navigateTo("/notifications");
                    }}
                >
                    Back to Notifications
                </Button>

                {
                    isImageOpen &&
                    <Lightbox
                        mainSrc={activeImage}
                        onCloseRequest={() => setIsImageOpen(false)}
                    />
                }

                <VStack
                    display='flex'
                    alignItems='baseline'
                    m={5}
                    mb={0}
                >
                    <SimpleGrid
                        w="full"
                        columns={{ base: 1, xl: 3 }}
                        spacing={5}
                    >
                        {
                            notification !== null &&
                            <Box gridColumnStart={1} gridColumnEnd={3}>
                                <SlideFade in={!loading} offsetY="20px">
                                    <Text
                                        fontWeight={700}
                                        fontSize={'2xl'}
                                        color="gray.600"
                                    >
                                        {
                                            notification.aekind <= "3" &&
                                            <Icon
                                                color="red.500"
                                                fontSize="xl"
                                                mr={3}
                                                as={BsExclamationCircleFill}
                                            />
                                        }
                                        {title}
                                    </Text>
                                </SlideFade>

                                <VStack
                                    textAlign="left"
                                    display='flex'
                                    alignItems='baseline'
                                    spacing={5}
                                    mt={3}
                                >
                                    <SlideFade in={!loading} offsetY="20px">
                                        <HStack spacing={4} mb={5}>
                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.500"
                                            >
                                                {localDateTime(notification.aedatecreated)}
                                            </Text>

                                            <Text
                                                fontWeight={400}
                                                fontSize="sm"
                                                color="gray.500"
                                            >
                                                {notification.datecreatedago}
                                            </Text>
                                        </HStack>
                                    </SlideFade>

                                    <SlideFade in={!loading} offsetY="20px">
                                        <Text
                                            fontWeight={500}
                                            fontSize="sm"
                                            color="gray.600"
                                            wordBreak="break-word"
                                        >
                                            {notification.aetext}
                                        </Text>
                                    </SlideFade>

                                    <SlideFade in={!loading} offsetY="20px">
                                        <Text fontWeight={400} color="gray.500" mb={3}>
                                            {/* CHANGE TO MARKDOWN */}
                                            {
                                                notification.additionalhtml &&
                                                notification.additionalhtml.replace(/(<([^>]+)>)|([^\x00-\x7F])/gi, "")
                                            }
                                        </Text>
                                    </SlideFade>

                                    {
                                        attachments.length > 0 &&
                                        <Box mb={5}>
                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.600"
                                                mb={2}
                                            >
                                                Attachments
                                            </Text>

                                            <Box
                                                w="100%"
                                                p={5}
                                                bgColor="gray.50"
                                                borderRadius="8px"
                                            >
                                                <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={2}>
                                                    {
                                                        attachments.map((a: any) => {
                                                            return (
                                                                <Box
                                                                    cursor="pointer"
                                                                    className="hover-pop"
                                                                    boxShadow="md"
                                                                    rounded="md"
                                                                    bg="gray.700"
                                                                    onClick={() => {
                                                                        setActiveImage(a.thumbnail.datauri);
                                                                        setIsImageOpen(true);
                                                                    }}
                                                                >
                                                                    <Image
                                                                        src={a.thumbnail.datauri}
                                                                        alt={a.fileName}
                                                                        rounded="md"
                                                                        p={1}
                                                                        m="0 auto"
                                                                    />

                                                                    <HStack
                                                                        p={4}
                                                                        gap={5}
                                                                        justifyContent="space-between"
                                                                        wordBreak="break-all"
                                                                    >
                                                                        <Text color="white">
                                                                            {a.thumbnail.attachment.name}
                                                                        </Text>
                                                                    </HStack>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </SimpleGrid>
                                            </Box>
                                        </Box>
                                    }
                                </VStack>

                                <Stack mb={5} direction={{ base: "column", md: "row" }}>
                                    {
                                        notification.istgratingalert &&
                                        <VStack spacing={6}>
                                            <VStack spacing={3}>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="md"
                                                    color="gray.700"
                                                >
                                                    Please rate this training group.
                                                </Text>

                                                <Rating rating={rating} setRating={setRating} />
                                            </VStack>

                                            <Button
                                                disabled={rating === 0}
                                                w={{ base: "full" }}
                                                size="sm"
                                                colorScheme="brand"
                                                _hover={{
                                                    bg: rating > 0 ? "#248451" : "",
                                                }}
                                                onClick={() => { submitGroupRating() }}
                                            >
                                                Submit rating
                                                <Icon
                                                    ml={3}
                                                    as={BsArrowRightCircleFill}
                                                    verticalAlign="middle"
                                                />
                                            </Button>
                                        </VStack>
                                    }

                                    {getActionLinks()}
                                </Stack>
                            </Box>
                        }

                        <Box justifySelf="flex-end">
                            <Image display={{ base: "none", xl: "flex" }} src={waiting} />
                        </Box>
                    </SimpleGrid>
                </VStack>
            </Box>
        </Box>
    )
};