import {
    Box,
    HStack,
    Icon,
    Text,
    Button,
    VStack,
    Progress,
    FormControl,
    Select
} from "@chakra-ui/react";
import {
    BsBarChartFill,
    BsCheckCircleFill,
    BsExclamationCircleFill,
    BsListCheck,
    BsPlayBtn,
    BsPlayBtnFill,
} from "react-icons/bs";

import { useLocation, useNavigate } from "react-router-dom";
import { isNull } from "lodash";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import { localDateTimeIgnoreToday, localTime, relativeTime } from "../../helpers/DayJsHelper";
import _ from "lodash";

export const TaskSummaryCard: React.FC<any> = ({
    teamId,
    taskId,
    teamTaskId,
    schedule,
    progress,
    cardStatus,
    summary,
    startAttempt,
    startAttemptDisabled,
    latestAttempt,
    trainingIsValid,
    teamUsers,
    selectedUser,
    setSelectedUser,
    taskData,
    isManager
}) => {
    const navigateTo = useNavigate();
    const { state }: any = useLocation();

    const setCardText = () =>
        (taskData?.ttcanstartperiodictask === "1" && taskData?.pthcompletedtoday) ? "You've already completed this task for today!" :
            taskData?.ttcanstartperiodictask === "1" && isManager ? "This is a mandatory task for your team members to complete." :
                checkTraining()
                    ? "You're ready to start!"
                    : "Invalid training related to this task!";

    const handleClickThrough = async (path: string, id?: number) => {
        let url: string = await getClickthroughURL(path, id);
        window.open(url, "_self");
    };

    const checkTraining = () => {
        for (var key in cardStatus) {
            if (cardStatus.hasOwnProperty(key) && !cardStatus[key].valid) {
                return false;
            }
        }

        return true;
    };

    return (
        <Box rounded={"lg"}>
            <VStack alignItems="start" gap={1}>
                <HStack>
                    {
                        <>
                            {
                                !(taskData?.ttcanstartperiodictask === "1" && isManager) && <Icon
                                    as={checkTraining() ? BsCheckCircleFill : BsExclamationCircleFill}
                                    color={checkTraining() ? "brand.500" : "danger.500"}
                                />
                            }

                            <Text
                                color="gray.600"
                                fontWeight={600}
                                fontSize="lg"
                                textAlign="left"
                                alignSelf="start"
                            >
                                {setCardText()}
                            </Text>
                        </>
                    }
                </HStack>

                {
                    !schedule && <Progress
                        w="100%"
                        h="25px"
                        border="1px solid"
                        borderColor="gray.200"
                        value={progress?.percentagecomplete || 0}
                        colorScheme="brand"
                        backgroundColor="gray.50"
                        hasStripe
                    />
                }

                {
                    summary && <HStack>
                        <Icon as={BsListCheck} color="gray.500" fontSize="md" />
                        <Text fontWeight={500} fontSize="xs" color="gray.500" >
                            {progress?.attempteditemcount || 0} of{" "}
                            {progress?.itemcount || 0} items completed
                        </Text>
                    </HStack>
                }

                {
                    schedule && taskData?.ttcanstartperiodictask !== "1" && <Text
                        fontSize="sm"
                        color="gray.600"
                        fontWeight={400}
                        textAlign="start"
                    >
                        {schedule !== "Not scheduled" && !state?.navData?.isGuided ? schedule : ""}
                        {state?.navData?.isGuided && "Select the Team Member you would like to observe complete this task. Or pick up where you left off and resume an existing Observation."}
                    </Text>
                }

                {
                    taskData?.canstartperiodictask && taskData?.ttcanstartperiodictask === "1" && <Text
                        fontSize="sm"
                        color="gray.600"
                        fontWeight={400}
                        textAlign="start"
                    >
                        Due Today, {localTime(taskData?.periodictaskstarttime)} - {localTime(taskData?.periodictaskendtime)}
                    </Text>
                }

                {
                    schedule &&
                    state?.navData?.isGuided && <FormControl colorScheme="green">
                        <Select
                            _focus={{ borderColor: "brand.500", borderWidth: "1px" }}
                            color="gray.600"
                            mt={5}
                            size="sm"
                            rounded="lg"
                            value={selectedUser}
                            onChange={(e) => { setSelectedUser(e.target.value) }}
                            placeholder="Select a Team Member"
                            defaultValue={0}
                        >
                            {
                                teamUsers?.map((user: any, i: number) => {
                                    return <option key={i} value={user.uduid}>
                                        {user.udforename} {user.udsurname}
                                    </option>
                                })
                            }
                        </Select>
                    </FormControl>
                }

                {
                    !summary && <VStack
                        alignItems="start"
                        gap={1}
                        w="100%"
                    >
                        {
                            !isNull(latestAttempt) && !taskData?.canstartguidedtask && <Button
                                p={8}
                                w={{ base: "full" }}
                                colorScheme="brand"
                                variant="solid"
                                color="brand.500"
                                border="1px solid"
                                fontWeight={600}
                                borderColor="brand.500"
                                bg="transparent"
                                _hover={{
                                    bg: "",
                                    color: ""
                                }}
                                _active={{
                                    bg: "",
                                    color: ""
                                }}
                                onClick={() => {
                                    navigateTo(`/tasks/task/${latestAttempt?.ttaid || taskData?.currentperiod?.ptpid}`, {
                                        state: {
                                            id: latestAttempt?.ttaid || taskData?.currentperiod?.ptpid,
                                            cardStatus: trainingIsValid,
                                            teamTaskId: teamTaskId,
                                            teamId: teamId,
                                            taskId: taskId,
                                            navData: {
                                                ...state?.navData,
                                                isMandatory: taskData?.canstartperiodictask
                                            }
                                        }
                                    });
                                }}
                            >
                                <VStack>
                                    <HStack>
                                        <Text>Resume in Progress</Text>
                                        <Icon as={BsPlayBtn} />
                                    </HStack>
                                    <Text
                                        textAlign="left"
                                        fontWeight={400}
                                        fontSize="sm"
                                        color="gray.500"
                                    >
                                        Started {relativeTime(latestAttempt?.ttadatecreated)}, {localDateTimeIgnoreToday(latestAttempt?.ttadatecreated)}
                                    </Text>
                                </VStack>
                            </Button>
                        }

                        {
                            ((taskData?.canstartperiodictask && !taskData?.pthcompletedtoday) ||
                                (taskData?.canstartteamtask || taskData?.canstartguidedtask)) && <Button
                                    disabled={startAttemptDisabled}
                                    w={{ base: "full" }}
                                    size="md"
                                    colorScheme="brand"
                                    variant="solid"
                                    color="white"
                                    border="1px solid transparent"
                                    fontWeight={600}
                                    _hover={{
                                        bg: ""
                                    }}
                                    onClick={() => {
                                        startAttempt();
                                    }}
                                >
                                Start {!state?.navData?.isGuided && "New"} <Icon as={BsPlayBtnFill} ml={2} />
                            </Button>
                        }

                        {
                            (taskData?.ttcanstartperiodictask === "1" &&
                                isManager &&
                                !isNull(taskData?.currentperiod)) && <Button
                                    disabled={startAttemptDisabled}
                                    w={{ base: "full" }}
                                    size="md"
                                    colorScheme="brand"
                                    variant="solid"
                                    color="white"
                                    border="1px solid transparent"
                                    fontWeight={600}
                                    _hover={{
                                        bg: ""
                                    }}
                                    onClick={() => {
                                        handleClickThrough("TaskPeriod", taskData?.currentperiod?.ptpid);
                                    }}
                                >
                                View Reports <Icon as={BsBarChartFill} ml={2} />
                            </Button>
                        }
                    </VStack>
                }
            </VStack>
        </Box>
    );
};