import { useMemo } from "react";
import {
    Box,
    VStack,
    Text,
    Icon,
    HStack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Badge,
    SlideFade,
    Avatar
} from "@chakra-ui/react";
import { BsCheckCircleFill, BsExclamationCircleFill, BsHandThumbsUp } from "react-icons/bs";
import {
    useTable,
    useSortBy,
    usePagination
} from "react-table";
import 'react-image-lightbox/style.css';

import { Pager } from "../common/Pager";
import { localDateTime } from "../../helpers/DayJsHelper";

export const MessageTrackingTable: React.FC<any> = ({
    loading,
    message,
    currentItems,
    pageCount,
    index,
    setPageIndex
}) => {
    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "name",
                disableSortBy: true,
                Cell: (cell: any) => {
                    return <HStack>
                        <Avatar
                            name={cell.value || ""}
                            size="xs"
                            color="white"
                        />

                        <Text color="gray.700">{cell.value || "No Name"}</Text>
                    </HStack>
                }
            },
            {
                Header: "READ",
                accessor: "unread",
                disableSortBy: true,
                Cell: (cell: any) => {
                    if (cell.value.status === "1") {
                        return <Text>-</Text>
                    } else {
                        return <HStack>
                            <Icon
                                as={BsCheckCircleFill}
                                mt="-1px"
                                verticalAlign="middle"
                                color="brand.500"
                            />

                            <Text color="gray.500" fontSize="xs">
                                {localDateTime(cell.value.date)}
                            </Text>
                        </HStack>
                    }
                }
            },
            {
                Header: "ACKNOWLEDGED",
                accessor: "acknowledged",
                disableSortBy: true,
                Cell: (cell: any) => {
                    if (cell.value.status === "acknowledged") {
                        return <HStack>
                            <Icon
                                as={BsCheckCircleFill}
                                mt="-1px"
                                verticalAlign="middle"
                                color="brand.500"
                            />

                            <Text color="gray.500" fontSize="xs">
                                {localDateTime(cell.value.date)}
                            </Text>
                        </HStack>
                    } else if (cell.value.status === "not") {
                        return <Badge
                            colorScheme="danger"
                            backgroundColor="transparent"
                            size="sm"
                            variant="outline"

                        >
                            <Icon
                                as={BsExclamationCircleFill}
                                mt="-2px"
                                mr="2px"
                                verticalAlign="middle"
                            /> Required
                        </Badge>
                    } else {
                        return <Text>-</Text>
                    }
                }
            }
        ], []
    );

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount
        } = useTable({
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: false,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
            useSortBy, usePagination
        );

        return (
            <Box
                p={3}
                border="1px"
                borderColor="gray.200"
                rounded="lg"
            >
                <SlideFade in={!loading} offsetY="20px">
                    <VStack display={{ base: "flex", lg: "none" }}>
                        {
                            currentItems.map((recipient: any, i: number) => (
                                <Box
                                    w="full"
                                    p={3}
                                    backgroundColor={i % 2 ? "white" : "gray.50"}
                                    border="1px solid"
                                    borderColor="gray.200"
                                    rounded="lg"
                                >
                                    <VStack alignItems="start">
                                        <HStack>
                                            <Avatar
                                                name={recipient.name}
                                                color="white"
                                                size="xs"
                                            />

                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                                textAlign="start"
                                            >
                                                {recipient.name}
                                            </Text>
                                        </HStack>

                                        <HStack spacing={2}>
                                            {
                                                recipient.unread.status === "0" ?
                                                    <Badge
                                                        colorScheme="brand"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"

                                                    >
                                                        <Icon
                                                            as={BsCheckCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Read
                                                    </Badge> :

                                                    <Badge
                                                        colorScheme="danger"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"

                                                    >
                                                        <Icon
                                                            as={BsExclamationCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Unread
                                                    </Badge>
                                            }

                                            {
                                                message?.msgacknowledgementrequired === "1" ?
                                                    recipient.acknowledged === "1" ?
                                                        <Badge
                                                            colorScheme="brand"
                                                            backgroundColor="transparent"
                                                            size="sm"
                                                            variant="outline"

                                                        >
                                                            <Icon
                                                                as={BsHandThumbsUp}
                                                                mt="-2px"
                                                                mr="2px"
                                                                verticalAlign="middle"
                                                            /> Acknowledged
                                                        </Badge> :

                                                        <Badge
                                                            colorScheme="danger"
                                                            backgroundColor="transparent"
                                                            size="sm"
                                                            variant="outline"

                                                        >
                                                            <Icon
                                                                as={BsExclamationCircleFill}
                                                                mt="-2px"
                                                                mr="2px"
                                                                verticalAlign="middle"
                                                            /> Acknowledge
                                                        </Badge> :

                                                    <Badge
                                                        colorScheme="darkgray"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"

                                                    >
                                                        <Icon
                                                            as={BsCheckCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Not required
                                                    </Badge>
                                            }
                                        </HStack>
                                    </VStack>
                                </Box>
                            ))
                        }
                    </VStack>

                    <Table
                        id="MessageTrackingTable"
                        {...getTableProps()}
                        variant="striped"
                        display={{ base: "none", lg: "inline-table" }}
                    >
                        <Thead>
                            {
                                headerGroups.map(headerGroup => (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map(column => (
                                                <Th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    onClick={() => onHeaderClick(column)}
                                                >
                                                    <Text fontWeight={700} color="gray.600">{column.render("Header")}</Text>
                                                </Th>
                                            ))
                                        }
                                    </Tr>
                                ))
                            }
                        </Thead>

                        <Tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <Tr
                                            {...row.getRowProps()}
                                            key={i}
                                        >
                                            {
                                                row.cells.map(cell => {
                                                    return <Td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </Td>
                                                })
                                            }
                                        </Tr>
                                    );
                                })
                            }
                        </Tbody>
                    </Table>
                </SlideFade>

                <Box
                    mt={4}
                    mb={1}
                    textAlign="center"
                    display={pageCount > 1 ? "block" : "none"}
                >
                    <Pager
                        pageCount={pageCount}
                        pageIndex={index}
                        setPageIndex={setPageIndex}
                    />
                </Box>
            </Box>
        )
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount
    });
};