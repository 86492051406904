import React, { useState } from "react";
import ReactMarkdown from 'react-markdown';
import CSS from 'csstype';
import {
	Box,
	Button,
	Image,
	SimpleGrid,
	Skeleton,
	SlideFade,
	Stack,
	Text,
} from "@chakra-ui/react";
import Lightbox from "react-image-lightbox";

const titleStyles: CSS.Properties = {
	backgroundColor: "#37a169",
	fontWeight: "bold",
	lineHeight: "80px",
	color: "white",
	borderRadius: "8px",
	marginBottom: "15px"
};

const pictureStyles: CSS.Properties = {
	width: "100%",
	objectFit: "contain"
};

export interface questionInterface {
	csrf: string,
	type: string,
	layout: string,
	title: string,
	image: string,
	video: string,
	content: string,
	prompt: string,
	shuffle: string,
	maxChoices: number,
	orientation: string,
	choices: choiceInterface[]
};

export interface choiceInterface {
	identifier: string,
	image?: string,
	imageUri: any,
	text: string,
	isCorrect?: boolean,
	isSelected?: boolean
};

export const QuestionPlayer: React.FC<any> = ({
	question,
	choices,
	image,
	loading,
	updateData
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<SlideFade in={!loading} offsetY="20px">
				{
					isOpen && <Lightbox mainSrc={image} onCloseRequest={() => setIsOpen(false)} />
				}

				<Text
					style={titleStyles}
					fontSize={{ base: "lg", md: "17pt" }}
					textAlign="center"
				>
					{question.title}
				</Text>

				<Text
					mt={5}
					mb={12}
					fontWeight={500}
					fontSize="md"
					textAlign="left"
					color="gray.600"
					id="TrainingNotesMD"
				>
					<ReactMarkdown children={question.prompt} />
				</Text>
			</SlideFade>

			<SimpleGrid
				columnGap={5}
				columns={
					question.layout?.includes("LeftImageChoices") ||
						question.layout?.includes("RightImageChoices") ? 2 : 1
				}
			>
				{
					(image !== null && image !== "") &&
					<Image
						mb={question.layout?.includes("LeftImageChoices") ||
							question.layout?.includes("RightImageChoices") ? 0 : 5}
						src={image}
						alt={image}
						style={pictureStyles}
						height={{ base: "auto", md: "480px" }}
						cursor="pointer"
						onClick={() => setIsOpen(true)}
					/>
				}

				<Stack direction={question.orientation === "vertical" ? "column" : "row"} gap={2}>
					{
						question.layout?.includes("ImagesChoices") ?
							choices.map((choice: choiceInterface) => {
								return <SlideFade in={true} offsetY="20px">
									<Box
										key={choice.identifier}
										flex={1}
										maxW="300px"
										boxShadow={choice.isSelected ? "none" : "lg"}
										className={choice.isSelected ? "" : "hover-pop"}
										cursor={choice.isSelected ? "default" : "pointer"}
										border="3px solid"
										borderRadius="8px"
										borderColor={choice.isSelected ? "green.500" : "transparent"}
										color={choice.isSelected ? "white" : "gray.600"}
										_hover={{ bg: "" }}
										_active={{ bg: "" }}
										onClick={() => {
											updateData(
												choice.identifier,
												question.csrf
											);
										}}
									>
										{
											loading ? <Skeleton /> : <Image src={choice.imageUri} rounded="md" />
										}

									</Box>
								</SlideFade>
							}) :

							choices.map((choice: choiceInterface) => {
								return <Button
									key={choice.identifier}
									p={3}
									id="TrainingAnswerMD"
									minH="60px"
									h="fit-content"
									className={choice.isSelected ? "" : "hover-pop"}
									display="inline-grid"
									justifyContent="start"
									textAlign="start"
									bg={choice.isSelected ? "white" : "gray.50"}
									border={choice.isSelected ? "3px solid" : "3px transparent"}
									borderColor="brand.500"
									borderLeft={choice.isSelected ? "trasparent" : "3px solid"}
									borderLeftColor="brand.500"
									color="gray.600"
									fontSize={{ base: "xs", md: "md" }}
									fontWeight={600}
									whiteSpace="pre-line"
									cursor={choice.isSelected ? "default" : "pointer"}
									boxShadow={choice.isSelected ? "none" : "sm"}
									_hover={{ bg: "" }}
									_active={{ bg: "" }}
									onClick={() => {
										updateData(
											choice.identifier,
											question.csrf
										);
									}}
								>
									<ReactMarkdown children={choice.text} />
								</Button>
							})
					}
				</Stack>
			</SimpleGrid>
		</>
	);
}