import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { authReducer } from '../features/auth/auth.slice';
import { messageReducer } from '../features/messages/message.slice';
import { notificationReducer } from '../features/notifications/notification.slice';

const combinedReducers = combineReducers({
    authReducer,
    messageReducer,
    notificationReducer
});

const rootReducer = (state: RootState, action: AnyAction) => {
    if (action.type === "RESET") {
        state = undefined;
    }

    return combinedReducers(state, action);
}

export type RootState = ReturnType<any>;

export default rootReducer;