import { Box, BoxProps } from '@chakra-ui/react';

export const Card = (props: BoxProps) => (
	<Box
		bg="white"
		maxWidth="2xl"
		p={{ base: 6, md: 8 }}
		rounded="lg"
		shadow="lg"
		{...props}
	/>
);