import { get, getWithParams } from "../../api/axiosClient";

export async function getComplianceRecommendations(
    category: string = "",
    page: number = 0,
    pagesize: number = 6
) {
    let recommendations: any = {};

    await getWithParams("recommendations/all", { category, page, pagesize })
        .then((res: any) => {
            recommendations = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return recommendations;
}

export async function getTasks(team: number, filter?: string): Promise<any> {
    let taskResult: any = {
        mandatoryTasks: [],
        tasks: [],
        teams: [],
        counts: {
            overdueTaskCount: 0,
            tasksDueTodayCount: 0,
            tasksDueThisWeekCount: 0
        }
    };

    await getWithParams("tasks/all", { team, filter, page: 1, pagesize: 6, mustHaveCanStartTeamTask: true })
        .then((res: any) => {
            taskResult.mandatoryTasks = res.data.availablePeriodicTasks;
            taskResult.tasks = res.data.tasks;
            taskResult.teams = res.data.teamMemberships;
            taskResult.counts = {
                overdueTaskCount: res.data.overdueTaskCount,
                tasksDueTodayCount: res.data.tasksDueTodayCount,
                tasksDueThisWeekCount: res.data.tasksDueThisWeekCount
            };
        })
        .catch((error: any) => {
            throw error;
        });

    return taskResult;
}

// Deprecated
export async function getComplianceStatus() {
    let statusResult = {
        "compliance": "",
        "training": "",
        "trainingDangerCount": "",
        "trainingInfoCount": "",
        "tasks": "",
        "tasksDangerCount": "",
        "tasksInfoCount": "",
        "actions": "",
        "actionsDangerCount": "",
        "actionsWarningCount": ""
    };

    await get("recommendations/status")
        .then((res: any) => {
            statusResult.compliance = res.data.status.compliance;
            statusResult.training = res.data.status.training;
            statusResult.trainingInfoCount = res.data.status.coursesAboutToExpireCount;
            statusResult.trainingDangerCount = res.data.status.coursesExpiredCount + res.data.status.coursesFailedCount;
            statusResult.tasks = res.data.status.tasks;
            statusResult.tasksInfoCount = res.data.status.tasksDueTodayCount;
            statusResult.tasksDangerCount = res.data.status.overdueTasksCount;
            statusResult.actions = res.data.status.actions;
            statusResult.actionsDangerCount = res.data.status.overdueActionsCount;
            statusResult.actionsWarningCount = res.data.status.actionsNearlyDueCount;
        })
        .catch((error: any) => {
            throw error;
        });

    return statusResult;
}

// Deprecated
export async function getComplianceCategories() {
    let categories: any[] = [];

    await get("recommendations/categories")
        .then((res: any) => {
            categories.push(res.data.categories.all);
            categories.push(res.data.categories.training);
            categories.push(res.data.categories.incidents);
            categories.push(res.data.categories.tasks);
            categories.push(res.data.categories.documents);
            categories.push(res.data.categories.actions);
        })
        .catch((error: any) => {
            throw error;
        });

    return categories;
}

export async function getActionInsights() {
    let insights: any[] = [];

    await get("actions/insights")
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}

export async function getTrainingInsights() {
    let insights: any[] = [];

    await get("training/insights")
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}

export async function getDocumentInsights() {
    let insights: any[] = [];

    await get("documents/insights")
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}

export async function getCertificateInsights() {
    let insights: any[] = [];

    await get("certificates/insights")
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}

export async function getIncidentInsights() {
    let insights: any[] = [];

    await get(`incidents/insights`)
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}

export async function getTaskInsights() {
    let insights: any[] = [];

    // TODO: update endpoint to bring back additional fields below

    // TESTING - Remove when API is ready
    // return {
    //     "inProgressCount": 4,
    //     "hasInProgressObservations": true,
    //     "requiredCount": 2,
    //     "hasRequiredObservations": true,
    //     "user": undefined,
    //     "elapsedTimeSeconds": 0.00933384895324707,
    //     "elapsedTimeHuman": "0s",
    //     "result": "ok",
    //     "error": 0,
    //     "success": true
    // }

    await get("tasks/insights")
        .then((res: any) => {
            insights = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return insights;
}