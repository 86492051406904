import { useEffect, useState } from "react";
import {
	Box,
	VStack,
	SimpleGrid,
	Text,
	Container,
	Divider,
	Icon,
	HStack,
	Image,
	useToast,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Button,
	Center,
	Spacer,
	Spinner,
	Avatar,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import { BsExclamationCircleFill, BsImages } from "react-icons/bs";

import { getDraftAttachment } from "../../features/messages/message.actions";

export const CreateStepFive: React.FC<any> = ({
	id,
	selectedContacts,
	selectedTeams,
	priority,
	subject,
	message,
	attachments,
}) => {
	const toast = useToast();
	const [isOpen, setIsOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState("");

	// PDF pages
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const [currentPdf, setCurrentPdf] = useState({
		id: 0,
		uri: "",
		fileName: ""
	});
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const renderImage = () => {
		return <Modal
			size="2xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false); }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader />
				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>
				<ModalBody>
					<Image
						src={currentImage}
						m="5px auto 15px auto"
						p={0}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentPdf.fileName}</Text>

						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>

						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>

						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentPdf.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	return (
		<Box
			height={{ base: "100vh", md: "unset" }}
			m={{ base: 0, md: 5 }}
			mb={8}
		>
			<VStack
				mt={3}
				mb={{ base: 0, md: 5 }}
				display="flex"
				alignItems="baseline"
			>
				<SimpleGrid w="full" columns={2} spacing={5}>
					<VStack alignItems="start">
						{
							selectedTeams.length > 0 &&
							<Text fontWeight={500} fontSize="md" color="gray.500">
								Team Distribution
							</Text>
						}

						{
							selectedTeams.map((team: any) => {
								return (
									<HStack>
										<Avatar
											name={team.teamname}
											size="xs"
											color="white"
										/>

										<Text fontWeight={500} fontSize="sm" color="gray.600">
											{team.teamname}
										</Text>
									</HStack>
								);
							})
						}

						{
							selectedContacts.length > 0 && <Text
								fontWeight={500}
								fontSize="md"
								color="gray.500"
								pt={selectedTeams.length > 0 ? 3 : 0}
							>
								Individual Distribution
							</Text>
						}

						{
							selectedContacts.map((contact: any) => {
								return (
									<HStack>
										<Avatar
											name={`${contact.udforename} ${contact.udsurname}`}
											size="xs"
											color="white"
										/>

										<Text fontWeight={500} fontSize="sm" color="gray.600">
											{`${contact.udforename} ${contact.udsurname}`}
										</Text>
									</HStack>
								);
							})
						}
					</VStack>

					<Box>
						<VStack alignItems="start">
							<Text fontWeight={500} fontSize="md" color="gray.500">
								Priority
							</Text>

							{
								<HStack>
									{
										priority && <Icon as={BsExclamationCircleFill} color="danger.500" />
									}
									
									<Text
										fontWeight={500}
										fontSize="sm"
										color="gray.600"
									>
										{priority ? "Critical" : "Normal"}
									</Text>
								</HStack>
							}
						</VStack>
					</Box>
				</SimpleGrid>

				<Box w="full" py={5}>
					<Divider />
				</Box>

				<SimpleGrid w="full" columns={2} spacing={5}>
					<VStack alignItems="start">
						<Text fontWeight={500} fontSize="md" color="gray.500">
							Title
						</Text>
						<Text
							fontWeight={500}
							fontSize="sm"
							color="gray.600"
							wordBreak="break-word"
						>
							{subject}
						</Text>
					</VStack>

					<VStack
						textAlign="left"
						display="flex"
						alignItems="baseline"
						spacing={3}
					>
						<Text
							fontWeight={500}
							fontSize="md"
							color="gray.500"
						>
							Message
						</Text>
						<Text
							fontWeight={500}
							fontSize="sm"
							color="gray.600"
							wordBreak="break-word"
							whiteSpace="pre-line"
						>
							{message}
						</Text>
					</VStack>
				</SimpleGrid>

				{
					attachments.length > 0 && <>
						<Divider
							w="full"
							display={{
								base: "none",
								md: attachments.length > 0 ? "flex" : "none",
							}}
						/>

						<Container w={{ base: "full" }} py={5}>
							<VStack textAlign="left" display="flex" alignItems="baseline">
								<HStack>
									<Icon
										as={BsImages}
										fontSize="xs"
										color="gray.500"
									/>
									<Text
										fontWeight={500}
										fontSize="md"
										color="gray.500"
									>
										Attachments
									</Text>
								</HStack>

								<Grid
									templateColumns={{ base: 'repeat(5, 1fr)', md: 'repeat(5, 5fr)' }}
									alignItems="start"
									gap={{ base: 2, lg: 5 }}
									mt={3}
								>
									{
										attachments.map((a: any) => {
											return (
												<Box minW="170px">
													<Image
														className="hover-pop"
														cursor="pointer"
														src={a.thumbnail}
														fallbackSrc='https://via.placeholder.com/160'
														alt={a.fileName}
														boxSize='160px'
														boxShadow="xs"
														objectFit="cover"
														border="1px solid"
														borderColor="gray.200"
														rounded="lg"
														onClick={async () => {
															await getDraftAttachment(id, a.path, 0, 1)
																.then((res: any) => {
																	if (a.isPdf) {
																		setCurrentPdf({
																			id: Number(id),
																			fileName: a.fileName,
																			uri: res?.datauri
																		});
																		setPdfIsOpen(true);
																	} else {
																		setCurrentImage(res.datauri);
																		setIsOpen(true);
																	}
																})
																.catch((error: any) => {
																	console.log(error);

																	toast({
																		title: "Error",
																		description: "Cannot download this attachment",
																		status: 'error',
																		duration: 6000,
																		isClosable: true,
																	});
																});
														}}
													/>
												</Box>
											)
										})
									}
								</Grid>
							</VStack>
						</Container>
					</>
				}
			</VStack>

			{renderImage()}
			{renderPdf()}
		</Box>
	);
};