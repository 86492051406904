import {
    configureStore,
    getDefaultMiddleware,
    Action
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';

import rootReducer, { RootState } from "./rootReducer";

const ignoredActions = {
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
};

const middleware = [...getDefaultMiddleware(ignoredActions), logger, thunk];
const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    middleware
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;

export const useAppDispatch = () => useDispatch();

export default store;