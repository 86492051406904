import {
	Avatar,
	Box,
	Center,
	FormControl,
	HStack,
	Icon,
	Input,
	SlideFade,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useToast,
	VStack,
} from "@chakra-ui/react";
import {
	BsArrowDown,
	BsArrowUp,
	BsConeStriped,
} from "react-icons/bs";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Pager } from "../common/Pager";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import { useLocation } from "react-router-dom";
import { getIncidentForms, getIncidents } from "../../features/incidents/incidents.actions";
import { useDebounce } from "../common/useDebounce";
import { isEmpty } from "lodash";
import { getCurrentTeams } from "../../features/common/common.actions";
import { SmallFilterCard } from "../common/SmallFilterCard";
import { Form } from "../../types/Form";
import { TeamCombobox } from "../common/TeamCombobox";
import { localDateTime, localDateTimeIgnoreToday } from "../../helpers/DayJsHelper";

export const IncidentHistory: React.FC<any> = ({
	filter,
	clearAllFilters,
	setClearAllFilters,
	setFilterSelected
}) => {
	const { state }: any = useLocation();
	const [currentItems, setCurrentItems] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [search, setSearch] = useState<string>("");
	const [sort, setSort] = useState<string>("dateCreatedDesc");
	const [error, setError] = useState<string>('');

	const [pagerIndex, setPagerIndex] = useState(
		state !== null && state.pagerIndex ? state.pagerIndex : 0
	);

	const [pageCount, setPageCount] = useState(
		state !== null && state.pageCount ? state.pageCount : 0
	);

	const [teams, setTeams] = useState<any>([]);
	const [selectedTeam, setSelectedTeam] = useState<any>();
	const [selectedFormName, setSelectedFormName] = useState<any>();
	const [selectedFormId, setSelectedFormId] = useState<any>();
	const [forms, setForms] = useState<any>([]);

	const toast = useToast();

	const getForms = useCallback(async () => {
		try {
			const forms = await getIncidentForms();

			if (!forms) {
				console.error('No forms found');

				return;
			}

			const incidentForms: Form[] = forms?.forms;

			setForms(incidentForms);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const getTeams = useCallback(async () => {
		try {
			const teamsList: any = await getCurrentTeams();

			if (!teamsList?.manages) {
				console.error('No teams found.');

				return;
			}

			setTeams(teamsList?.manages);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const fetchData = useCallback(
		async ({
			filter,
			search,
			sort,
			selectedTeam,
			selectedFormId,
			pagerIndex,
		}) => {
			setLoading(true);

			pagerIndex = (pagerIndex + 1).toString();

			await getIncidents(
				filter,
				search,
				sort,
				selectedTeam ?? 0,
				selectedFormId ?? 0,
				pagerIndex
			)
				.then((res: any) => {
					setCurrentItems(res.incidents);
					setPageCount(res.pageCount);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
		}, []
	);

	useEffect(() => {
		fetchData({
			filter,
			search,
			sort,
			selectedTeam,
			selectedFormId,
			pagerIndex
		});
	}, [
		fetchData,
		selectedTeam,
		selectedFormId,
		pagerIndex,
		sort
	]);

	useEffect(() => {
		getTeams();
		getForms();
	}, []);

	useEffect(() => {
		const filterInEffect =
			(selectedTeam !== 0 && selectedTeam !== "0" && selectedTeam !== undefined) ||
			(selectedFormId !== 0 && selectedFormId !== undefined) ||
			(search !== "");

		setFilterSelected(filterInEffect);
	}, [search, selectedTeam, selectedFormId]);

	useEffect(() => {
		if (clearAllFilters) {
			setSearch("");
			setSelectedTeam(0);
			setSelectedFormId(0);
			setSelectedFormName("");
			setPagerIndex(0);
			setClearAllFilters(false);
			setFilterSelected(false);
		}
	}, [clearAllFilters]);

	const debouncedRequest = useDebounce(() => {
		setPagerIndex(0);

		fetchData({
			filter,
			search,
			sort,
			selectedTeam,
			'pagerIndex': 0
		});
	}, 300);

	const onChange = (e: any) => {
		setSearch(e.target.value);

		if (/[^a-zA-Z0-9\s]+/.test(e.target.value)) {

			setError('Search text contains characters that are not allowed.');

			toast({
				title: "Search text contains characters that are not allowed.",
				description: "",
				status: "error",
				duration: 3000,
				isClosable: false
			});

			return;

		} else {
			setError('');
		}

		debouncedRequest();
	};

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "tiid",
				disableSortBy: true,
			},
			{
				Header: "INCIDENT",
				accessor: "tiname",
				sortType: "basic",
				Cell: (cell: any) => <HStack>
					<Text
						color="gray.600"
						fontSize="sm"
					>
						{cell.value || "Untitled"}
					</Text>
				</HStack>
			},
			{
				Header: <Fragment>
					CREATED {
						sort === "dateCreated" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "dateCreatedDesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<Fragment />
					}
				</Fragment>,
				accessor: "tidatecreated",
				sortType: "basic",
				Cell: (cell: any) => {
					return (
						<VStack alignItems="left">
							<Text
								color="gray.600"
								fontSize="sm"
							>
								{localDateTimeIgnoreToday(cell.value)}
							</Text>
						</VStack>
					);
				}
			},
			{
				Header: <Fragment>
					LAST UPDATED {
						sort === "lastModified" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "lastModifiedDesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<Fragment />
					}
				</Fragment>,
				accessor: "tidatelastmodified",
				sortType: "basic",
				Cell: (cell: any) => {
					return (
						<VStack alignItems="left">
							<Text
								color="gray.600"
								fontSize="sm"
							>
								{localDateTimeIgnoreToday(cell.value)}
							</Text>
						</VStack>
					);
				}
			},
			{
				Header: <Fragment>
					TEAM {
						sort === "team" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "teamDesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<Fragment />
					}
				</Fragment>,
				accessor: "teamname",
				sortType: "basic",
				Cell: (cell: any) => <Text
					color="gray.600"
					fontSize="sm"
				>
					{cell.value}
				</Text>
			}
		], [sort]
	);

	const sortColumn = (term: string) => {
		switch (term) {
			case "tidatecreated":
				if (sort === "dateCreated") {
					setSort("dateCreatedDesc");
				} else if (sort === "dateCreatedDesc") {
					setSort("dateCreated");
				} else setSort("dateCreated");
				break;
			case "tidatelastmodified":
				if (sort === "lastModified") {
					setSort("lastModifiedDesc");
				} else if (sort === "lastModifiedDesc") {
					setSort("lastModified");
				} else setSort("lastModified");
				break;
			case "teamname":
				if (sort === "team") {
					setSort("teamDesc");
				} else if (sort === "teamDesc") {
					setSort("team");
				} else setSort("team");
				break;
		}
	};

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
			pageCount,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["tiid"]
				},
			},
			useSortBy,
			usePagination
		);

		const handleClickThrough = async (path: string, id: number) => {
			let url: string = await getClickthroughURL(path, id);
			window.open(url, "_self");
		};

		return (
			<>
				<SlideFade in={!firstTimeLoading}>

					{/* incident type filter */}
					{
						forms.length > 0 && <Box
							p={1.5}
							bg="gray.50"
							textAlign="left"
							rounded="lg"
							border="1px solid"
							borderColor="gray.100"
						>
							<Text
								fontWeight={500}
								color="gray.500"
								fontSize="sm"
								ml={1}
								mb={2}
							>
								Incident Type
							</Text>

							<SlideFade in={!firstTimeLoading}>
								{
									forms.map((form: any, i: Number) => {
										return (
											<SmallFilterCard
												key={i}
												title={form.tifname}
												onClickAction={() => {
													form.tifname !== selectedFormName
														? setSelectedFormName(form.tifname)
														: setSelectedFormName("");

													setSelectedFormId(form.tifid === selectedFormId ? 0 : form.tifid)
												}}
												selectedCard={selectedFormName}
												icon={BsConeStriped}
												iconColor="brand.500"
											/>
										);
									})
								}
							</SlideFade>
						</Box>
					}

					{/* search bar */}
					<Stack
						direction={{ base: "column", lg: "row" }}
						mt={
							forms.length > 0 && forms.length <= 7 ? 2 :
								forms.length > 7 ? 0 : 5
						}
					>
						<FormControl
							w={filter === 'managed' && teams?.length > 1 ? { base: "full", lg: "50%" } : { base: "full", lg: "50%" }}>
							<Input
								placeholder="Search Incidents"
								fontSize="sm" value={search}
								onChange={onChange}
							/>
							{error &&
								<Text
									mt={2}
									ml={2}
									color={'red.500'}
									fontSize="sm"
								>
									{error}
								</Text>
							}
						</FormControl>

						{/* team filter */}
						{
							(filter === 'managed' && teams?.length > 1) &&
							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }} hidden={isEmpty(teams)}>
								<TeamCombobox
									teams={teams || []}
									update={(value: any) => {
										setPagerIndex(0);
										setSelectedTeam(value);
									}}
									selectedTeam={selectedTeam}
								/>
							</FormControl>
						}
					</Stack>
				</SlideFade>

				<Box
					mt={5}
					p={3}
					border="1px"
					borderRadius="12px"
					borderColor="gray.200"
					hidden={firstTimeLoading}
				>
					{
						currentItems?.length > 0 ?
							<>
								{/* mobile view */}
								<VStack display={{ base: "flex", lg: "none" }}>
									{
										currentItems.map((incident: any, i: number) => (
											<Box
												key={i}
												w="full"
												p={2}
												className="hover-pop"
												cursor="pointer"
												backgroundColor={i % 2 ? "white" : "gray.50"}
												border="1px solid"
												borderColor="gray.100"
												borderRadius="8px"
												boxShadow="xs"
												onClick={() => {
													handleClickThrough("Incident", incident?.tiid)
												}}
											>
												<VStack alignItems="start">
													<Text
														fontSize="sm"
														fontWeight={500}
														color="gray.600"
														textAlign="start"
													>
														{incident.tiname || "Untitled"}
													</Text>
													<Text
														fontSize="xs"
														fontWeight={400}
														color="gray.500"
														textAlign="start"
													>
														Created: {localDateTime(incident.tidatecreated)}
													</Text>
													<Text
														fontSize="xs"
														fontWeight={400}
														color="gray.500"
														textAlign="start"
													>
														Last modified: {localDateTime(incident.tidatelastmodified)}
													</Text>

													<HStack>
														<Avatar name={incident.teamname} size="xs" />
														<Text
															fontSize="sm"
															fontWeight={400}
															color="gray.500"
															textAlign="start"
														>
															{incident.teamname}
														</Text>
													</HStack>
												</VStack>
											</Box>
										))}
								</VStack>

								{/* desktop view */}
								<Table
									{...getTableProps()}
									id="IncidentsTable"
									variant="striped"
									display={{ base: "none", lg: "inline-table" }}
								>
									<Thead>
										{headerGroups.map((headerGroup) => (
											<Tr {...headerGroup.getHeaderGroupProps()}>
												{
													headerGroup.headers.map((column) => (
														<Th
															{...column.getHeaderProps(column.getSortByToggleProps())}
															onClick={() => onHeaderClick(column)}
														>
															<Text fontWeight={700} color="gray.600">
																{column.render("Header")}
															</Text>
														</Th>
													))
												}
											</Tr>
										))}
									</Thead>
									<Tbody {...getTableBodyProps()}>
										{
											page.map((row, i) => {
												prepareRow(row);
												return (
													<Tr
														{...row.getRowProps()}
														className="hover-pop"
														key={i}
														borderBottom="1px solid"
														borderColor={"gray.100"}
														cursor="pointer"
														rounded="lg"
														onClick={() => {
															handleClickThrough("Incident", row.values?.tiid)
														}}
													>
														{row.cells.map((cell) => {
															return (
																<Td wordBreak="break-word" {...cell.getCellProps()}>
																	{cell.render("Cell")}
																</Td>
															);
														})}
													</Tr>
												);
											})
										}
									</Tbody>
								</Table>

								{/* page control */}
								<Box
									mt={3}
									textAlign="center"
									display={pageCount > 1 ? "block" : "none"}
								>
									<Pager
										pageCount={pageCount}
										pageIndex={pagerIndex}
										setPageIndex={setPagerIndex}
									/>
								</Box>
							</> :

							<SlideFade in={!loading} offsetY="20px">
								<Center m={5}>
									<VStack>
										<Text fontSize="xl" fontWeight={600}>
											No Incidents Found
										</Text>
										<Text fontWeight={400} color="gray.500">
											You have no incidents available
										</Text>
									</VStack>
								</Center>
							</SlideFade>
					}
				</Box>
			</>
		);
	}

	return DataTable({
		data: currentItems ?? [],
		columns,
		loading,
		pageCount,
		onHeaderClick: (c: any) => sortColumn(c.id)
	});
};
