import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from "@chakra-ui/react";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import '@fontsource/inter/variable.css';

import { store, persistor } from "./redux/store";
import Router from './Router';
import { obbiTheme } from './obbiTheme';

TimeAgo.addDefaultLocale(en);

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ChakraProvider theme={obbiTheme}>
					<Router />
				</ChakraProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;