import { get, getWithParams, patch, post, _delete } from "../../api/axiosClient";

export async function getActions(
    sort: string = "",
    filter: string = "",
    state: string,
    team: string,
    category: string,
    search: string,
    page: string,
    pagesize: string = ""
) {
    let actions: any = [];

    await getWithParams("actions/all", { sort, filter, state, team, category, search, page, pagesize })
        .then((res: any) => {
            actions = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return actions;
}

export async function getAction(id: string) {
    let action: any = {};

    await getWithParams("actions/action", { id })
        .then((res: any) => {
            action = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return action;
}

export async function createAction(actionBody: any) {
    let action: any = {};

    await post(`actions/action`, actionBody)
        .then((res: any) => {
            action = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return action;
}

export async function updateAction(
    id: string,
    name: string,
    value: string
) {
    let action: any = {};

    await patch(`actions/action`, {
        id: Number(id),
        name: name,
        value: value
    })
        .then((res: any) => {
            action = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return action;
}

export async function selectTeam(e: any, createActionBody: any) {
    let current = createActionBody;
    const updated = Object.assign(
        current,
        { team: e.teamid }
    );
    return updated;
}

export async function reopenAction(id: string) {
    let action: any = {};

    await post(`actions/reopen`, { id: Number(id) })
        .then((res: any) => {
            action = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return action;
}

export async function addNote(id: string, note: string) {
    let noteResult: any = {};

    await post("actions/note", { action: Number(id), text: note })
        .then((res: any) => {
            noteResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return noteResult;
}

export async function getAttachment(
    id: string,
    attachment: string,
    pdf: boolean
) {
    let result: any = {};

    await getWithParams("/actions/attachment", {id, attachment, pdf, datauri: true, thumbnail: false })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function addAttachment(attachmentData: any) {
    let result: any = {};

    await post("actions/attachment", attachmentData)
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function deleteActionAttachment(id: string, attachment: any) {
    let result: any = {};

    await _delete("actions/attachment", { id, attachment })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getCategories() {
    let result: any = [];

    await get("/actions/availableCategories")
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}