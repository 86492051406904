import { get, getWithParams } from "../../api/axiosClient";

export async function getCurrentTeams() {
    let teams = "";

    try {
        const result: any = await get("admin/myTeams");
        if (result.data.success) {
            teams = result.data;
        }
    } catch (error: any) {
        console.log(error);
    }

    return teams;
};

export async function getCurrentTeamMembers(id: string) {
    let users = "";

    await getWithParams("admin/teamMembers", { id })
        .then((res: any) => {
            if (res?.data?.success) {
                users = res.data.members.concat(res.data.user);
            }
        })
        .catch((error: any) => {
            console.log(error);
        })


    return users;
};