import store from "../../redux/store";
import {
    _delete,
    get,
    getWithParams,
    post,
    postAttachment,
    put
} from "../../api/axiosClient"

import { getMessageFoldersSuccess, getMessageFoldersFailure } from "./message.slice";

import Asset from "../../types/Asset";
import Location from "../../types/Location";
import MessageDraft from "../../types/MessageDraft";
import MessageFolders from "../../types/MessageFolders";

export async function getMessageFolders() {
    let folders: MessageFolders = {
        inboxCount: 0,
        unreadCount: 0,
        draftCount: 0,
        sentCount: 0,
        unreadCriticalCount: 0,
        userFolders: []
    };

    await get("messages/folders")
        .then((res: any) => {
            folders.inboxCount = res.data.unreadCount;
            folders.draftCount = res.data.draftCount;
            folders.sentCount = res.data.sentCount;
            folders.unreadCount = res.data.unreadCount - res.data.unreadCriticalCount;
            folders.unreadCriticalCount = res.data.unreadCriticalCount;

            store.dispatch(getMessageFoldersSuccess(folders));
        })
        .catch((error: any) => {
            store.dispatch(getMessageFoldersFailure(folders));

            throw error;
        })

    return folders;
}

export async function getInbox(
    sort: string,
    filter: string,
    kind: string,
    page: string,
    pagesize: string = "6"
) {
    let messageResult = {
        "pageCount": 0,
        "messages": []
    };

    await getWithParams("messages/all", { sort, filter, kind, page, pagesize })
        .then((res: any) => {
            messageResult.pageCount = res.data.pageCount;
            messageResult.messages = res.data.messages;
        })
        .catch((error: any) => {
            throw error;
        });

    return messageResult;
}

export async function getUnread(
    sort: string,
    page: string,
    pagesize: string = "6"
) {
    let messages: any[] = [];

    await getWithParams("messages/unread", { sort, page, pagesize })
        .then((res: any) => {
            messages = res.data.messages;
        })
        .catch((error: any) => {
            throw error;
        });

    return messages;
}

export async function getDrafts(
    sort: string,
    page: string,
    pagesize: string = "6"
) {
    let messages: any[] = [];

    await getWithParams("messages/drafts", { sort, page, pagesize })
        .then((res: any) => {
            messages = res.data.messages;
        })
        .catch((error: any) => {
            throw error;
        });

    return messages;
}

export async function getSent(
    sort: string,
    page: string,
    pagesize: string = "6"
) {
    let messages: any[] = [];

    await getWithParams("messages/sent", { sort, page, pagesize })
        .then((res: any) => {
            messages = res.data.messages;
        })
        .catch((error: any) => {
            throw error;
        });

    return messages;
}

export async function getDraft(id: number) {
    let message: {} = {};

    await getWithParams("messages/draft", { id })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function saveNewDraft(draft: MessageDraft) {
    let message: {} = {};

    await post("messages/draft", draft)
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function saveDraft(draft: MessageDraft) {
    return (
        await put("messages/draft", draft)
            .then((res) => { })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function getMessage(id: number) {
    let message: {} = {};

    await getWithParams("messages/message", { id })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function getSentMessage(id: number) {
    let message: {} = {};

    await getWithParams("messages/sentMessage", { id })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function sendMessage(id: Number) {
    return (
        await post("messages/send", { id })
            .then((res: any) => { })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function deleteDraft(id: string) {
    return (
        await _delete("messages/draft", { id })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function getContacts() {
    let contacts: any = [];

    await get("admin/allUsers")
        .then((res: any) => {
            contacts = res.data.users;
        })
        .catch((error: any) => {
            console.log(error);

            throw error;
        });

    return contacts;
}

export async function getTeams() {
    let teams: any = [];

    await get("admin/allTeams")
        .then((res: any) => {
            teams = res.data.teams;
        })
        .catch((error: any) => {
            console.log(error);

            throw error;
        });

    return teams;
}

export async function getLocations() {
    let locations: Location[] = [];

    await get("messages/locations")
        .then((res: any) => {
            locations = res.data.locations
        })
        .catch((error: any) => {
            console.log(error);
            throw error;
        });

    return locations;
}

export async function getAssets() {
    let assets: Asset[] = [];

    await get("messages/assets")
        .then((res: any) => {
            assets = res.data.assets
        })
        .catch((error: any) => {
            console.log(error);
            throw error;
        });

    return assets;
}

export async function postMessageAttachment(formData: FormData) {
    let attachment: any = null;

    await postAttachment("messages/draftAttachment", formData)
        .then((res: any) => {
            attachment = {
                thumbnail: res.data.datauri,
                fileName: res.data.attachment.matfilename,
                path: res.data.attachment.matpath,
                isPdf: res.data.attachment.ispdf || res.data.attachment.isdoc
            };
        })
        .catch((error: any) => {
            throw error;
        });

    return attachment;
}

export async function deleteMessageAttachment(id: string, attachment: string) {
    return (
        await _delete("messages/draftAttachment", { id, attachment })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function getDraftAttachment(
    id: string,
    attachment: string,
    thumbnail: Number = 0,
    datauri: Number
) {
    let result: any = null;

    await getWithParams("messages/draftAttachment", { id, attachment, thumbnail, datauri })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getMessageAttachment(
    id: string,
    attachment: string,
    thumbnail: Number = 0,
    datauri: Number
) {
    let result: any = null;

    await getWithParams("messages/attachment", { id, attachment, thumbnail, datauri })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function acknowledgeMessage(id: number) {
    let message: any = null;

    await post("messages/acknowledge", { id })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function markAsRead(id: number) {
    return (
        await post("messages/markAsRead", { id })
            .then((res: any) => { })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function getMessageTracking(
    id: number,
    filter: string,
    page: number,
    pagesize: string = "6"
) {
    let messages: any[] = [];

    await getWithParams("messages/sentMessage", { id, pagesize, page, filter })
        .then((res: any) => {
            messages = res.data.message.tracking;
        })
        .catch((error: any) => {
            throw error;
        })

    return messages;
}