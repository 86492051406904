import { useState } from "react";
import { useSelector } from "react-redux";
import {
	Badge,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Icon,
	Spacer,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { BsBell, BsTrashFill } from "react-icons/bs";

import { RootState } from "../../redux/rootReducer";
import {
	dismissAlerts,
	getBreakdown,
} from "../../features/notifications/notification.actions";

import { ObbiAlertDialog } from "../common/AlertDialog";
import { NotificationTable } from "./NotificationTable";
import { useLocation } from "react-router-dom";

export const Notifications = () => {
	const toast = useToast();
	const { state }: any = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const notificationFolders = useSelector((state: RootState) =>
		state.notificationReducer.notificationFolders);

	const [grouping, setGrouping] = useState(state?.grouping || "new");
	const [pageData, setPageData] = useState({
		tabIndex: state?.tabIndex || 0,
		pageIndex: state?.index || 0
	});

	const setPageIndex = (index: number) => {
		setPageData({
			...pageData,
			pageIndex: index,
		});
	};

	const onTabIndexChange = (index: number) => {
		if (index !== 0) setGrouping("all");

		setPageData({
			...pageData,
			tabIndex: index,
			pageIndex: 0,
		});
	};

	const clearAll = () => {
		dismissAlerts()
			.then(() => {
				toast({
					title: "All notifications cleared.",
					description: "",
					status: "success",
					duration: 3000,
					isClosable: true,
				});

				onClose();
				setGrouping("all");
				getBreakdown();
			})
			.catch((error) => {
				toast({
					title: "Could not dismiss all notifications.",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true,
				});
			});
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			<ObbiAlertDialog
				isOpen={isOpen}
				onConfirm={() => { clearAll(); }}
				onClose={onClose}
				title="Clear all notifications?"
				message="Notifications that still require action cannot be cleared."
			/>

			<Box
				height={{ base: "max-content", md: "unset" }}
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={{ base: "none", md: "lg" }}
				rounded={{ base: "none", md: "lg" }}
				textAlign={"center"}
			>
				<HStack m={5} mt={1} gap={4}>
					<Icon
						as={BsBell}
						verticalAlign="middle"
						color="green.500"
						fontSize="4xl"
					/>
					<Text
						fontWeight={700}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						Notifications
					</Text>

					<Spacer />

					<Button
						display={{ base: "block", md: "none" }}
						color="red.500"
						borderColor="red.500"
						variant="outline"
						size="sm"
						float="right"
						onClick={() => onOpen()}
					>
						<HStack>
							<Icon as={BsTrashFill} />
							<Text>Clear</Text>
						</HStack>
					</Button>
				</HStack>

				<Divider mx={{ base: 0, md: -5 }} w="unset" />

				<Tabs
					mt={{ base: pageData.tabIndex === 2 ? 5 : 0, md: 0 }}
					colorScheme="green"
					defaultChecked={pageData.tabIndex}
					onChange={(index) => {
						onTabIndexChange(index);
					}}
				>
					<Flex
						justify={{ base: "center", md: "space-between" }}
						py={2}
					>
						<TabList
							mx={{ base: 0, md: 5 }}
							w={{ base: "full", md: "unset" }}
							justifyContent="center"
						>
							<Tab>
								<HStack>
									<Text
										color={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
										fontWeight={pageData.tabIndex === 0 ? 700 : 500}
									>
										New
									</Text>

									<Badge
										bg={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
										color="white"
									>
										{Number(notificationFolders.totalCount) || 0}
									</Badge>
								</HStack>
							</Tab>

							<Tab>
								<Text
									color={pageData.tabIndex === 1 ? "green.500" : "gray.500"}
									fontWeight={pageData.tabIndex === 1 ? 700 : 500}
								>
									All
								</Text>
							</Tab>

							<Tab>
								<Text
									color={pageData.tabIndex === 2 ? "green.500" : "gray.500"}
									fontWeight={pageData.tabIndex === 2 ? 700 : 500}
								>
									Cleared
								</Text>
							</Tab>
						</TabList>

						<HStack
							mx={5}
							mr={5}
							display={{
								base: "none",
								md: pageData.tabIndex === 2 ? "none" : "flex",
							}}
						>
							<Button
								color="red.500"
								borderColor="red.500"
								variant="outline"
								mb={-3}
								size="sm"
								float="right"
								onClick={() => onOpen()}
							>
								<Icon as={BsTrashFill} mr={2}></Icon> Clear all
							</Button>
						</HStack>
					</Flex>

					<TabPanels>
						<TabPanel>
							<NotificationTable
								tabIndex={pageData.tabIndex}
								grouping={grouping}
								setGrouping={setGrouping}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
							/>
						</TabPanel>
						<TabPanel>
							<NotificationTable
								tabIndex={pageData.tabIndex}
								grouping={grouping}
								setGrouping={setGrouping}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
							/>
						</TabPanel>
						<TabPanel>
							<NotificationTable
								tabIndex={pageData.tabIndex}
								grouping={grouping}
								setGrouping={setGrouping}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
};
