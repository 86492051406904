import {
    As,
    Button,
    ButtonProps,
    HStack,
    Icon,
    SlideFade,
    Spacer,
    Text,
    useBreakpointValue
} from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';

interface NavButtonProps extends ButtonProps {
    icon: As;
    label: string;
    bgColor: string;
    color: string;
    toggleAlert: boolean;
};

export const NavButton: React.FC<any> = (props: NavButtonProps) => {
    const { icon, label, bgColor, color, toggleAlert, ...buttonProps } = props;

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        isMobile ?
            <Button
                _hover={{ bgColor: bgColor ? bgColor : "gray.50" }}
                backgroundColor={bgColor}
                variant="ghost"
                justifyContent="start"
                {...buttonProps}
            >
                <HStack spacing={3}>
                    <Icon
                        as={icon}
                        boxSize={6}
                        color={color || "subtle"}
                    />
                    <Text fontSize="sm" color={color}>
                        {label}
                    </Text>

                    <Spacer />

                    {
                        toggleAlert && <SlideFade in={true} offsetY="20px">
                            <Icon
                                as={BsDot}
                                color={window.location.pathname.includes("messages") ? "white" : "danger.500"}
                                boxSize="28pt"
                                verticalAlign="sub"
                            />
                        </SlideFade>
                    }
                </HStack>
            </Button> :

            <Button
                _hover={{ bgColor: bgColor ? bgColor : "gray.50" }}
                backgroundColor={bgColor}
                variant="ghost"
                justifyContent="start"
                h="37px"
                {...buttonProps}
            >
                <HStack spacing={3}>
                    <Icon
                        as={icon}
                        boxSize={{ base: "4", md: "5" }}
                        color={color || "subtle"}
                    />
                    <Text fontSize="sm" color={color}>
                        {label}
                    </Text>

                    <Spacer />

                    {
                        toggleAlert && <SlideFade in={true} offsetY="20px">
                            <Icon
                                as={BsDot}
                                color={window.location.pathname.includes("messages") ? "white" : "danger.500"}
                                boxSize="28pt"
                                verticalAlign="sub"
                            />
                        </SlideFade>
                    }
                </HStack>
            </Button>

    );
}