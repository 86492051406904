import {
	Avatar,
	Box,
	HStack,
	SlideFade,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

import { localDateTimeIgnoreToday, relativeTime } from "../../helpers/DayJsHelper";

export const TaskAttemptsTable: React.FC<any> = ({
	navData,
	attemptData,
	teamTaskId,
	teamId,
	taskId,
	cardStatus
}) => {
	const navigateTo = useNavigate();

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				disableSortBy: true,
			},
			{
				Header: "DATE STARTED",
				accessor: "started",
				sortType: "basic",
				Cell: (cell: any) => {
					if (cell.value) {
						return (
							<VStack alignItems="left">
								<Text
									color="gray.600"
									fontSize="sm"
								>
									{localDateTimeIgnoreToday(cell.value)}
								</Text>
							</VStack>
						);
					} else return <Text color="gray.500">Not scheduled</Text>;
				}
			},
			{
				Header: "STARTED BY",
				accessor: "by",
				disableSortBy: true,
				Cell: (cell: any) => <HStack>
					<Avatar name={cell.value} size="xs" color="white" />
					<Text color="gray.600" fontSize="sm">
						{cell.value}
					</Text>
				</HStack>
			}
		], []
	);

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick,
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["id"]
				},

			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading} offsetY="20px">
				<VStack
					display={{ base: "flex", xl: "none" }}
					spacing={3}
					m={2}
				>
					{
						attemptData.map((attempt: any, i: number) => (
							<Box
								w="full"
								p={2}
								className="hover-pop"
								cursor="pointer"
								backgroundColor={i % 2 ? "white" : "gray.50"}
								boxShadow="xs"
								border="1px solid"
								borderColor="gray.100"
								rounded="lg"
								onClick={() => {
									navigateTo(`/tasks/task/${attempt.id}`, {
										state: {
											taskId: taskId,
											teamId: teamId,
											teamTaskId: teamTaskId,
											cardStatus: cardStatus,
											from: 'tasks',
											navData: navData
										},
									});
								}}
							>
								<Box verticalAlign="middle" mx={2}>
									<Text
										fontSize="sm"
										fontWeight={500}
										color="gray.600"
										textAlign="start"
										mb={2}
									>
										Started {localDateTimeIgnoreToday(attempt.started)}
									</Text>

									<Text
										fontSize="xs"
										fontWeight={500}
										color="gray.500"
										textAlign="start"
									>
										By {attempt.by}, {relativeTime(attempt.started)}
									</Text>
								</Box>
							</Box>
						))
					}
				</VStack>

				<Table
					{...getTableProps()}
					id="TaskHistoryTable"
					variant="striped"
					display={{ base: "none", xl: "inline-table" }}
				>
					<Thead>
						{
							headerGroups.map((headerGroup) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<Th
											{...column.getHeaderProps()}
											onClick={() => onHeaderClick(column)}
										>
											<Text fontWeight={700} color="gray.600">
												{column.render("Header")}
											</Text>
										</Th>
									))}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.map((row, i) => {
								prepareRow(row);
								return (
									<Tr
										{...row.getRowProps()}
										key={i}
										className="hover-pop"
										cursor="pointer"
										borderBottom="1px solid"
										borderColor={"gray.100"}
										onClick={() => {
											navigateTo(`/tasks/task/${row.values.id}`, {
												state: {
													id: row.values.id,
													taskId: taskId,
													teamId: teamId,
													teamTaskId: teamTaskId,
													cardStatus: cardStatus,
													navData: navData
												},
											});
										}}
									>
										{
											row.cells.map((cell) => (
												<Td wordBreak="break-word" {...cell.getCellProps()}>
													{cell.render("Cell")}
												</Td>
											))
										}
									</Tr>
								);
							})
						}
					</Tbody>
				</Table>
			</SlideFade>
		);
	}

	return DataTable({
		data: attemptData,
		columns
	});
};