import { useEffect, useState } from "react";
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	HStack,
	Icon,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Spinner,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	BsArrowLeftCircleFill,
	BsAward,
	BsXCircleFill
} from "react-icons/bs";
import { downloadCertificate } from "../../features/certificates/certificates.actions";
import { isEmpty, isUndefined } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { CertificateTable } from "./CertificateTable";
import { getClickthroughURL } from "../../features/auth/auth.actions";

export const Certificates = () => {
	const { state }: any = useLocation();
	const user = useSelector((state: RootState) => state.authReducer.user);
	const navigateTo = useNavigate();

	const [isOpen, setIsOpen] = useState(false);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);
	const [currentCert, setCurrentCert] = useState({
		id: 0,
		isPdf: false,
		uri: "",
		fileName: ""
	});
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageData, setPageData] = useState({
		tabIndex: 0,
	});
	const [tabIndex, setTabIndex] = useState(state?.tabIndex || 0);

	const [clearAllMyCertificatesFilters, setClearAllMyCertificatesFilters] = useState(false);
	const [clearAllManagedCertificatesFilters, setClearAllManagedCertificatesFilters] = useState(false);
	const [myCertificatesFilterSelected, setMyCertificatesFilterSelected] = useState(false);
	const [myManagedCertificatesFilterSelected, setMyManagedCertificatesFilterSelected] = useState(false);

	function clearAllFiltersPressed() {
		if (tabIndex === 0) {
			//my certs tab is open
			setClearAllMyCertificatesFilters(true);
		}
		else if (tabIndex === 1) {
			//my managed certificates tab is open
			setClearAllManagedCertificatesFilters(true);
		}
	}

	const onTabIndexChange = async (index: number) => {
		setTabIndex(index);
		setPageData({
			tabIndex: index,
		});
	};

	const resetCertViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetCertViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent minH="100vh" my={0}>
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} pr={10}>
						<Text wordBreak="break-word" fontSize={{ base: "sm", sm: "md" }}>
							{currentCert.fileName}
						</Text>

						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentCert.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderImage = () => {
		return <Modal
			size="4xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false) }}
		>
			<ModalOverlay />

			<ModalContent my={0} bg="white">
				<ModalHeader
					style={{
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white"
					}}
				>
					<HStack gap={3} paddingRight={10}>
						<Text wordBreak="break-word" fontSize={{ base: "sm", sm: "md" }}>
							{currentCert.fileName || "Untitled"}
						</Text>

						<Spacer />

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody m={5}>
					<Image src={currentCert.uri} m="0 auto" p={0} />
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const fetchCertificate = async (id: number, isPdf: boolean) => {
		await downloadCertificate(id, isPdf)
			.then((res: any) => {
				setCurrentCert({
					id: id,
					isPdf: res.cert.ispdf || res.cert.isdoc,
					uri: res.datauri,
					fileName: res.cert.certfilename
				});
			})
			.catch((error: any) => {
				console.log(error);
			})
	}

	useEffect(() => {
		if (state?.from === "notification") {
			fetchCertificate(state?.id, state?.isPdf)
				.then(() => {
					if (state?.isPdf || currentCert.isPdf) {
						setPdfIsOpen(true);
					} else {
						setIsOpen(true);
					}
				});
		}
	}, []);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	const handleClickThrough = async (path: string, id?: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			{
				!isEmpty(currentCert.uri) && pdfIsOpen && renderPdf()
			}

			{
				!isEmpty(currentCert.uri) && isOpen && renderImage()
			}

			{/* back to notifications - desktop */}
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				hidden={isUndefined(state?.from)}
				size="sm"
				mb={2}
				variant="outline"
				color="gray.600"
				fontWeight={600}
				onClick={() => {
					navigateTo(-1);
				}}
			>
				<HStack>
					<Icon as={BsArrowLeftCircleFill} />
					<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
				</HStack>
			</Button>

			<Box
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
				minH={{ base: "100vh", md: "auto" }}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={"lg"}
				rounded={"lg"}
			>
				{/* back to notifications - mobile */}
				<Button
					display={{ base: "unset", md: "none" }}
					m={5}
					size="sm"
					color="gray.600"
					variant="link"
					hidden={isUndefined(state?.from)}
					fontWeight={600}
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircleFill} />
						<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
					</HStack>
				</Button>

				{/* title */}
				<HStack mx={5} mb={5} gap={4}>
					<Icon
						as={BsAward}
						verticalAlign="middle"
						fontSize="4xl"
						color="green.500"
					/>
					<Text
						fontWeight={{ base: 600, lg: 700 }}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						Certificates
					</Text>

					<Spacer />

					{
						(
							(tabIndex === 0 && myCertificatesFilterSelected) ||
							(tabIndex === 1 && myManagedCertificatesFilterSelected)
						) &&
						<Button
							variant="ghost"
							size="xs"
							onClick={clearAllFiltersPressed}
						>
							<HStack>
								<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
								<Text>Clear All Filters</Text>
							</HStack>
						</Button>
					}

					{
						user?.permissions?.Certificates?.read && <>
							<Button
								display={{ base: "none", md: "flex" }}
								bg="green.500"
								_hover={{
									bg: "#248451",
								}}
								mb={-2}
								size="sm"
								color="white"
								float="right"
								onClick={() => {
									handleClickThrough("ManageCertificates");
								}}
							>
								<Icon as={BsAward} mr={2} /> Manage Certificates
							</Button>
						</>
					}
				</HStack>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				{
					user?.isTeamManager ?
						<Tabs colorScheme="green" onChange={(index) => onTabIndexChange(index)}>
							<Flex
								justify={{ base: "center", md: "space-between" }}
								py={2}
							>
								<TabList
									mx={{ base: 0, md: 5 }}
									w={{ base: "full", md: "unset" }}
									justifyContent="center"
								>
									<Tab>
										<Text
											color={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
											fontWeight={pageData.tabIndex === 0 ? 700 : 500}
										>
											My Certificates
										</Text>
									</Tab>
									<Tab>
										<Text
											color={pageData.tabIndex === 1 ? "green.500" : "gray.500"}
											fontWeight={pageData.tabIndex === 1 ? 700 : 500}
										>
											My Team's Certificates
										</Text>
									</Tab>
								</TabList>
							</Flex>

							<TabPanels>
								<TabPanel>
									<CertificateTable
										role={Role.USER}
										clearAllFilters={clearAllMyCertificatesFilters}
										setClearAllFilters={setClearAllMyCertificatesFilters}
										setFilterSelected={setMyCertificatesFilterSelected}
									/>
								</TabPanel>
								<TabPanel>
									<CertificateTable
										role={Role.MANAGER}
										clearAllFilters={clearAllManagedCertificatesFilters}
										setClearAllFilters={setClearAllManagedCertificatesFilters}
										setFilterSelected={setMyManagedCertificatesFilterSelected}
									/>
								</TabPanel>
							</TabPanels>
						</Tabs>
						:
						<Box m={5}>
							<CertificateTable
								role={Role.USER}
								clearAllFilters={clearAllMyCertificatesFilters}
								setClearAllFilters={setClearAllMyCertificatesFilters}
								setFilterSelected={setMyCertificatesFilterSelected}
							/>
						</Box>
				}
			</Box>
		</Box>
	);
};

export enum Role {
	MANAGER = "manager",
	USER = "user"
}