import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Button,
	Center,
	CircularProgress,
	HStack,
	Icon,
	Square,
	Text,
	useColorModeValue,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { BsFillCloudUploadFill } from 'react-icons/bs';
import _ from 'lodash';

export const DropZone: React.FC<any> = ({
	props,
	onDropAction,
	fileUploadLoading,
	additionalAcceptedFileTypes = []
}) => {
	const toast = useToast();
	const maxSize = 8388608; // 8MB

	const [acceptedFileTypes, setAcceptedFileTypes] = useState([
		"PNG",
		"JPG",
		"PDF"
	]);

	useEffect(() => {
		const distinctAcceptedFileTypes = _.union(acceptedFileTypes.concat(additionalAcceptedFileTypes));
		setAcceptedFileTypes(distinctAcceptedFileTypes);
	}, []);

	const onDrop =
		useCallback((acceptedFiles => {
			if (acceptedFiles.length > 4) {
				toast({
					title: "You can only upload up to 5 attachments",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			} else {
				acceptedFiles.forEach((file: any) => {
					if (file.size > maxSize) {
						toast({
							title: `${file.name} exceeds 8MB`,
							description: "",
							status: "error",
							duration: 6000,
							isClosable: true
						});
					} else {
						onDropAction(file);
					}
				});
			}
		}), [onDropAction, toast]);

	const {
		getRootProps,
		getInputProps
	} = useDropzone({ onDrop });

	return (
		<Center
			borderWidth="1px"
			borderRadius="lg"
			minH="165px"
			px="6"
			py="4"
			bg={useColorModeValue('white', 'gray.800')}
			{...props}
			{...getRootProps()}
		>
			{
				fileUploadLoading ? <CircularProgress
					isIndeterminate
					thickness={5}
					color='brand.500'
				/> :
					<>
						<input {...getInputProps()} />
						
						<VStack spacing="3">
							<Square
								size="10"
								bg="bg-subtle"
								borderRadius="lg"
							>
								<Icon
									as={BsFillCloudUploadFill}
									boxSize="5"
									color="gray.500"
								/>
							</Square>

							<VStack spacing="1">
								<HStack spacing="1" whiteSpace="nowrap">
									<Button
										variant="link"
										color="brand.500"
										fontWeight={600}
										size="sm"
										_hover={{ color: "" }}
										_active={{ color: "" }}
									>
										Click to upload
									</Button>

									<Text fontSize="sm" color="gray.600">
										or drag and drop
									</Text>
								</HStack>

								<Text fontSize="xs" color="gray.500">
									{
										acceptedFileTypes.map((type: string, i: number) => {
											if (i === acceptedFileTypes.length - 1) {
												return `${type} `;
											} else if (i === acceptedFileTypes.length - 2) {
												return `${type} or `;
											} else
												return `${type}, `;
										})
									}
									up to 8MB
								</Text>
							</VStack>
						</VStack>
					</>
			}
		</Center>
	)
}