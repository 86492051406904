import {
    Center,
    Image,
    Text,
    VStack
} from "@chakra-ui/react";

import construction from "../../assets/images/construction.svg";

export const UnderConstruction: React.FC<any> = () => {
    return (
        <Center m={5}>
            <VStack>
                <Image mb={8} src={construction} />
                <Text fontSize="2xl" fontWeight={600}>
                    Feature under construction
                </Text>
                <Text fontSize="lg" color="gray.500" fontWeight={500}>
                    Check back soon!
                </Text>
            </VStack>
        </Center>
    )
};