import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	AspectRatio,
	Badge,
	Box,
	Button,
	Center,
	Divider,
	FormControl,
	Grid,
	HStack,
	Icon,
	Image,
	Input,
	Skeleton,
	SlideFade,
	Spacer,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useBreakpointValue,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import { useTable, useSortBy, usePagination } from "react-table";
import {
	BsArrowLeftCircleFill,
	BsCheckCircleFill,
	BsExclamationCircleFill,
	BsPlayBtnFill,
	BsPlayCircleFill,
	BsXCircleFill,
} from "react-icons/bs";
import { VscMortarBoard } from "react-icons/vsc";

import incident from "../../assets/images/incident.svg";
import books from "../../assets/images/books.svg";
import sortBoard from "../../assets/images/sortBoard.svg";
import board from "../../assets/images/whiteboard.svg";

import { getCourses } from "../../features/training/training.actions";

import { InsightActionCard } from "../common/InsightActionCard";
import { SmallFilterCard } from "../common/SmallFilterCard";
import { Pager } from "../common/Pager";
import { isUndefined } from "lodash";
import { useDebounce } from "../common/useDebounce";
import { localDate } from "../../helpers/DayJsHelper";

export const Training = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [tabIndex, setTabIndex] = useState(0);
	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);

	const [currentItems, setCurrentItems] = useState<any[]>([]);
	const [categories, setCategories] = useState([]);

	const [search, setSearch] = useState<string>("");

	const [pageCount, setPageCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	const [numberOfNotStartedCourses, setNumberOfNotStartedCourses] = useState(0);
	const [numberOfInvalidCourses, setNumberOfInvalidCourses] = useState(0);
	const [numberOfInProgressCourses, setNumberOfInProgressCourses] = useState(0);
	const [numberOfExpiringCourses, setNumberOfExpiringCourses] = useState(0);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [category, setCategory] = useState<string>("");
	const setCategoryData = (category: any) => {
		setCategory(category);
		setPageIndex(0);
	};

	const [clearAllTrainingLibraryFilters, setClearAllTrainingLibraryFilters] = useState(false);
	const [trainingLibraryFilterSelected, setTrainingLibraryFilterSelected] = useState(false);

	const clearAllFiltersPressed = () => {
		if (tabIndex === 0) {
			//training library tab is open
			setClearAllTrainingLibraryFilters(true);
		}
	};

	const debouncedRequest = useDebounce(() => {
		fetchData({
			filter,
			category,
			search,
			pageIndex,
		});
	});

	const onChange = (e: any) => {
		setSearch(e.target.value);
		debouncedRequest();
	};

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				disableSortBy: true,
			},
			{
				Header: "COURSE",
				accessor: "course",
				disableSortBy: true,
				Cell: (cell: any) => <Text>{cell.value || "no title"}</Text>,
			},
			{
				Header: "MODULES",
				accessor: "modules",
				sortType: "basic",
				Cell: (cell: any) => <Text>{cell.value || "no title"}</Text>,
			},
			{
				Header: "STATUS",
				accessor: "status",
				sortType: "basic",
				Cell: (cell: any) => {
					if (cell.value === "fail") {
						return (
							<Badge
								colorScheme="danger"
								backgroundColor="white"
								size="sm"
								variant="outline"

							>
								<Icon
									as={BsExclamationCircleFill}
									mt="-2px"
									mr="2px"
									verticalAlign="middle"
								/>{" "}
								Failed
							</Badge>
						);
					} else if (cell.value === "pass") {
						return (
							<Badge
								colorScheme="brand"
								backgroundColor="white"
								size="sm"
								variant="outline"

							>
								<Icon
									as={BsCheckCircleFill}
									mt="-2px"
									mr="2px"
									verticalAlign="middle"
								/>{" "}
								Passed
							</Badge>
						);
					} else {
						return (
							<Badge
								colorScheme="gray"
								backgroundColor="white"
								size="sm"
								variant="outline"

							>
								<Icon
									as={BsExclamationCircleFill}
									mt="-2px"
									mr="2px"
									verticalAlign="middle"
								/>{" "}
								In progress
							</Badge>
						);
					}
				}
			},
			{
				Header: "EXPIRY",
				accessor: "expiry",
				sortType: "basic",
				Cell: (cell: any) => <Text>{localDate(cell.value)}</Text>,
			},
			{
				Header: "ACTION",
				accessor: "action",
				sortType: "basic",
				Cell: (cell: any) => {
					if (cell.value === "pass") {
						return <Fragment />;
					} else if (cell.value === "inprogress") {
						return (
							<Button size="sm" colorScheme="darkgray">
								Continue <Icon as={BsPlayCircleFill} ml={2} />
							</Button>
						);
					} else {
						return (
							<Button size="sm" colorScheme="green">
								Start <Icon as={BsPlayBtnFill} ml={2} />
							</Button>
						);
					}
				}
			},
		], []
	);

	const fetchData = useCallback(
		async ({ filter, category, search, pageIndex }) => {
			setLoading(true);

			await getCourses(
				filter,
				category,
				search,
				(pageIndex + 1).toString()
			)
				.then(async (res: any) => {
					let formattedCurrentItems: any[] = [];

					for (let i = 0; i < res.courses.length; i++) {
						let courseImage = "https://dummyimage.com/300";

						try {
							courseImage = `${process.env.REACT_APP_OBBI_API_URL}/training/modulePhoto?id=${Number(res.courses[i].cmodid)}&datauri=false&thumbnail=false`;
						} catch (error: any) {
							console.log(error);
						}

						formattedCurrentItems.push({
							id: res.courses[i].cmodid,
							course: res.courses[i].cmodname,
							modules: res.courses[i].numcourses,
							expiry: res.courses[i].datevaliduntil,
							action: res.courses[i].statestring,
							courseImage
						});
					}

					setNumberOfNotStartedCourses(res.numberOfNotStartedCourses);
					setNumberOfInvalidCourses(res.numberOfInvalidCourses);
					setNumberOfInProgressCourses(res.numberOfInProgressCourses);
					setNumberOfExpiringCourses(res.numberOfExpiringCourses);

					setCategories(res.categories);

					setCurrentItems(formattedCurrentItems);
					setPageCount(res.pageCount);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
		}, []);

	useEffect(() => {
		fetchData({
			filter,
			category,
			search,
			pageIndex,
		});
	}, [fetchData, category, filter, search, pageIndex]);

	useEffect(() => {
		if (clearAllTrainingLibraryFilters) {
			setFilter("all");
			setCategory("");
			setSearch("");
			setPageIndex(0);
			setClearAllTrainingLibraryFilters(false);
		}
	}, [clearAllTrainingLibraryFilters]);

	useEffect(() => {
		const filterInEffect =
			(filter !== "" && filter !== "all") ||
			search !== "" ||
			(category !== "" && category !== undefined);

		setTrainingLibraryFilterSelected(filterInEffect);
	}, [filter, category, search]);

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick,
	}: any) {
		const {
			pageCount
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["id"]
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<Box
				m={{ base: 0, md: 5 }}
				height={{ base: "calc(100%)", md: "unset" }}
			>
				<Button
					display={{ base: "none", md: "unset" }}
					w="fit-content"
					mb={2}
					hidden={isUndefined(state?.from)}
					size="sm"
					variant="outline"
					color="gray.600"
					fontWeight={600}
					boxShadow="xs"
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircleFill} />
						<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
					</HStack>
				</Button>

				<Box
					px={{ base: 0, md: 5 }}
					pt={5}
					pb={3}
					minH={{ base: "100vh", md: "auto" }}
					bg={useColorModeValue("white", "gray.900")}
					boxShadow={"lg"}
					rounded={"lg"}
				>
					<Button
						display={{ base: "unset", md: "none" }}
						hidden={isUndefined(state?.from)}
						size="sm"
						m={5}
						textAlign="start"
						color="gray.600"
						variant="link"
						fontWeight={600}
						onClick={() => {
							navigateTo(-1);
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircleFill} />
							<Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
						</HStack>
					</Button>

					<HStack
						mx={5}
						mb={5}
						gap={4}
					>
						<Icon
							as={VscMortarBoard}
							verticalAlign="middle"
							fontSize="4xl"
							color="green.500"
						/>
						<Text
							fontWeight={{ base: 600, lg: 700 }}
							fontSize={{ base: "lg", lg: "2xl" }}
							color="gray.700"
						>
							{tabIndex > 0 ? "Training Reports" : "Training Library"}
						</Text>

						<Spacer />

						{
							(tabIndex === 0 && trainingLibraryFilterSelected) && <Button
								variant="ghost"
								size="xs"
								onClick={clearAllFiltersPressed}
							>
								<HStack>
									<Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
									<Text>Clear All Filters</Text>
								</HStack>
							</Button>
						}

						{/* {
							<Button
								borderColor="green"
								variant="outline" 
								p={7}
								float="right"
								onClick={() => {
									navigateTo("/tasks", {
										state: { navData: { tabIndex: 1, filter: "required" } }
									});
								}}
							>
								<VStack>
									<HStack color="green">
										<Icon as={BsClipboardCheck} />
										<Text fontSize="sm">5 People are waiting to be trained on their tasks</Text>
									</HStack>

									<Text fontSize="xs" color="gray.500">Click to view </Text>
								</VStack>
							</Button>
						} */}
					</HStack>

					<Divider width="unset" mx={{ base: 0, md: -5 }} />

					<Tabs colorScheme="green" onChange={(index) => setTabIndex(index)}>
						<TabPanels>
							<TabPanel>
								{
									currentItems ? <>
										<Grid
											templateColumns={{
												base: "repeat(2, 2fr)",
												sm: "repeat(4, 1fr)"
											}}
											gap={5}
											mb={5}
										>
											<InsightActionCard
												title="Not Started"
												count={numberOfNotStartedCourses}
												image={books}
												color="#805AD5"
												active={filter === "notstarted"}
												onClickAction={() => {
													if (filter === "notstarted") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("notstarted");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>

											<InsightActionCard
												title="In Progress"
												count={numberOfInProgressCourses}
												image={sortBoard}
												color="#DD6B20"
												active={filter === "inprogress"}
												onClickAction={() => {
													if (filter === "inprogress") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("inprogress");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>

											<InsightActionCard
												title="Failed or Expired"
												count={numberOfInvalidCourses}
												image={incident}
												color="danger.500"
												active={filter === "notvalid"}
												onClickAction={() => {
													if (filter === "notvalid") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("notvalid");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>

											<InsightActionCard
												title="Expiring Soon"
												count={numberOfExpiringCourses}
												image={board}
												color="#D69E2E"
												active={filter === "expiringsoon"}
												onClickAction={() => {
													if (filter === "expiringsoon") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("expiringsoon");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>
										</Grid>

										{
											!isMobile && <Divider w="unset" mx={{ base: -5, md: -10 }} />
										}

										{
											categories.length > 0 &&
											<Box
												p={1.5}
												mt={5}
												bg="gray.50"
												textAlign="left"
												rounded="lg"
												border="1px solid"
												borderColor="gray.100"
											>
												<Text
													fontWeight={500}
													color="gray.500"
													fontSize="sm"
													ml={1}
													mb={2}
												>
													Training Categories
												</Text>

												<SlideFade in={!firstTimeLoading} offsetY="20px">
													{
														categories.map((c: any, i: Number) => {
															return (
																<SmallFilterCard
																	key={i}
																	title={c.mcatname}
																	onClickAction={() => {
																		c.mcatname !== category
																			? setCategoryData(c.mcatname)
																			: setCategoryData("");
																	}}
																	selectedCard={category}
																	icon={VscMortarBoard}
																	iconColor="brand.500"
																/>
															);
														})
													}
												</SlideFade>
											</Box>
										}

										<SlideFade in={!firstTimeLoading} offsetY="20px">
											<FormControl
												isRequired
												w={{ base: "full", md: "50%" }}
												mt={
													categories.length > 0 && categories.length <= 7 ? 2 :
														categories.length > 7 ? 5 : 5
												}
											>
												<Input
													placeholder="Search Courses"
													fontSize="sm"
													value={search}
													onChange={onChange}
												/>
											</FormControl>
										</SlideFade>

										<Box
											mt={5}
											px={5}
											border="1px"
											borderRadius="12px"
											borderColor="gray.200"
											hidden={firstTimeLoading}
										>
											{
												!loading && currentItems.length < 1 ? <Center m={5}>
													<VStack>
														<Text fontSize="xl" fontWeight={600}>
															No Courses Found
														</Text>
														<Text fontWeight={400} color="gray.500">
															You have no courses available
														</Text>
													</VStack>
												</Center> :
													<>
														<Grid
															templateColumns={{
																base: "repeat(1, minmax(0, 1fr))",
																sm: "repeat(2, minmax(0, 1fr))",
																lg: "repeat(3, minmax(0, 1fr))",
																xl: "repeat(5, minmax(0, 1fr))",
															}}
															gridAutoRows="1fr"
															gap={5}
															my={5}
														>
															{
																currentItems.map((item: any, i) => {
																	return (
																		<Skeleton
																			isLoaded={!loading}
																			display="flex"
																			rounded="lg"
																			key={i}
																		>
																			<Box
																				position="relative"
																				w="full"
																				p={3}
																				background="white"
																				rounded="md"
																				boxShadow="xs"
																				border="1px solid"
																				borderColor="gray.200"
																				className="hover-pop"
																				cursor="pointer"
																				onClick={() => {
																					navigateTo(`/training/course/${item.id}`);
																				}}
																			>
																				<AspectRatio maxW="100%" ratio={4 / 3} mb={3}>
																					<Image
																						rounded="lg"
																						src={item.courseImage}
																					/>
																				</AspectRatio>

																				<VStack alignItems="start">
																					<Badge
																						colorScheme={
																							item.action === "Valid" ? "brand"
																								: item.action === "Not Valid" || item.action === "Expired" ? "danger"
																									: "gray"
																						}
																						size="sm"
																						variant="outline"

																					>
																						<Icon
																							as={
																								item.action === "Valid" ? BsCheckCircleFill
																									: item.action === "Not Valid" || item.action === "Expired" ? BsXCircleFill
																										: BsPlayBtnFill
																							}
																							mt="-2px"
																							mr="2px"
																							verticalAlign="middle"
																						/>{" "}
																						{item.action}
																					</Badge>

																					<Box width="calc(100%)">
																						<Text
																							fontWeight={700}
																							fontSize="sm"
																							color="gray.600"
																							textAlign="left"
																							textOverflow="ellipsis"
																							overflow="hidden"
																							whiteSpace="nowrap"
																							height="3ch"
																						>
																							{item.course}
																						</Text>
																						<Text
																							fontWeight={500}
																							fontSize="xs"
																							color="gray.500"
																							textAlign="left"
																							height="2ch"
																						>
																							{item.modules} modules
																						</Text>
																					</Box>

																					{
																						item.expiry !== null && <HStack alignItems="start">
																							<Text
																								fontSize="xs"
																								color="gray.500"
																								fontWeight={600}
																								textAlign="left"
																							>
																								Expires {localDate(item.expiry)}
																							</Text>
																						</HStack>
																					}
																				</VStack>
																			</Box>
																		</Skeleton>
																	);
																})
															}
														</Grid>

														<Box
															my={3}
															textAlign="center"
															hidden={pageCount <= 1}
														>
															<Pager
																pageCount={pageCount}
																pageIndex={pageIndex}
																setPageIndex={setPageIndex}
															/>
														</Box>
													</>
											}
										</Box>
									</> :

										<Center m={5}>
											<VStack>
												<Text fontSize="xl" fontWeight={600}>
													No Courses Found
												</Text>
												<Text fontWeight={400} color="gray.500">
													You have no courses available
												</Text>
											</VStack>
										</Center>
								}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Box>
		);
	}

	return DataTable({
		data: currentItems,
		columns,
		loading,
		pageCount,
	});
};