import { getWithParams, post } from "../../api/axiosClient";

export async function getDocuments(
    sort: string,
    filter: string,
    team: number,
    category: string,
    search: string,
    page: string,
    pagesize: string = "10"
) {
    let certificatesResult: any = {};

    await getWithParams("documents/all", { sort, filter, team, category, search, page, pagesize })
        .then((res: any) => {
            certificatesResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return certificatesResult;
}

export async function getDocument(id: number) {
    let documemnt: any = null;

    await getWithParams("documents/document", { id })
        .then((res: any) => {
            documemnt = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return documemnt;
}

export async function acknowledgeDocument(id: number) {
    let result: any = null;

    await post("documents/acknowledge", {id: id})
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function downloadDocument(id: number, pdf: boolean) {
    let result: any = null;

    await getWithParams("documents/download", { id, pdf, datauri: true })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getDocumentThumbnail(id: number, datauri: boolean = true) {
    let result: any = null;

    await getWithParams("documents/thumbnail", { id, datauri })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}