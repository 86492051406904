import {
    useEffect,
    useState,
    useMemo,
    useCallback
} from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    usePagination,
    useSortBy,
    useTable
} from "react-table";
import {
    Avatar,
    Badge,
    Box,
    Center,
    Divider,
    Grid,
    HStack,
    Icon,
    SlideFade,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    VStack
} from "@chakra-ui/react";
import {
    BsArrowDown,
    BsArrowUp,
    BsCheckCircleFill,
    BsExclamationCircleFill
} from "react-icons/bs";

import { RootState } from "../../redux/rootReducer";
import { getInbox } from "../../features/messages/message.actions";

import { InsightActionCard } from "../common/InsightActionCard";
import { Pager } from "../common/Pager";

import incident from "../../assets/images/incident.svg";
import organise from "../../assets/images/viewing-files.svg";
import { localDateTimeIgnoreToday } from "../../helpers/DayJsHelper";

export const InboxTable: React.FC<any> = ({
    tabIndex,
    index,
    setPageIndex,
    clearAllFilters,
    setClearAllFilters,
    setFilterSelected
}) => {
    const { state }: any = useLocation();
    const isMobile = useBreakpointValue({ base: true, xl: false });

    const navigateTo = useNavigate();
    const messageFolders = useSelector((state: RootState) => state.messageReducer.messageFolders);

    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentItems, setCurrentItems] = useState([]);

    const [pageCount, setPageCount] = useState(state !== null && state.pageCount ? state.pageCount : 0);
    const [sort, setSort] = useState<string>("datesentdesc");

    const [kind, setKind] = useState<string>(state?.filter || "all");
    const [filter, setFilter] = useState<string>(state?.filter === "critical" ? "new" : "all");

    const [criticalActive, setCriticalActive] = useState(state?.filter === "critical" ? true : false);
    const [unreadActive, setUnreadActive] = useState(state?.filter === "normal" ? true : false);

    useEffect(() => {
        if (clearAllFilters) {
            setUnreadActive(false);
            setCriticalActive(false);
            setKind("all");
            setFilter("all");
            setPageIndex(0);
            setClearAllFilters(false);
            setFilterSelected(false);
        }
    }, [clearAllFilters]);

    useEffect(() => {
        setFilterSelected(criticalActive || unreadActive);
    }, [criticalActive, unreadActive]);

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true,
            },
            {
                Header: <>
                    MESSAGE {
                        sort === "subject" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "subjectdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "title",
                Cell: (cell: any) => <HStack>
                    {
                        cell.value.critical === "1" &&
                        <Icon color="red.500" justifySelf="center" as={BsExclamationCircleFill} />
                    }
                    <Text textOverflow="ellipsis" color="gray.600">{cell.value.title || "Untitled"}</Text>
                </HStack>
            },
            {
                Header: <>
                    FROM {
                        sort === "sender" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "senderdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "from",
                Cell: (cell: any) => <HStack>
                    <Avatar name={cell.value} size="xs" color="white" />
                    <Text color="gray.600">{cell.value}</Text>
                </HStack>
            },
            {
                Header: <>
                    RECEIVED {
                        sort === "datesent" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "datesentdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "received",
                Cell: (cell: any) => <Text color="gray.600">
                    {localDateTimeIgnoreToday(cell.value)}
                </Text>
            },
            {
                Header: <>
                    ACKNOWLEDGED {
                        sort === "ackreq" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "ackreqdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "acknowledgement",
                Cell: (cell: any) => {
                    switch (cell.value) {
                        case "none": return <Text>-</Text>
                        case "required": return (
                            <Badge
                                colorScheme="red"
                                backgroundColor="transparent"
                                size="sm"
                                variant="outline"

                            >
                                <Icon
                                    as={BsExclamationCircleFill}
                                    mt="-2px"
                                    mr="2px"
                                    verticalAlign="middle"
                                /> Required
                            </Badge>
                        );
                        case "acknowledged": return <Text>
                            <Icon color="brand.500" as={BsCheckCircleFill} />
                        </Text>
                    }
                }
            },
            {
                Header: "READ",
                accessor: "read",
                disableSortBy: true,
                Cell: (cell: any) => {
                    if (cell.value === "0") {
                        return <Text>
                            <Icon color="brand.500" as={BsCheckCircleFill} />
                        </Text>;
                    } else {
                        return <Text>-</Text>
                    }
                }
            }
        ], [sort]
    );

    const sortColumn = (term: string) => {
        switch (term) {
            case "title":
                if (sort === "subject") {
                    setSort("subjectdesc");
                } else if (sort === "subjectdesc") {
                    setSort("subject");
                } else setSort("subjectdesc");
                break;
            case "from":
                if (sort === "sender") {
                    setSort("senderdesc");
                } else if (sort === "senderdesc") {
                    setSort("sender");
                } else setSort("senderdesc");
                break;
            case "received":
                if (sort === "datesent") {
                    setSort("datesentdesc");
                } else if (sort === "datesentdesc") {
                    setSort("datesent");
                } else setSort("datesentdesc");
                break;
            case "acknowledgement":
                if (sort === "ackreq") {
                    setSort("ackreqdesc");
                } else if (sort === "ackreqdesc") {
                    setSort("ackreq");
                } else setSort("ackreqdesc");
                break;
        }
    };

    const fetchData = useCallback(({
        sort,
        filter,
        kind,
        index
    }) => {
        setLoading(true);
        const pageIndex = (index + 1).toString();

        getInbox(
            sort,
            filter,
            kind,
            pageIndex
        )
            .then((result: any) => {
                const formattedCurrentItems = result.messages.map((message: any) => {
                    let acknowledgementStatus = "none";
                    if (message.msgacknowledgementrequired === "1" && message.muacknowledged === "1") {
                        acknowledgementStatus = "acknowledged";
                    } else if (message.msgacknowledgementrequired === "1" && message.muacknowledged === "0") {
                        acknowledgementStatus = "required";
                    }

                    return {
                        id: message.msgid,
                        title: { critical: message.msgkind, title: message.msgsubject },
                        from: `${message.udforename} ${message.udsurname}`,
                        received: message.msgdatesent,
                        acknowledgement: acknowledgementStatus,
                        read: message.muunread
                    }
                });

                setCurrentItems(formattedCurrentItems);
                setPageCount(result.pageCount);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            })
    }, []);

    useEffect(() => {
        fetchData({
            sort,
            filter,
            kind,
            index
        });
    }, [fetchData, filter, kind, index, sort]);

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount,
        } = useTable({
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: true,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
            useSortBy,
            usePagination
        );

        return (
            <>
                <Grid
                    templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
                    gap={5}
                    mb={5}
                >
                    <InsightActionCard
                        title="Critical Messages"
                        count={messageFolders.unreadCriticalCount || 0}
                        image={incident}
                        color="red.500"
                        active={criticalActive}
                        onClickAction={() => {
                            if (unreadActive) {
                                setUnreadActive(!unreadActive);
                                setCriticalActive(!criticalActive);
                                setKind("critical");
                                setFilter("new");
                                setPageIndex(0);
                            } else if (criticalActive) {
                                setCriticalActive(!criticalActive);
                                setKind("all");
                                setFilter("all");
                                setPageIndex(0);
                            } else {
                                setCriticalActive(true);
                                setKind("critical");
                                setFilter("new");
                                setPageIndex(0);
                            }
                        }}
                        loading={firstTimeLoading}
                    />

                    <InsightActionCard
                        title="Unread Messages"
                        count={messageFolders.unreadCount || 0}
                        image={organise}
                        color="orange.500"
                        active={unreadActive}
                        onClickAction={() => {
                            if (criticalActive) {
                                setCriticalActive(!criticalActive);
                                setUnreadActive(!unreadActive);
                                setKind("normal");
                                setFilter("new");

                                setPageIndex(0);
                            } else if (unreadActive) {
                                setUnreadActive(!unreadActive);
                                setKind("all");
                                setFilter("all");

                                setPageIndex(0);
                            } else {
                                setUnreadActive(true);
                                setKind("normal");
                                setFilter("new");

                                setPageIndex(0);
                            }
                        }}
                        loading={firstTimeLoading}
                    />
                </Grid>

                {
                    !isMobile && <Divider w="unset" mb={5} mx={{ base: -5, md: -10 }} />
                }

                <Box
                    p={3}
                    border="1px"
                    borderColor={firstTimeLoading ? "transparent" : "gray.200"}
                    rounded="lg"
                >
                    <SlideFade in={!loading} offsetY="20px">
                        <VStack display={{ base: "flex", lg: "none" }}>
                            {
                                currentItems.map((message: any, i: number) => (
                                    <Box
                                        key={`inbox_${i}`}
                                        w="full"
                                        p={2}
                                        className="hover-pop"
                                        cursor="pointer"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        backgroundColor={i % 2 ? "white" : "gray.50"}
                                        rounded="lg"
                                        boxShadow="xs"
                                        onClick={() => {
                                            navigateTo(`/messages/message/${message.id}`,
                                                { state: { sent: false } });
                                        }}
                                    >
                                        <VStack alignItems="start">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                                textAlign="start"
                                                mr={3}
                                                textOverflow="ellipsis"
                                                maxWidth="75ch"
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                            >
                                                {message.title.title || "no title"}
                                            </Text>

                                            <HStack pb={2}>
                                                <HStack>
                                                    <Avatar
                                                        name={message.from}
                                                        size="xs"
                                                        color="white"
                                                    />
                                                    <Text
                                                        fontSize="xs"
                                                        fontWeight={500}
                                                        color="gray.500"
                                                        textAlign="start"
                                                    >
                                                        {message.from},
                                                    </Text>
                                                </HStack>

                                                <Text
                                                    fontSize="xs"
                                                    fontWeight={500}
                                                    color="gray.500"
                                                    textAlign="start"
                                                >
                                                    {localDateTimeIgnoreToday(message.received)}
                                                </Text>
                                            </HStack>

                                            <HStack spacing={2}>
                                                {
                                                    message.read === "0" &&
                                                    <Badge
                                                        colorScheme="green"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"

                                                    >
                                                        <Icon
                                                            as={BsCheckCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Read
                                                    </Badge>
                                                }

                                                {
                                                    message.acknowledgement === "required" ?
                                                        <Badge
                                                            colorScheme="red"
                                                            backgroundColor="transparent"
                                                            size="sm"
                                                            variant="outline"

                                                        >
                                                            <Icon
                                                                as={BsExclamationCircleFill}
                                                                mt="-2px"
                                                                mr="2px"
                                                                verticalAlign="middle"
                                                            /> Acknowledge
                                                        </Badge> :

                                                        message.acknowledgement === "acknowledged" &&
                                                        <Badge
                                                            colorScheme="green"
                                                            backgroundColor="transparent"
                                                            size="sm"
                                                            variant="outline"

                                                        >
                                                            <Icon
                                                                as={BsCheckCircleFill}
                                                                mt="-2px"
                                                                mr="2px"
                                                                verticalAlign="middle"
                                                            /> Acknowledged
                                                        </Badge>
                                                }

                                                {
                                                    message.title.critical === "1" &&
                                                    <Badge
                                                        colorScheme="red"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"

                                                    >
                                                        <Icon
                                                            as={BsExclamationCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Critical
                                                    </Badge>
                                                }
                                            </HStack>
                                        </VStack>
                                    </Box>
                                ))
                            }
                        </VStack>

                        {
                            currentItems.length < 1 && !loading ?
                                <Center m={5}>
                                    <VStack>
                                        <Text
                                            fontSize="2xl"
                                            fontWeight={600}
                                        >
                                            No Messages
                                        </Text>
                                        <Text
                                            fontWeight={400}
                                            color="gray.500"
                                        >
                                            You have no messages in your inbox
                                        </Text>
                                    </VStack>
                                </Center> :

                                <SlideFade in={!loading} offsetY="20px">
                                    <Table
                                        {...getTableProps()}
                                        id="MessageTable"
                                        variant="striped"
                                        display={{ base: "none", lg: "inline-table" }}
                                    >
                                        <Thead>
                                            {
                                                headerGroups.map(headerGroup => (
                                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                                        {
                                                            headerGroup.headers.map((column, i) => (
                                                                <Th
                                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                    onClick={() => onHeaderClick(column)}
                                                                    key={`inbox_header_${i}`}
                                                                >
                                                                    <Text fontWeight={700} color="gray.600">
                                                                        {column.render("Header")}
                                                                    </Text>
                                                                </Th>
                                                            ))
                                                        }
                                                    </Tr>
                                                ))
                                            }
                                        </Thead>

                                        <Tbody {...getTableBodyProps()}>
                                            {
                                                page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <Tr
                                                            {...row.getRowProps()}
                                                            className="hover-pop"
                                                            key={`inbox_row_${i}`}
                                                            borderBottom="1px solid"
                                                            borderColor={"gray.100"}
                                                            cursor="pointer"
                                                            rounded="lg"
                                                            onClick={() => {
                                                                navigateTo(`/messages/message/${row.values.id}`,
                                                                    {
                                                                        state: {
                                                                            tabIndex: tabIndex,
                                                                            pageIndex: index,
                                                                            pageCount: pageCount,
                                                                            filter: kind
                                                                        }
                                                                    });
                                                            }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    return <Td wordBreak="break-word" {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </Td>
                                                                })
                                                            }
                                                        </Tr>
                                                    );
                                                })
                                            }
                                        </Tbody>
                                    </Table>
                                </SlideFade>
                        }
                    </SlideFade>

                    <Box
                        mt={3}
                        textAlign="center"
                        display={pageCount > 1 ? "block" : "none"}
                    >
                        <Pager
                            pageCount={pageCount}
                            pageIndex={index}
                            setPageIndex={setPageIndex}
                        />
                    </Box>
                </Box>
            </>
        )
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount,
        onHeaderClick: (c: any) => sortColumn(c.id)
    });
}