import { Fragment, useEffect, useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown, BsSearch } from "react-icons/bs";

export const UserCombobox: React.FC<any> = ({
    users,
    disabled,
    update,
    selectedUser
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    if (!users.some((user: any) => user.uduid === "0")) {
        users.unshift({ uduid: "0" })
    }

    useEffect(() => {
        if (selectedUser === 0 || isEmpty(selectedUser)) {
            setSelected(undefined);
        }
    }, [selectedUser]);

    const getDisplayValue = () =>
        !isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` : "";

    const filteredUsers =
        query === ""
            ? users
            : users.filter((user: any) => {
                return (user.udforename?.toLowerCase()?.includes(query.toLowerCase()) ||
                    user.udsurname?.toLowerCase()?.includes(query.toLowerCase())) ||
                    (`${user.udforename?.toLowerCase()} ${user.udsurname?.toLowerCase()}`).includes(query.toLowerCase())
            });

    const updateUser = (e: any) => {
        setSelected(e);
        update(e.uduid);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            onChange={(e: any) => updateUser(e)}
        >
            <Box
                border="1px solid"
                borderColor={!disabled ? "#e2e8f0" : ""}
                rounded="lg"
                style={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
                alignItems="center"
                justifyContent={'center'}
            >
                <HStack
                    ml={2}
                    alignItems="center"
                    width={'100%'}
                >
                    <Avatar
                        size="xs"
                        name={!isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` : ""}
                        color="white"
                        verticalAlign="middle"
                    />

                    <Combobox.Input
                        className="combobox"
                        placeholder="All Users"
                        onChange={(event: any) => {
                            setQuery(event.target.value);
                        }}
                        defaultValue={''}
                        displayValue={getDisplayValue}
                        style={{
                            flexGrow: 1,
                            padding: "4px",
                            backgroundColor: "transparent",
                        }}
                    />

                    <Combobox.Button hidden={disabled}>
                        <Icon
                            as={BsChevronDown}
                            mr={2}
                            color="gray.500"
                            verticalAlign="middle"
                        />
                    </Combobox.Button>
                </HStack>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        width: "100%",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white"
                    }}
                >
                    {
                        filteredUsers.length === 0 && query !== "" ?
                            <HStack mt={2}>
                                <Icon as={BsSearch} color="gray.500" ml={2} />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack>

                            : filteredUsers.map((user: any, i: number) => (
                                <Combobox.Option
                                    key={`${user}${i}`}
                                    value={user}
                                >
                                    {({ active }) => (
                                        <HStack
                                            w="100%"
                                            p={2}
                                            bgColor={active ? "gray.50" : ""}
                                            cursor="pointer"
                                        >
                                            <Avatar
                                                size="xs"
                                                name={!isEmpty(user.udforename) ? `${user.udforename} ${user.udsurname}` : ""}
                                                color="white"
                                            />
                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                textAlign="left"
                                            >
                                                {!isEmpty(user.udforename) ? `${user.udforename} ${user.udsurname}` : user.uduid === '0' ? "All Users" : "Unnamed"}
                                            </Text>
                                        </HStack>
                                    )}
                                </Combobox.Option>
                            ))
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};