import dayjs from "dayjs";
import "dayjs/plugin/localizedFormat";
import "dayjs/plugin/relativeTime";
import { isEmpty } from "lodash";

dayjs.extend(require("dayjs/plugin/localizedFormat"));
dayjs.extend(require("dayjs/plugin/relativeTime"), {
    thresholds: [
        { l: "s", r: 1 },
        { l: "m", r: 1 },
        { l: "mm", r: 59, d: "minute" },
        { l: "h", r: 1 },
        { l: "hh", r: 23, d: "hour" },
        { l: "d", r: 1 },
        { l: "dd", r: 29, d: "day" },
        { l: "M", r: 1 },
        { l: "MM", r: 11, d: "month" },
        { l: "y" },
        { l: "yy", d: "year" }
    ]
});

export const localDate = (input) => isEmpty(input) ? "" : dayjs(input).format("ll");

export const localTime = (input) => isEmpty(input) ? "" : dayjs(input).format("LT");

export const localDateTime = (input) => isEmpty(input) ? "" : dayjs(input).format("lll");

export const relativeTime = (input) => isEmpty(input) ? "" : dayjs(input).fromNow();

export const localDateTimeIgnoreToday = (input) => {
    if (isEmpty(input)) {
        return "";
    }

    const then = dayjs(input);
    const now = new Date();

    if (now.getDate() === then.date() &&
        now.getMonth() === then.month() &&
        now.getFullYear() === then.year()
    ) {
        return then.format("LT");
    }

    return then.format("lll");
};