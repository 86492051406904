import React, { Fragment } from "react";
import {
  Box,
  BoxProps,
  Center,
  Circle,
  createIcon,
  Flex,
  Icon,
  Stack,
  StackProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useStyleConfig,
} from "@chakra-ui/react";
import _ from "lodash";

interface RadioCardGroupProps<T> extends Omit<StackProps, "onChange"> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export const RadioCardGroup = <T extends string>(
  props: RadioCardGroupProps<T>
) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const cards = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<RadioCardProps>>(React.isValidElement)
        .map((card) => {
          return React.cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          });
        }),
    [children, getRadioProps]
  );

  return <Stack {...getRootProps(rest)}>{cards}</Stack>;
};

interface RadioCardProps extends BoxProps {
  value: string;
  showCheck?: boolean;
  selectedColor?: string;
  fillColor?: string;
  radioProps?: UseRadioProps;
}

export const RadioCard = (props: RadioCardProps) => {
  const { radioProps, showCheck, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } =
    useRadio(radioProps);
  const id = useId(undefined, "radio-button");

  const styles = useStyleConfig("RadioCard", props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();

  return (
    <Box
      mt={2}
      w="100%"
      as="label"
      cursor="pointer"
      {...labelProps}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Box
        {...props}
        {...checkboxProps}
        {...rest}
        sx={_.merge(styles, {
          _checked: !props.showCheck
            ? {
                borderColor: props.selectedColor || "#37a169",
                boxShadow: `0px 0px 0px 1px ${
                  props.selectedColor || "#37a169"
                }`,
                bg: props.fillColor || "",
              }
            : {},
        })}
      >
        {!props.showCheck ? (
          <Center>{children}</Center>
        ) : (
          <Stack direction="row">
            <Flex flex="1">{children}</Flex>

            {props.showCheck ? (
              state.isChecked ? (
                <Circle bg="accent" size="4">
                  <Icon as={CheckIcon} boxSize="2.5" color="inverted" />
                </Circle>
              ) : (
                <Circle borderWidth="2px" size="4" />
              )
            ) : (
              <Fragment />
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 12 10",
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="currentColor"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
});
