import {
    Fragment,
    useState
} from "react";
import {
    useNavigate,
} from "react-router-dom";
import {
    Box,
    VStack,
    SimpleGrid,
    Text,
    Button,
    Icon,
    HStack,
    AvatarGroup,
    Tab,
    TabList,
    Tabs,
    TabPanel,
    TabPanels,
    Grid,
    Flex,
    Avatar,
    GridItem,
    Checkbox
} from "@chakra-ui/react";
import { BsArrowLeftCircleFill, BsExclamationCircleFill, BsPeople, BsPlusSquare } from "react-icons/bs";

import dateFormat from "dateformat";

import 'react-image-lightbox/style.css';
import { DropZone } from "../common/DropZone";
import { IncidentHistory } from "./IncidentHistory";

export const Incident: React.FC<any> = () => {
    const navigateTo = useNavigate();

    const [tabIndex, setTabIndex] = useState(0);
    const [message, setMessage] = useState<any>({});

    const peopleInvolved = [
        { "name": "Harry Potter" },
        { "name": "Rodney Mullen" },
        { "name": "Benny Fairfax" },
        { "name": "Chaz Ortiz" },
        { "name": "Tom Penny" },
        { "name": "Ishod Weir" },
        { "name": "Tyshawn Jones" }
    ];

    return (
        <Box>
            <Button
                w="fit-content"
                leftIcon={<BsArrowLeftCircleFill />}
                size="md"
                mb={2}
                variant="outline"
                color="gray.500"
                fontWeight={600}
                onClick={() => {
                    navigateTo("/incidents");
                }}
            >
                All incidents
            </Button>

            <Box
                p={{ base: 0, md: 5 }}
                pt={{ base: 5 }}
                bg="white"
                boxShadow={'lg'}
                rounded={'lg'}
            >
                <SimpleGrid
                    columns={2}
                    mt={2}
                    mb={{ base: 12, md: 5 }}
                >
                    <Text
                        fontWeight={700}
                        fontSize='2xl'
                        color="gray.700"
                        wordBreak="break-word"
                        ml={5}
                    >
                        {
                            message.msgkind === "1" ?
                                <Icon
                                    color="red.500"
                                    fontSize="lg"
                                    mr={2}
                                    as={BsExclamationCircleFill}
                                />

                                : <Fragment></Fragment>
                        }
                        Incident #1
                    </Text>

                    <Text
                        display={{ base: "flex", md: "none" }}
                        mt={1}
                        mr={5}
                        size="sm"
                        color="gray.500"
                        justifySelf="end"
                    >
                        {dateFormat(message.mudatesent, "dddd, mmmm d, yyyy")}
                    </Text>

                    <Box
                        justifyContent="end"
                        display={{ base: "none", md: 'flex' }}
                        mr={5}
                    >
                        <HStack>
                            <Text size="sm" color="gray.500">
                                Reported by
                            </Text>

                            <Avatar
                                size="sm"
                                color="white"
                                name="Harry Potter"
                            />
                        </HStack>
                    </Box>
                </SimpleGrid>

                <Tabs colorScheme="green" onChange={(index) => setTabIndex(index)}>
                    <Flex
                        justify={{ base: "center", md: "space-between" }}
                        mb={{ base: 5, md: 3 }}
                    >
                        <TabList
                            ml={{ base: 0, md: 5 }}
                            w={{ base: "full", md: "unset" }}
                            justifyContent="center"
                        >
                            <Tab>
                                <Text
                                    color={tabIndex === 0 ? "green.500" : "gray.600"}
                                    fontWeight={600}
                                    fontSize={{ base: "xs", sm: "md" }}
                                >
                                    Report
                                </Text>
                            </Tab>
                            <Tab>
                                <Text
                                    color={tabIndex === 1 ? "green.500" : "gray.600"}
                                    fontWeight={600}
                                    fontSize={{ base: "xs", sm: "md" }}
                                >
                                    Investigation
                                </Text>
                            </Tab>
                            <Tab>
                                <Text
                                    color={tabIndex === 2 ? "green.500" : "gray.600"}
                                    fontWeight={600}
                                    fontSize={{ base: "xs", sm: "md" }}
                                >
                                    History
                                </Text>
                            </Tab>
                        </TabList>
                    </Flex>

                    <TabPanels>
                        <TabPanel>
                            <Grid
                                templateColumns={{ base: "repeat(1, 2fr)", md: "repeat(2, 1fr)" }}
                                gap={8}
                                mb={{ base: 8, md: 1 }}
                            >
                                <GridItem>
                                    <VStack gap={8} alignItems="start">
                                        <HStack gap={8}>
                                            <Box>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    color="gray.500"
                                                    mb={2}
                                                >
                                                    Occurred on
                                                </Text>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="md"
                                                    color="gray.700"
                                                    wordBreak="break-word"
                                                >
                                                    {dateFormat(message.mudatesent, "dddd, mmmm d, yyyy")}
                                                </Text>
                                            </Box>

                                            <Box>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    color="gray.500"
                                                    mb={2}
                                                >
                                                    Investigated on
                                                </Text>
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="md"
                                                    color="gray.700"
                                                    wordBreak="break-word"
                                                >
                                                    {dateFormat(message.mudatesent, "dddd, mmmm d, yyyy")}
                                                </Text>
                                            </Box>
                                        </HStack>

                                        <Box>
                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.500"
                                                mb={2}
                                            >
                                                Description
                                            </Text>
                                            <Text
                                                fontWeight={500}
                                                fontSize="md"
                                                color="gray.700"
                                                wordBreak="break-word"
                                            >
                                                Blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah...
                                            </Text>
                                        </Box>

                                        <Box
                                            p={2}
                                            pb={1}
                                            borderRadius="8px"
                                            backgroundColor="#F0FFF4"
                                        >
                                            <Checkbox
                                                borderColor="gray.500"
                                                onChange={() => { }}
                                                isChecked={true}
                                            >
                                                <Text
                                                    fontWeight={600}
                                                    fontSize="sm"
                                                    color="gray.700"
                                                >
                                                    Is this RIDDOR reportable?
                                                </Text>
                                            </Checkbox>
                                        </Box>

                                        <Box
                                            w="full"
                                            border={{ base: "none", md: "1px" }}
                                            borderRadius="8px"
                                            borderColor={{ md: "gray.200" }}
                                            p={3}
                                        >
                                            <VStack alignItems="start">
                                                <HStack mt={2} ml={3}>
                                                    <Icon
                                                        as={BsPeople}
                                                        mr={1}
                                                        color="gray.600"
                                                        fontSize="2xl"
                                                    />
                                                    <Text
                                                        fontSize="md"
                                                        fontWeight={700}
                                                        color="gray.600"
                                                    >
                                                        People involved
                                                    </Text>
                                                </HStack>

                                                <Box
                                                    w="full"
                                                    p={3}
                                                    backgroundColor="gray.50"
                                                >
                                                    <AvatarGroup size='md' max={6}>
                                                        {
                                                            peopleInvolved.map((p) => {
                                                                return <Avatar
                                                                    size="sm"
                                                                    color="white"
                                                                    name={p.name}
                                                                />
                                                            })

                                                        }
                                                    </AvatarGroup>
                                                </Box>

                                                <Button
                                                    variant="outline"
                                                    w="full"
                                                    mt={5}
                                                >
                                                    <Text
                                                        mr={2}
                                                        color="gray.700"
                                                        fontWeight={600}
                                                    >
                                                        Add people
                                                    </Text>
                                                    <Icon as={BsPlusSquare} color="gray.700" />
                                                </Button>
                                            </VStack>
                                        </Box>

                                    </VStack>
                                </GridItem>

                                <GridItem>
                                    <Box w="full" textAlign="start">
                                        <DropZone
                                            onDropAction={() => { }}
                                            w="full"
                                            fileUploadLoading={false}
                                        />
                                    </Box>
                                </GridItem>
                            </Grid>
                        </TabPanel>

                        <TabPanel>
                            <Box
                                px={{ base: 1, md: 5 }}
                                py={{ base: 1, md: 1 }}
                                border="1px"
                                borderRadius="12px"
                                borderColor="gray.200"
                            >
                                <Fragment>
                                    <Grid
                                        templateColumns={{ base: "repeat(1, 2fr)", md: "repeat(2, 1fr)" }}
                                        gap={5}
                                        // mb={{ base: 8, md: 12 }}
                                    >

                                    </Grid>

                                    <VStack display={{ base: "flex", lg: "none" }}>

                                    </VStack>
                                </Fragment>
                            </Box>
                        </TabPanel>

                        <TabPanel>
                            <IncidentHistory />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}