import { get, post, getWithParams } from "../../api/axiosClient";

export async function createIncident(incidentDetails: any): Promise<any> {
    let createIncidentResponse: any = {};

    await post("incidents/incident", {
        teamid: incidentDetails?.teamid,
        formid: incidentDetails?.formid
    })
        .then((res: any) => {
            createIncidentResponse = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return createIncidentResponse;
}

export async function getIncidentForms(): Promise<any> {
    let getIncidentFormsResponse: any = {};

    await get("incidents/forms")
        .then((res: any) => {
            getIncidentFormsResponse = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return getIncidentFormsResponse;
}

export async function getIncidents(
    filter: string,
    search: string,
    sort: string,
    team: number,
    form: number,
    page: string,
    pagesize: string = "6"
): Promise<any> {
    let getIncidentsResponse: any = {
        "pageCount": 0,
        "incidents": []
    };

    await getWithParams("incidents/all", { filter, search, sort, team, form, page, pagesize })
        .then((res: any) => {

            if (!res?.data?.success){
                throw new Error(res?.data?.message);
            }

            getIncidentsResponse.incidents = res.data.incidents;
            getIncidentsResponse.pageCount = res.data.pageCount;
        })
        .catch((error: any) => {
            throw error;
        });

    return getIncidentsResponse;
}

