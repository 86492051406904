import { createSlice } from '@reduxjs/toolkit';

const createInitialState = (loggingOut: boolean = false): any => {
    return {
        userId: 0,
        error: null,
        orgnewobbi: "0", // 0 = Classic, 1 = User Preference, 2 = New Obbi
        user: {
            loggingOut,
            id: 0,
            forename: "",
            surname: "",
            username: "",
            email: "",
            jobrole: "",
            orgid: "",
            orgname: "",
            has2FA: false,
            emailVerified: false,
            termsAccepted: false,
            mustChangePassword: false,
            passwordAboutToExpire: false,
            daysUntilPasswordExpires: 0,
            isAdmin: false,
            isTeamManager: false,
            permissions: [],
            interface: "0", // 0 = Classic, 1 = New Obbi
        }
    }
};

const authSlice = createSlice({
    name: "auth",
    initialState: createInitialState(),
    reducers: {
        logout: () => createInitialState(true),
        loginSuccess: (state, { payload }) => {
            state.userId = payload.userid;
            state.error = null;
            state.orgnewobbi = payload.orgnewobbi;
            state.user = {
                id: payload.userid,
                forename: payload.forename,
                surname: payload.surname,
                email: payload.email,
                jobrole: payload.jobrole,
                orgid: payload.orgid,
                orgname: payload.orgname,
                has2FA: payload.has2FA,
                emailVerified: payload.emailVerified,
                termsAccepted: payload.termsAccepted,
                mustChangePassword: payload.mustChangePassword,
                passwordAboutToExpire: payload.passwordAboutToExpire,
                daysUntilPasswordExpires: payload.daysUntilPasswordExpires,
                isAdmin: payload.isAdmin,
                isTeamManager: payload.isTeamManager,
                permissions: payload.permissions,
                interface: payload.interface
            }
        },
        loginFailure: (state, { payload }) => {
            state = {
                ...createInitialState(),
                error: payload.error,
            };
        },
        verifyEmailExists: (state, { payload }) => {
            state.user.email = payload;
        },
        verifyEmail: (state) => {
            state.user.emailVerified = true;
        },
        verifyTerms: (state) => {
            state.user.termsAccepted = true;
        },
        updatePasswordExpiryStatus: (state, { payload }) => {
            state.user.passwordAboutToExpire = payload.passwordAboutToExpire;
            state.user.daysUntilPasswordExpires = payload.daysUntilPasswordExpires;
        }
    }
});

export const {
    loginSuccess,
    loginFailure,
    logout,
    verifyEmail,
    verifyTerms,
    verifyEmailExists,
    updatePasswordExpiryStatus
} = authSlice.actions;

export const authReducer = authSlice.reducer;