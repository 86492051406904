import {
	Avatar,
	Box,
	Button,
	HStack,
	SlideFade,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { localDate } from "../../../helpers/DayJsHelper";

export const GuidedObservationsTable: React.FC<any> = ({
	navData,
	attemptData,
	teamTaskId,
	teamId,
	taskId,
	cardStatus,
	startAttempt
}) => {
	const navigateTo = useNavigate();

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "targetUserId",
				disableSortBy: true
			},
			{
				Header: "",
				accessor: "attemptId",
				disableSortBy: true
			},
			{
				Header: "USER TO OBSERVE",
				accessor: "name",
				disableSortBy: true,
				Cell: (cell: any) => <HStack>
					<Avatar name={cell.value} size="xs" color="white" />
					<Text color="gray.600" fontSize="sm" >
						{cell.value}
					</Text>
				</HStack>
			},
			{
				Header: "DATE STARTED",
				accessor: "started",
				sortType: "basic",
				Cell: (cell: any) => {
					if (cell.value) {
						return (
							<VStack alignItems="left">
								<Text color="gray.600" fontSize="sm">
									{localDate(cell.value)}
								</Text>
							</VStack>
						);
					} else return <Text color="gray.500">-</Text>;
				}
			},
			{
				Header: "",
				accessor: "action",
				disableSortBy: true,
				Cell: (cell: any) =>
					<Button
						float="right"
						minW={40}
						onClick={() => {
							actionClicked(cell?.row?.values)
						}}
					>
						<Text color="gray.600" fontSize="sm">
							{cell.value}
						</Text>
					</Button>
			}
		], []
	);

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick,
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["id", "targetUserId", "attemptId"]
				},
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading} offsetY="20px">

				{/* mobile view */}
				<VStack
					display={{ base: "flex", xl: "none" }}
					spacing={3}
					m={2}
				>
					{
						attemptData?.map((attempt: any, i: number) => (
							<Box
								w="full"
								p={2}
								className="hover-pop"
								cursor="pointer"
								backgroundColor={i % 2 ? "white" : "gray.50"}
								boxShadow="xs"
								border="1px solid"
								borderColor="gray.100"
								rounded="lg"
							>
								<VStack alignItems="start" mx={2}>
									<HStack
										fontSize="sm"
										fontWeight={500}
										color="gray.600"
										textAlign="start"
									>
										<Text>Observing</Text>
										<Avatar name={attempt?.name} size="xs" color="white" />
										<Text>{attempt?.name}</Text>
									</HStack>

									<Text
										fontSize="xs"
										fontWeight={500}
										color="gray.500"
										textAlign="start"
									>
										Started {localDate(attempt.started)}
									</Text>

									<Button
										my={2}
										w={'100%'}
										onClick={() => {
											actionClicked(attempt);
										}}
									>
										<Text color="gray.600" fontSize="sm" >
											{attempt.action}
										</Text>
									</Button>
								</VStack>
							</Box>
						))
					}
				</VStack>

				{/* desktop view */}
				<Table
					{...getTableProps()}
					id="GuidedObservationsTable"
					variant="striped"
					display={{ base: "none", xl: "inline-table" }}
				>
					<Thead>
						{
							headerGroups.map((headerGroup) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<Th
											{...column.getHeaderProps()}
											onClick={() => onHeaderClick(column)}
										>
											<Text fontWeight={700} color="gray.600">
												{column.render("Header")}
											</Text>
										</Th>
									))}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.map((row, i) => {
								prepareRow(row);
								return (
									<Tr
										{...row.getRowProps()}
										key={i}
										borderBottom="1px solid"
										borderColor={"gray.100"}
									>
										{
											row.cells.map((cell) => (
												<Td wordBreak="break-word" {...cell.getCellProps()}>
													{cell.render("Cell")}
												</Td>
											))
										}
									</Tr>
								);
							})
						}
					</Tbody>
				</Table>
			</SlideFade>
		);
	}

	function actionClicked(values: any) {
		if (values?.action === "Start") {
			startAttempt(values?.targetUserId)
		}

		if (values?.action === "Resume") {
			if (values?.attemptId) {
				navigateTo(`/tasks/task/${values.attemptId}`, {
					state: {
						taskId: taskId,
						teamId: teamId,
						teamTaskId: teamTaskId,
						cardStatus: cardStatus,
						from: 'tasks',
						navData: navData
					},
				});
			}
		}
	}

	return DataTable({
		data: attemptData ?? [],
		columns
	});
};