import {
    Box,
    CloseButton,
    HStack,
    GridItem,
    Image,
    Spacer,
    Spinner,
    Text,
    VStack
} from "@chakra-ui/react";
import { isNull } from "lodash";

export const InsightActionCard: React.FC<any> = (
    {
        title,
        count,
        image,
        color,
        onClickAction,
        onClearAction,
        active,
        loading,
        colSpan = null
    }) => {

    return (
        <>
            <GridItem
                colSpan={{ base: colSpan, lg: undefined }}
                display={{ base: "none", md: "block" }}
                w="100%"
                p={2} pt={0}
                rounded="lg"
                border="1px solid"
                bgColor={active ? "white" : "gray.50"}
                borderColor={active ? "brand.500" : "gray.200"}
                boxShadow={!active ? "xs" : ""}
                className={!active ? "hover-pop" : ""}
                cursor="pointer"
                onClick={onClickAction}
            >
                <HStack>
                    <VStack alignItems="start">
                        {
                            loading ? <Spinner
                                size="md"
                                my={3}
                                thickness="3px"
                                color={color || "brand.500"}
                            /> :
                                <Text
                                    fontSize="3xl"
                                    fontWeight={700}
                                    textAlign="left"
                                    color={count > 0 ? color : "gray.500"}
                                >
                                    {count}
                                </Text>
                        }

                        <Text
                            fontSize="sm"
                            fontWeight={600}
                            textAlign="left"
                            color="gray.600"
                            marginTop="0px!important"
                        >
                            {title}
                        </Text>
                    </VStack>

                    <Spacer />

                    {
                        active ? <CloseButton color="gray.600" _hover={{ bg: "" }} /> :
                            <Image
                                display={{ base: "none", sm: "block" }}
                                height="40px"
                                src={image}
                                ml={{ base: 2, md: 5 }}
                                mr={{ base: 6, md: 12 }}
                            />
                    }
                </HStack>
            </GridItem>

            <GridItem
                colSpan={colSpan}
                display={{ base: "block", md: "none" }}
                minH="auto"
                w="100%"
                p={2}
                rounded="lg"
                border="1px solid"
                bgColor={active ? "white" : "gray.50"}
                borderColor={active ? "brand.500" : "gray.200"}
                boxShadow="xs"
                className={!active ? "hover-pop" : ""}
                cursor="pointer"
                onClick={onClickAction}
            >
                <VStack>
                    {
                        loading ? <Spinner
                            mb={3}
                            size="md"
                            thickness="3px"
                            color={color || "brand.500"}
                        /> :
                            <Text
                                w="full"
                                fontSize={{ base: "xl", md: "2xl" }}
                                fontWeight={700}
                                textAlign="center"
                                color={count > 0 ? color : "gray.500"}
                            >
                                {count}
                            </Text>
                    }

                    <Text
                        w="full"
                        fontSize={{ base: "xs", md: "sm" }}
                        fontWeight={500}
                        textAlign="center"
                        color="gray.600"
                        marginTop="0px!important"
                    >
                        {title}
                    </Text>
                </VStack>
            </GridItem>
        </>
    )
};