import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Icon,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { CreateIncident } from './CreateIncident';
import { IncidentHistory } from './IncidentHistory';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { BsArrowLeftCircleFill, BsConeStriped, BsXCircleFill } from 'react-icons/bs';
import { isUndefined } from 'lodash';

export const Incidents = () => {
    const { state }: any = useLocation();
    const user = useSelector((state: RootState) => state.authReducer.user);
    const navigateTo = useNavigate();

    const [pageData, setPageData] = useState({
        tabIndex: 0,
        pageIndex: 0,
        pageCount: 0,
    });
    const [tabIndex, setTabIndex] = useState(state?.tabIndex || 0);

    const [clearAllMyIncidentsFilters, setClearAllMyIncidentsFilters] = useState(false);
    const [clearAllManagedIncidentsFilters, setClearAllManagedIncidentsFilters] = useState(false);
    const [myIncidentsFilterSelected, setMyIncidentsFilterSelected] = useState(false);
    const [myManagedIncidentsFilterSelected, setMyManagedIncidentsFilterSelected] = useState(false);

    function clearAllFiltersPressed() {
        if (tabIndex === 1) {
            //my incidents tab is open
            setClearAllMyIncidentsFilters(true);
        }
        else if (tabIndex === 2) {
            //my managed incidents tab is open
            setClearAllManagedIncidentsFilters(true);
        }
    }

    const onTabIndexChange = async (index: number) => {
        setTabIndex(index);
        setPageData({
            ...pageData,
            tabIndex: index,
            pageIndex: 0,
        });
    };

    return (
        <Box>
            <Box m={{ base: 0, md: 5 }}>
                <Button
                    display={{ base: "none", md: "unset" }}
                    w="fit-content"
                    mb={2}
                    hidden={isUndefined(state?.from)}
                    size="sm"
                    variant="outline"
                    color="gray.600"
                    fontWeight={600}
                    boxShadow="xs"
                    onClick={() => {
                        navigateTo(-1);
                    }}
                >
                    <HStack>
                        <Icon as={BsArrowLeftCircleFill} />
                        <Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
                    </HStack>
                </Button>

                <Box
                    px={{ base: 0, md: 5 }}
                    pt={5}
                    pb={3}
                    minH={{ base: "100vh", md: "auto" }}
                    bg={useColorModeValue("white", "gray.900")}
                    boxShadow={"lg"}
                    rounded={"lg"}
                >
                    <Button
                        display={{ base: "unset", md: "none" }}
                        hidden={isUndefined(state?.from)}
                        size="sm"
                        m={5}
                        textAlign="start"
                        color="gray.600"
                        variant="link"
                        fontWeight={600}
                        onClick={() => {
                            navigateTo(-1);
                        }}
                    >
                        <HStack>
                            <Icon as={BsArrowLeftCircleFill} />
                            <Text>{state?.from === "home" ? "Back to Home" : state?.from === "notification" && "Back to Notification"}</Text>
                        </HStack>
                    </Button>

                    <HStack mb={5}>
                        <Icon
                            as={BsConeStriped}
                            mx={5}
                            verticalAlign="middle"
                            fontSize="4xl"
                            color="green.500"
                        />
                        <Text
                            fontWeight={{ base: 600, lg: 700 }}
                            fontSize={{ base: "lg", lg: "2xl" }}
                            color="gray.700"
                        >
                            Incidents
                        </Text>

                        <Spacer />

                        {
                            (
                                (tabIndex === 1 && myIncidentsFilterSelected) ||
                                (tabIndex === 2 && myManagedIncidentsFilterSelected)
                            ) &&
                            <Button
                                variant="ghost"
                                size="xs"
                                onClick={clearAllFiltersPressed}
                            >
                                <HStack>
                                    <Icon as={BsXCircleFill} color="gray.600" fontSize="sm" />
                                    <Text>Clear All Filters</Text>
                                </HStack>
                            </Button>
                        }

                    </HStack>

                    <Divider
                        w="unset"
                        mx={{ base: 0, md: -5 }}
                    />

                    <Tabs colorScheme="green" onChange={(index) => onTabIndexChange(index)}>
                        <Flex
                            justify={{ base: "center", md: "space-between" }}
                            py={2}
                        >
                            <TabList
                                mx={{ base: 0, md: 5 }}
                                w={{ base: "full", md: "unset" }}
                                justifyContent="center"
                            >
                                <Tab>
                                    <Text
                                        color={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
                                        fontWeight={pageData.tabIndex === 0 ? 700 : 500}
                                    >
                                        Report
                                    </Text>
                                </Tab>
                                <Tab>
                                    <Text
                                        color={pageData.tabIndex === 1 ? "green.500" : "gray.500"}
                                        fontWeight={pageData.tabIndex === 1 ? 700 : 500}
                                    >
                                        My Incidents
                                    </Text>
                                </Tab>

                                {
                                    user?.isTeamManager &&
                                    <Tab>
                                        <Text
                                            color={pageData.tabIndex === 2 ? "green.500" : "gray.500"}
                                            fontWeight={pageData.tabIndex === 2 ? 700 : 500}
                                        >
                                            Managed Incidents
                                        </Text>
                                    </Tab>
                                }
                            </TabList>
                        </Flex>

                        <TabPanels>
                            <TabPanel>
                                <CreateIncident />
                            </TabPanel>

                            <TabPanel>
                                <IncidentHistory
                                    filter={'my-incindents'}
                                    clearAllFilters={clearAllMyIncidentsFilters}
                                    setClearAllFilters={setClearAllMyIncidentsFilters}
                                    setFilterSelected={setMyIncidentsFilterSelected}
                                />
                            </TabPanel>
                            
                            <TabPanel>
                                <IncidentHistory
                                    filter={'managed'}
                                    clearAllFilters={clearAllManagedIncidentsFilters}
                                    setClearAllFilters={setClearAllManagedIncidentsFilters}
                                    setFilterSelected={setMyManagedIncidentsFilterSelected}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Box>
        </Box>
    )
};