import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _, { isEmpty } from "lodash";

import { Box, Grid, HStack, Button, Square, Text, Icon, Image, SimpleGrid, GridItem, VStack, Avatar, Spacer, Badge, Stack, useBreakpointValue, SlideFade } from "@chakra-ui/react";
import { BsCheckCircle, BsDashCircle, BsExclamationCircle, BsExclamationTriangle, BsExclamationTriangleFill, BsPeople, BsPlayCircle, BsStopCircle, BsWrench } from "react-icons/bs";

import { IoSkullOutline } from "react-icons/io5";
import { getHazard } from "../../features/riskAssessments/riskAssessments.actions";
import { localDate } from "../../helpers/DayJsHelper";

import smile_green from "../../assets/images/smile_green.png";
import smile_yellow from "../../assets/images/smile_yellow.png";
import smile_orange from "../../assets/images/smile_orange.png";
import smile_red from "../../assets/images/smile_red.png";
import smile_black from "../../assets/images/smile_black.png";

export const HazardReader: React.FC<any> = ({
    publishing = false,
    hazard,
    riskAssessmentId,
    riskAssessmentVersionId
}) => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [loading, setLoading] = useState<boolean>(true);

    const [controls, setControls] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);

    const [risk, setRisk] = useState({ riskText: "", score: 0 });

    const [hazardData, setHazardData] = useState<any>({
        rahid: "",
        rahdescription: "N/A",
        rahriskcategory: "N/A",
        rahpeopleaffected: "None",
        rahconsequences: "None",
        controls: []
    });

    const getColor = (risk: any) => (
        risk > 0 && risk < 5 ? "#37a169" :
            risk > 4 && risk < 11 ? "#D69E2E" :
                risk > 10 && risk < 16 ? "#ED8936" :
                    risk > 15 && risk < 21 ? "#E53E3E" :
                        "#1A202C"
    );

    const getControlIcon = (risk: any) => (
        risk > 0 && risk < 5 ? smile_green :
            risk > 4 && risk < 11 ? smile_yellow :
                risk > 10 && risk < 16 ? smile_orange :
                    risk > 15 && risk < 21 ? smile_red :
                        smile_black
    );

    const getHazardFunc = useCallback(async () => {
        setLoading(true);

        getHazard(
            riskAssessmentId,
            riskAssessmentVersionId,
            hazard?.rahid
        )
            .then((res: any) => {
                setHazardData(res.hazard);
                setControls(res.hazard?.controls || []);
                setActions(res.hazard?.actions || []);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => { setLoading(false); });
    }, []);

    useEffect(() => {
        setRisk({
            ...risk,
            riskText: hazard?.rahriskcategory || "",
            score: Number(hazard?.rahrisklevel) || 0
        });

        if (publishing) {
            setHazardData(hazard);
            setControls(hazard?.controls);
            setActions(hazard?.actions);
            setLoading(false);
        } else {
            getHazardFunc();
        }
    }, []);

    return (
        <Box w="full">
            <SlideFade in={!loading}>
                <Box
                    pt={{ base: 1.5, md: 3 }}
                    pb={{ base: 3, md: 3 }}
                    px={{ base: 3, md: 5 }}
                    mx={{ base: 0, md: -5 }}
                    bg={getColor(hazardData?.rahrisklevel)}
                    color="white"
                >
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        mx={0}
                        w="unset"
                    >
                        <VStack alignItems="start" mt={{ base: 0, md: "7px" }}>
                            <HStack fontWeight={600}>
                                {
                                    !isMobile && <Icon as={BsExclamationTriangle} fontSize="md" color="white" />
                                }
                                <Text color="white" fontSize={{ base: "md", md: "lg" }}>{hazardData?.rahdescription}</Text>
                            </HStack>
                        </VStack>

                        <Spacer />

                        <HStack gap={2}>
                            <Text
                                color="white"
                                fontWeight={600}
                            >
                                Risk
                            </Text>

                            <Button
                                variant="link"
                                size="xs"
                                border="1px solid"
                                borderColor="white"
                                bg="white"
                                cursor='default'
                            >
                                <Square
                                    rounded="7px"
                                    size="40px"
                                    bg={getColor(hazardData?.rahrisklevel)}
                                >
                                    {
                                        (hazardData?.rahriskcategory.indexOf("Fatal") > -1) && <Icon
                                            as={IoSkullOutline}
                                            color="white"
                                            mr="2px"
                                        />
                                    }
                                    <Text
                                        color="white"
                                        fontWeight={700}
                                        fontSize={(hazard?.rahriskcategory.indexOf("Fatal") > -1) ? "10pt" : "lg"}
                                    >
                                        {hazardData?.rahrisklevel}
                                    </Text>
                                </Square>

                                <Text
                                    color={getColor(hazardData?.rahrisklevel)}
                                    fontSize="10pt"
                                    fontWeight={600}
                                    mx={3}
                                >
                                    {hazardData?.rahriskcategory}
                                </Text>
                            </Button>
                        </HStack>
                    </Stack>
                </Box>

                <Box
                    p={{ base: 3, md: 5 }}
                    pt={4}
                    pb={isMobile ? 3 : 1}
                    mx={{ base: 0, md: -5 }}
                    bg="white"
                    roundedBottom="lg"
                >
                    <Grid
                        templateColumns={{ md: 'repeat(1, 1fr)', xl: 'repeat(2, 2fr)' }}
                        gap={6}
                    >
                        <GridItem>
                            <HStack mb={2} fontSize={{ base: "sm", md: "md" }}>
                                <Icon as={BsPeople} color="gray.600" />
                                <Text color="gray.600" fontWeight={600}>People Affected</Text>
                            </HStack>

                            <Text color="gray.500" fontSize={{ base: "xs", md: "11pt" }} fontWeight={500} mt={2}>
                                {hazardData?.rahpeopleaffected}
                            </Text>
                        </GridItem>

                        <GridItem>
                        <HStack mb={2} fontSize={{ base: "sm", md: "md" }}>
                                <Icon as={BsExclamationCircle} color="gray.600" />
                                <Text color="gray.600" fontWeight={600}>Consequences</Text>
                            </HStack>

                            <Text color="gray.500" fontSize={{ base: "xs", md: "11pt" }} fontWeight={500} mt={2}>
                                {hazardData?.rahconsequences}
                            </Text>
                        </GridItem>

                        <Box>
                            <Text
                                color="gray.600"
                                fontWeight={600}
                                fontSize={{ base: "sm", md: "md" }}
                                mb={2}
                            >
                                Existing Safety Controls
                            </Text>

                            {
                                isEmpty(controls) ?
                                    <Box
                                        textAlign="center"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                        p={3}
                                        mb={{ base: 0, md: 0 }}
                                    >
                                        <VStack gap={2}>
                                            <Box w="full">
                                                <Text
                                                    color="gray.600"
                                                    fontSize={{ base: "sm", md: "lg" }}
                                                    fontWeight={600}
                                                >
                                                    No Controls
                                                </Text>

                                                <Text
                                                    color="gray.500"
                                                    fontSize={{ base: "9pt", md: "sm" }}
                                                    fontWeight={400}
                                                    mt={1}
                                                >
                                                    No Controls have been added to this Hazard
                                                </Text>
                                            </Box>
                                        </VStack>
                                    </Box> :

                                    <SimpleGrid
                                        columns={1}
                                        gap={3}
                                        mb={{ base: 0, md: 5 }}
                                    >
                                        {
                                            controls.map((c: any, index: any) => {
                                                return <HStack w="full" key={index}>
                                                    <Image
                                                        w="16px"
                                                        mt="7px"
                                                        alignSelf="baseline"
                                                        src={getControlIcon(risk.score)}
                                                    />

                                                    <Text
                                                        color="gray.500"
                                                        textAlign="left"
                                                        fontSize={{ base: "xs", md: "11pt" }}
                                                        fontWeight={500}
                                                        wordBreak="break-word"
                                                        whiteSpace="break-spaces"
                                                    >
                                                        {c.racdescription}
                                                    </Text>
                                                </HStack>
                                            })
                                        }
                                    </SimpleGrid>
                            }
                        </Box>

                        <Box w="full" mb={0}>
                            <Text
                                color="gray.600"
                                fontWeight={600}
                                fontSize={{ base: "sm", md: "md" }}
                                mb={2}
                            >
                                Follow up Actions
                            </Text>

                            {
                                isEmpty(actions) ?
                                    <Box
                                        textAlign="center"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                        p={3}
                                        mb={{ base: 4, md: 0 }}
                                    >
                                        <VStack gap={2}>
                                            <Box w="full">
                                                <Text
                                                    color="gray.600"
                                                    fontSize={{ base: "sm", md: "lg" }}
                                                    fontWeight={600}
                                                >
                                                    No Actions
                                                </Text>

                                                <Text
                                                    color="gray.500"
                                                    fontSize={{ base: "9pt", md: "sm" }}
                                                    fontWeight={400}
                                                    mt={1}
                                                >
                                                    No Actions have been added to this Hazard
                                                </Text>
                                            </Box>
                                        </VStack>
                                    </Box> :

                                    <SimpleGrid columns={1} gap={3} mb={{ base: 2, md: 0 }}>
                                        {
                                            actions.map((a: any, index: any) => {
                                                return <Box
                                                    key={index}
                                                    border="1px solid"
                                                    borderColor="gray.200"
                                                    rounded="lg"
                                                    p={{ base: "8px", md: "11.5px" }}
                                                 
                                                >
                                                    <VStack alignItems="start">
                                                        <HStack w="full">
                                                            <Icon
                                                                as={BsWrench}
                                                                color={getColor(risk.score)}
                                                            />
                                                            <Text
                                                                color="gray.500"
                                                                textAlign="left"
                                                                fontWeight={500}
                                                                fontSize={{ base: "xs", md: "11pt" }}
                                                                wordBreak="break-word"
                                                                whiteSpace="break-spaces"
                                                                cursor="pointer"
                                                                _hover={{
                                                                    textDecoration: "underline"
                                                                }}
                                                                onClick={() => {
                                                                    navigateTo(`/actions/action/${a?.action?.actionid}`,
                                                                        { state: { from: "riskassessment" } }
                                                                    );
                                                                }}
                                                            >
                                                                {a.raadescription}
                                                            </Text>
                                                        </HStack>

                                                        <HStack w="full" mt={1}>
                                                            <HStack w="full">
                                                                <Avatar name={a?.action?.teamname || null} size={isMobile ? "2xs" : "xs"} mt={isMobile ? -0.5 : 0} />
                                                                <Text
                                                                    color="gray.500"
                                                                    fontSize={{ base: "8pt", md: "11pt" }}
                                                                    fontWeight={500}
                                                                >
                                                                    {a?.action?.teamname || "Unassigned"}
                                                                </Text>
                                                            </HStack>

                                                            <Spacer />

                                                            <Text
                                                                w="full"
                                                                color="gray.500"
                                                                fontSize={{ base: "8pt", md: "11pt" }}
                                                                fontWeight={500}
                                                            >
                                                                {localDate(a?.action?.duedate) || "No due date"}
                                                            </Text>

                                                            <Spacer />

                                                            <Badge
                                                                size="sm"
                                                                color={
                                                                    a?.action?.actionstatus === "0" ? "gray.600" :
                                                                        a?.action?.actionstatus === "1" ? "telegram" :
                                                                            a?.action?.actionstatus === "2" ? "red.700" :
                                                                                a?.action?.actionstatus === "99" ? "green.700" :
                                                                                    "gray.600"
                                                                }
                                                                colorScheme={
                                                                    a?.action?.actionstatus === "0" ? "gray" :
                                                                        a?.action?.actionstatus === "1" ? "telegram" :
                                                                            a?.action?.actionstatus === "2" ? "danger" :
                                                                                a?.action?.actionstatus === "99" ? "green" :
                                                                                    "gray"
                                                                }
                                                            >
                                                                <HStack>
                                                                    <Icon
                                                                        as={
                                                                            a?.action?.actionstatus === "0" ? BsDashCircle :
                                                                                a?.action?.actionstatus === "1" ? BsPlayCircle :
                                                                                    a?.action?.actionstatus === "2" ? BsStopCircle :
                                                                                        a?.action?.actionstatus === "99" ? BsCheckCircle :
                                                                                            BsDashCircle
                                                                        }
                                                                    />
                                                                    <Text fontWeight={500} fontSize={{ base: "8pt", md: "11pt" }}>
                                                                        {a.statustext}
                                                                    </Text>
                                                                </HStack>
                                                            </Badge>
                                                        </HStack>
                                                    </VStack>
                                                </Box>
                                            })
                                        }
                                    </SimpleGrid>
                            }
                        </Box>
                    </Grid>
                </Box>
            </SlideFade>
        </Box>
    );
};