import { useEffect } from "react";
import { Box, SlideFade } from "@chakra-ui/react";

import { Item } from "./Item";

export const Page: React.FC<any> = ({
	currentPage,
	attemptData,
	setProgressState,
}) => {
	useEffect(() => { }, [currentPage.items]);

	return (
		<SlideFade in={true} offsetY="20px">
			<Box mx={{ base: 3, md: 0 }}>
				{
					currentPage.items.map((item: any, i: number) => {
						return (
							<Item
								attemptId={Number(attemptData.ttaid)}
								itemId={Number(item.tiid)}
								itemIndex={i}
								currentPage={currentPage}
								setProgressState={setProgressState}
							/>
						);
					})
				}
			</Box>
		</SlideFade>
	);
};