import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import {
	Avatar,
	Box,
	HStack,
	Icon,
	SlideFade,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from "@chakra-ui/react";
import { BsExclamationCircleFill } from "react-icons/bs";

export const MandatoryTaskTable: React.FC<any> = ({
	currentItems,
	loading,
	navData
}) => {
	const navigateTo = useNavigate();

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "taskId",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "teamId",
				disableSortBy: true,
			},
			{
				Header: "TASK",
				accessor: "taskName",
				sortType: "basic",
				Cell: (cell: any) => <HStack>
					<Icon
						as={BsExclamationCircleFill}
						color="purple.500"
					/>

					<Text color="gray.600" fontSize="sm">
						{cell.value || "Untitled"}
					</Text>
				</HStack>
			},
			{
				Header: "DUE",
				accessor: "due",
				sortType: "basic",
				Cell: (cell: any) => <Text color="gray.600" fontSize="sm">
					{cell.value}
				</Text>
			},
			{
				Header: "TEAM",
				accessor: "assigned",
				disableSortBy: true,
				Cell: (cell: any) => <HStack>
					<Avatar name={cell.value} size="xs" color="white" />
					<Text color="gray.600" fontSize="sm">
						{cell.value}
					</Text>
				</HStack>
			}
		], []
	);

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: [
						"id",
						"teamId",
						"taskId",
						navData.isGuided && "lastReport",
						navData.isGuided && "due",
						!navData.isGuided && "requiredCount",
						!navData.isGuided && "count"
					]
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading}>
				<VStack display={{ base: "flex", lg: "none" }}>
					{
						currentItems.map((task: any, i: number) => (
							<Box
								key={i}
								p={3}
								w="full"
								className="hover-pop"
								cursor="pointer"
								border="1px solid"
								borderColor="purple.100"
								backgroundColor="purple.50"
								borderRadius="8px"
								boxShadow="xs"
								onClick={() => {
									navigateTo(`/tasks/tasksummary/${task.id}`, {
										state: {
											navData: navData,
											from: "tasks"
										}
									});
								}}
							>
								<VStack alignItems="start">
									<Text
										fontSize="md"
										fontWeight={600}
										color="purple.600"
										textAlign="start"
									>
										{task.taskName || "Untitled"}
									</Text>

									<HStack>
										<Avatar name={task.assigned} size="xs" />
										<Text
											fontSize="sm"
											fontWeight={400}
											color="gray.600"
											textAlign="start"
										>
											{task.assigned}
										</Text>
									</HStack>

									{
										!navData.isGuided &&
										task.due && <Text
											fontSize="xs"
											color="gray.500"
											textAlign="start"
										>
											{`Due ${task.due}`}
										</Text>
									}
								</VStack>
							</Box>
						))}
				</VStack>

				<Table
					{...getTableProps()}
					id="TaskTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
				>
					<Thead>
						{
							headerGroups.map((headerGroup, i) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => onHeaderClick(column)}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.map((row, i) => {
								prepareRow(row);

								return (
									<Tr
										{...row.getRowProps()}
										style={{ backgroundColor: "red!important" }}
										className="hover-pop"
										key={i}
										borderBottom="1px solid"
										borderColor="gray.100"
										cursor="pointer"
										rounded="lg"
										onClick={() => {
											navigateTo(`/tasks/tasksummary/${row.values.id}`, {
												state: {
													navData: navData,
													from: "tasks"
												}
											});
										}}
									>
										{
											row.cells.map((cell) => {
												return (
													<Td wordBreak="break-word" {...cell.getCellProps()}>
														{cell.render("Cell")}
													</Td>
												);
											})
										}
									</Tr>
								);
							})
						}
					</Tbody>
				</Table>
			</SlideFade>
		);
	}

	return DataTable({
		data: currentItems,
		columns,
		loading
	});
};